import React, { useState, useEffect } from "react";
import { Modal, Button, Form, OverlayTrigger, Popover } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import config from "../../../../config/config";
import { useSelector } from "react-redux";

export const EditField = (props) => {
  const { user } = useSelector((state) => state.auth);
  const [module, setModules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count] = useState(0);
  const [message, setMessage] = useState("");
  const [color, setColor] = useState("");

  const GetModules = async () => {
    setLoading(true);
    const options = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
    };
    fetch(config.apiUrl + "users/GetModules.php", options)
      .then((response) => response.json())
      .then((data) => setModules(data))
      .then(() => setLoading(false));
  };

  useEffect(() => {
    GetModules();
  }, [count]);

  const schema = Yup.object({
    name: Yup.string()
      .required("Inserire un nome campo")
      .max(20, "Massimo 20 caratteri")
      .min(3, "Minimo 3 caratteri"),
    module: Yup.number("Seleziona il modulo corrispondente")
      .required("Selezionare il modulo corrispondente")
      .positive("Selezionare il modulo corrispondente"),
    description: Yup.string()
      .notRequired()
      .min(3, "Minimo 3 caratteri")
      .max(150, "Massimo 150 caratteri"),
  });

  const formik = useFormik({
    initialValues: {
      name: props.field.name,
      description: props.field.description,
      module: props.field.module,
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      const options = {
        headers: {
          Authorization:
            "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
        },
        method: "POST",
        body: JSON.stringify({
          idField: props.field.id,
          idUser: user.id,
          idOrganization: user.id_organization,
          values: values,
        }),
      };

      props.setLoading(true);
      fetch(config.apiUrl + "obiettivi/EditField.php", options)
        .then((response) => response.json())
        .then((data) => {
          setMessage(data["message"]);
          setColor(data["color"]);
        })
        .then(() => {
          props.setCount(props.count + 1);
        })
        .then(() => {
          props.setLoading(false);
        });
    },
  });

  const getInputClassesForHandle = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  return (
    <Modal show={props.show} onHide={props.handleClose} size="lg">
      <Modal.Header closeButton={true}>
        <Modal.Title>Aggiorna informazioni {props.field.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="col-12">
          <Form.Label>Nome campo</Form.Label>
          <div className="input-group bg-white mb-2">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="top"
                  overlay={
                    <Popover
                      style={{ width: "110%" }}
                      id={`popover-positioned-top`}
                    >
                      <Popover.Title as="h3">Campi valutazione</Popover.Title>
                      <Popover.Content>
                        Per poter eseguire la valutazione dell'utente vanno
                        creati i campi secondo i quali questo verrà valutato.
                        Sono consentiti <strong>massimo </strong>20 campi per
                        modulo. Il nome è obbligatorio, mentre la description
                        no, ma occorre a dare una spiegazione più dettagliata di
                        cosa si punta a valutare del dipendente.
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <i className="far fa-question-circle"></i>
                </OverlayTrigger>
              </div>
            </div>
            <Form.Control
              className={`form-control ${getInputClassesForHandle("name")}`}
              type="text"
              value={formik.values.name}
              name="name"
              onChange={formik.handleChange}
              {...formik.getFieldProps("name")}
              placeholder="Inserire nome campo..."
            />
            {message !== "" ? (
              <p className="bg-white col-12" style={{ color: color }}>
                {message}
              </p>
            ) : null}
            {formik.touched.name && formik.errors.name ? (
              <div className="col-12 bg-white fv-plugins-message-container">
                <div className="bg-white fv-help-block">
                  {formik.errors.name}
                </div>
              </div>
            ) : null}
          </div>
        </Form.Group>
        <Form.Group className="col-12">
          <Form.Label>Seleziona modulo corrispondente</Form.Label>
          {!loading ? (
            <select
              className={`form-control ${getInputClassesForHandle("module")}`}
              name="module"
              value={formik.values.module}
              {...formik.getFieldProps("module")}
              onChange={formik.handleChange}
            >
              <option value="">Seleziona modulo</option>
              {module.map((e, key) => {
                return (
                  <option key={key} value={e.id} name={e.slug}>
                    {e.name}
                  </option>
                );
              })}
            </select>
          ) : (
            <div className="divLoading" style={{ padding: "50px" }}>
              <span
                className="spinner-border"
                style={
                  loading
                    ? { width: "6rem", height: "6rem" }
                    : { display: "none" }
                }
                role="status"
              >
                <span className="sr-only">Caricamento...</span>
              </span>
              <p className="my-7 mt-4">Caricamento moduli...</p>
            </div>
          )}
          {formik.touched.module && formik.errors.module ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.module}</div>
            </div>
          ) : null}
        </Form.Group>
        <Form.Group className="col-12">
          <Form.Label>Descrizione campo</Form.Label>
          <Form.Control
            style={{ resize: "none" }}
            className={`form-control ${getInputClassesForHandle(
              "description"
            )}`}
            as="textarea"
            rows={4}
            value={formik.values.description}
            name="description"
            onChange={formik.handleChange}
            {...formik.getFieldProps("description")}
            placeholder="Descrizione campo..."
          />
          {formik.touched.description && formik.errors.description ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.description}</div>
            </div>
          ) : null}
        </Form.Group>{" "}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            props.handleClose();
            setMessage("");
          }}
        >
          {message === "Campo aggiornato con successo" ? "Chiudi" : "Annulla"}
        </Button>
        <Button variant="success" onClick={formik.handleSubmit}>
          Conferma
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
