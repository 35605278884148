import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import { useSelector } from "react-redux";
import ModalEvent from "./ModalEvent";
import ModalForm from "./ModalForm";
import { Card } from "react-bootstrap";
import ModalRichiesta from "./ModalRichiesta";
import { ModalOvertime } from "./ModalOvertime";
import { ModalFerie } from "./ModalFerie";
import { ModalPresenze } from "./ModalPresenze";
import timeGrid from "@fullcalendar/timegrid";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import config from "../../../../config/config";
import itLocale from "@fullcalendar/core/locales/it";
import StickyToolbar from "../../../layout/components/extras/StickyToolbar";
import { Skeleton } from "@material-ui/lab";


export default function DateCalendar(props) {
  const { user } = useSelector((state) => state.auth);

  const requestOptions = {
    headers: {
      Authorization:
        "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
    },
    method: "POST",
    body: JSON.stringify({
      idUser: user.id,
      idOrganization: user.id_organization,
      idDepartment: user.idDepartment,
    }),
  };

  const GetEvents = () => {
    setLoadingEvents(true);
    fetch(config.apiUrl + "calendar/GetEvents.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setEvents(data))
      .then(() => setLoadingEvents(false));
  };

  const GetPresenze = () => {
    setLoadingPresenze(true);
    fetch(config.apiUrl + "calendar/GetPresenze.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setPresenze(data))
      .then(() => setLoadingPresenze(false));
  };

  const [count, setCount] = useState(0);

  useEffect(() => {
    GetEvents();
    GetPresenze();
  }, [count, props.genCount]);

  const [open, setOpen] = useState(false);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [loadingPresenze, setLoadingPresenze] = useState(false);
  const handleEventClose = () => setOpen(false);
  //boolean per modal form
  const [show, setShow] = useState(false);
  const [richiestaShow, setRichiestaShow] = useState(false);
  const handleRichiestaClose = () => {
    setRichiestaShow(false);
  };
  const handleRichiestaOpen = () => setRichiestaShow(true);
  const handleClose = () => {
    setShow(false);
  };
  const handleOpen = () => setShow(true);

  //stato per salvare gli eventi
  const [events, setEvents] = useState([]);
  const [presenze, setPresenze] = useState([]);
  const [evento, setEvent] = useState({
    title: "",
    description: "",
    allday: "",
    start: "",
    end: "",
  });
  // const forceUpdate = useCallback(() => updateState({}), []);
  //stato per salvare lista delle presenze
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [ferieShow, setFerieShow] = useState(false);
  const handleOpenFerieShow = () => setFerieShow(true);
  const handleCloseFerieShow = () => {
    setFerieShow(false);
    setTimeout(() => {
      window.focus();
      if (document.activeElement) {
        document.activeElement.blur();
      }
    }, 250);
  };
  const [straordinarioShow, setStraordinarioShow] = useState(false);
  const handleOpenStraordinarioShow = () => setStraordinarioShow(true);
  const handleCloseStraordinarioShow = () => {
    setStraordinarioShow(false);
  };
  const [presenzeShow, setPresenzeShow] = useState(false);
  const handleOpenPresenzeShow = () => setPresenzeShow(true);
  const handleClosePresenzeShow = () => {
    setPresenzeShow(false);
  };

  //funzione per gestire il click sulla singola data->apre un modal con un form per la creazione di un evento

  function handleEventClick({ event, el }) {
    if (event.extendedProps.allDay === false) {
      var newEvent = {
        title: event.title,
        allday: false,
        description: event.extendedProps.description,
        department: event.extendedProps.department,
        id: parseInt(event.extendedProps.id),
        start: event.start,
        end: event.end,
      };
    } else {
      newEvent = {
        title: event.title,
        allday: true,
        description: event.extendedProps.description,
        department: event.extendedProps.department,
        id: parseInt(event.extendedProps.id),
        start: event.start,
      };
    }
    if (newEvent.description === "") {
      newEvent.description = "Nessuna descrizione";
    }
    setEvent(newEvent);
    setOpen(true);
  }

  function handlePresenze(button) {
    var newListaEventi = [...events];
    var dataInizio = new Date(startDate);
    var dataFine = new Date(endDate);
    if (button === "presenza") {
      var color = "#059033";
    } else if (button === "assenza") {
      color = "#c82d2f";
    }
    for (
      dataInizio;
      dataInizio < dataFine;
      dataInizio.setDate(dataInizio.getDate() + 1)
    ) {
      var verifica = false;
      for (var n = 0; n < newListaEventi.length; n++) {
        if (
          newListaEventi[n].groupId === "presenze" &&
          formatDate(dataInizio) === newListaEventi[n].start
        ) {
          newListaEventi[n].i = button;
          newListaEventi[n].backgroundColor = color;
          verifica = true;
          break;
        }
      }
      if (verifica === false) {
        var newPresenza = {
          groupId: "presenze",
          allday: true,
          start: formatDate(dataInizio),
          i: button,
          display: "background",
          backgroundColor: color,
        };
        newListaEventi.push(newPresenza);
      }
    }
    setEvents(newListaEventi);
  }

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function selectDate(info) {
    setStartDate(info.startStr);
    setEndDate(info.endStr);
  }

  function dateRender(dayRenderInfo) {
    if (dayRenderInfo.dow === 6) {
      dayRenderInfo.el.style.borderColor = "#C0C0C0";
      dayRenderInfo.el.style.color = "#fcb603";
    } else if (dayRenderInfo.dow === 0) {
      dayRenderInfo.el.style.borderColor = "#C0C0C0";
      dayRenderInfo.el.style.color = "#ed2f09";
    } else {
      dayRenderInfo.el.style.borderColor = "#C0C0C0";
      dayRenderInfo.el.style.color = "green";
    }
  }

  return (
    <>
      <Card>
        <Card.Header>
          <h6 className="card-title-h3">CALENDARIO</h6>
        </Card.Header>
        <Card.Body>
          {!loadingEvents && !loadingPresenze ? (
            <FullCalendar
              plugins={[
                momentTimezonePlugin,
                dayGridPlugin,
                interactionPlugin,
                timeGrid,
                bootstrapPlugin,
              ]}
              timeZone="Europe/Rome"
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              }}
              handleWindowResize={true}
              weekends={true}
              dayCellDidMount={dateRender}
              themeSystem="bootstrap"
              locales={itLocale}
              locale={"it"}
              initialView="dayGridMonth"
              eventClick={handleEventClick}
              selectable={true}
              editable={true}
              events={[...events, ...presenze]}
              select={selectDate}
            />
          ) : (
            <>
              <Skeleton height={50} />
              <Skeleton variant="circle" height={100} width={100} />
              <Skeleton height={250} />
            </>
          )}
          <ModalEvent
            showEvento={open}
            close={handleEventClose}
            evento={evento}
            setCount={setCount}
            count={count}
          />
          <ModalForm
            events={events}
            show={show}
            handleClose={handleClose}
            setEvento={setEvents}
            setShow={setShow}
            startDate={startDate}
            endDate={endDate}
            count={count}
            setCount={setCount}
          />
          <ModalRichiesta
            events={events}
            show={richiestaShow}
            handleClose={handleRichiestaClose}
            setEvento={setEvents}
            setRichiestaShow={setRichiestaShow}
            startDate={startDate}
            endDate={endDate}
            count={count}
            setCount={setCount}
          />
          <ModalOvertime
            straordinarioShow={straordinarioShow}
            handleCloseStraordinarioShow={handleCloseStraordinarioShow}
            handleOpenStraordinarioShow={handleOpenStraordinarioShow}
            startDate={startDate}
            endDate={endDate}
          />
          <ModalPresenze
            presenzeShow={presenzeShow}
            handleClosePresenzeShow={handleClosePresenzeShow}
            handleOpenPresenzeShow={handleOpenPresenzeShow}
            startDate={startDate}
            endDate={endDate}
          />
          <StickyToolbar
            handleOpenFerieShow={handleOpenFerieShow}
            handleOpen={handleOpen}
            handleRichiestaOpen={handleRichiestaOpen}
            handlePresenze={handlePresenze}
            handleOpenPresenzeShow={handleOpenPresenzeShow}
            handleOpenStraordinarioShow={handleOpenStraordinarioShow}
          />
          <ModalFerie
            ferieShow={ferieShow}
            handleCloseFerieShow={handleCloseFerieShow}
            handleOpenFerieShow={handleOpenFerieShow}
            startDate={startDate}
            endDate={endDate}
          />
        </Card.Body>
      </Card>
    </>
  );
}
