import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import config from "../../../../config/config";
import { CSVReader } from "react-papaparse";
/*eslint-disable */

export function ModalImportCsvDipendenti(props) {
  const { user } = useSelector((state) => state.auth);

  const getCustomField = async () => {
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idOrganization: user.id_organization,
        idUser: user.id,
      }),
    };

    var rawData = await fetch(
      config.apiUrl + "users/GetColumnField.php",
      requestOptions
    );
    var data = await rawData.json();
    setCustomField(data);
    const colCustom = Object.fromEntries(data.map((e) => [e.slug, ""]));
    setListaColonne({ ...colCustom, ...colonneNote });
  };

  useEffect(() => {
    getCustomField();
  }, []);

  const buttonRef = React.createRef();

  const colonneNote = {
    nome: "",
    cognome: "",
    mail: "",
    mailAziendale: "",
    ruolo: "",
    dipartimento: "",
  };

  const [dati, setDati] = useState([]);
  const [colonne, setColonne] = useState([]);
  const [load, setLoading] = useState(false);
  const [customField, setCustomField] = useState([]);
  const [listaColonne, setListaColonne] = useState(colonneNote);
  const [mailTo, setMailTo] = useState(false);
  const [a, setA] = useState([]);

  const getInputClassesForHandle = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const handleOnFileLoad = (data) => {
    setColonne(data[0].data);
    setDati(data.slice(1).map((e) => e.data));
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log("---------------------------");
    console.log(err);
    console.log("---------------------------");
  };

  const handleOnRemoveFile = (data) => {
    console.log("---------------------------");
    console.log(data);
    console.log("---------------------------");
  };

  const handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const schema = Yup.object().shape({
    exId: Yup.number().required("Selezionare il campo corrispondente"),
    tipoChiamata: Yup.number().required("Selezionare il campo corrispondente"),
    dataRicevuta: Yup.number().required("Selezionare il campo corrispondente"),
    oraRicevuta: Yup.number().required("Selezionare il campo corrispondente"),
    durata: Yup.number().required("Selezionare il campo corrispondente"),
    nrDialed: Yup.number().required("Selezionare il campo corrispondente"),
    ringTime: Yup.number().required("Selezionare il campo corrispondente"),
    numeroChiamante: Yup.number().required(
      "Selezionare il campo corrispondente"
    ),
    nomeSoggetto: Yup.number().required("Selezionare il campo corrispondente"),
  });

  const formik = useFormik({
    initialValues: listaColonne,
    enableReinitialize: true,
    onSubmit: (values) => {
      const requestOptions = {
        headers: {
          Authorization:
            "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
        },
        method: "POST",
        body: JSON.stringify({
          colonne: values,
          dati: dati,
          idUser: user.id,
          idOrganization: user.id_organization,
          mailTo: mailTo,
        }),
      };
      props.setLoading(true);
      props.handleCloseImport();
      props.handleOpenToast();
      fetch(config.apiUrl + "users/ImportCsvDipendenti.php", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          props.setTitle(data.title);
          props.setMessage(data.message);
        })
        .then(()=>props.setLoading(false));
    },
  });

  return (
    <Modal
      backdrop="static"
      show={props.importModal}
      onHide={props.handleCloseImport}
    >
      <Modal.Header style={{ backgroundColor: "#caf0f8" }} closeButton>
        <Modal.Title>Importa file CSV</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CSVReader
          ref={buttonRef}
          onFileLoad={handleOnFileLoad}
          onError={handleOnError}
          noClick
          noDrag
          onRemoveFile={handleOnRemoveFile}
        >
          {({ file }) => (
            <aside
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
              }}
            >
              <Button
                style={{
                  float: "left",
                }}
                size="sm"
                variant="success"
                onClick={handleOpenDialog}
              >
                Seleziona file
              </Button>
              <div
                style={{
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: "#ccc",
                  height: 35,
                  lineHeight: 2.5,
                  paddingLeft: 13,
                  paddingTop: 3,
                  width: "60%",
                }}
              >
                {file && file.name}
              </div>
              <Button
                size="sm"
                style={{
                  float: "right",
                }}
                variant="danger"
                onClick={handleRemoveFile}
              >
                Remove
              </Button>
            </aside>
          )}
        </CSVReader>
        <Form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-12">
              <label className="col-12 col-form-label">
                Inviare mail con credeziali?
              </label>
              <Form.Check
                className="ml-4"
                inline
                label={mailTo === true ? "Si" : "No"}
                name="mailTo"
                type="checkbox"
                id="checkCustomField"
                onChange={(e) => {
                  setMailTo(e.target.checked);
                }}
              />
            </div>
            <div className="col-6">
              <label className="col-xl-12 col-lg-12 col-form-label">
                Email
              </label>
              <div className="col-lg-12 col-xl-12">
                <div className="input-group input-group-lg input-group-solid">
                  <select
                    onChange={formik.handleChange}
                    value={formik.values.mail}
                    className="form-control form-control-lg form-control-solid"
                    name="mail"
                  >
                    <option>Seleziona colonna corrispondente</option>
                    {colonne.map((e, key) => {
                      return (
                        <option key={key} value={key}>
                          {e}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <label className="col-xl-12 col-lg-12 col-form-label">
                Email Aziendale
              </label>
              <div className="col-lg-12 col-xl-12">
                <div className="input-group input-group-lg input-group-solid">
                  <select
                    onChange={formik.handleChange}
                    value={formik.values.mailAziendale}
                    className="form-control form-control-lg form-control-solid"
                    name="mailAziendale"
                  >
                    <option>Seleziona colonna corrispondente</option>
                    {colonne.map((e, key) => {
                      return (
                        <option key={key} value={key}>
                          {e}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <label className="col-xl-12 col-lg-12 col-form-label">Nome</label>
              <div className="col-lg-12 col-xl-12">
                <div className="input-group input-group-lg input-group-solid">
                  <select
                    onChange={formik.handleChange}
                    value={formik.values.nome}
                    className="form-control form-control-lg form-control-solid"
                    name="nome"
                  >
                    <option>Seleziona colonna corrispondente</option>
                    {colonne.map((e, key) => {
                      return (
                        <option key={key} value={key}>
                          {e}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <label className="col-xl-12 col-lg-12 col-form-label">
                Cognome
              </label>
              <div className="col-lg-12 col-xl-12">
                <div className="input-group input-group-lg input-group-solid">
                  <select
                    onChange={formik.handleChange}
                    value={formik.values.cognome}
                    className="form-control form-control-lg form-control-solid"
                    name="cognome"
                  >
                    <option>Seleziona colonna corrispondente</option>
                    {colonne.map((e, key) => {
                      return (
                        <option key={key} value={key}>
                          {e}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <label className="col-xl-12 col-lg-12 col-form-label">
                Ruolo
              </label>
              <div className="col-lg-12 col-xl-12">
                <div className="input-group input-group-lg input-group-solid">
                  <select
                    onChange={formik.handleChange}
                    value={formik.values.ruolo}
                    className="form-control form-control-lg form-control-solid"
                    name="ruolo"
                  >
                    <option>Seleziona colonna corrispondente</option>
                    {colonne.map((e, key) => {
                      return (
                        <option key={key} value={key}>
                          {e}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <label className="col-xl-12 col-lg-12 col-form-label">
                Dipartimenti
              </label>
              <div className="col-lg-12 col-xl-12">
                <div className="input-group input-group-lg input-group-solid">
                  <select
                    onChange={formik.handleChange}
                    value={formik.values.dipartimento}
                    className="form-control form-control-lg form-control-solid"
                    name="dipartimento"
                  >
                    <option>Seleziona colonna corrispondente</option>
                    {colonne.map((e, key) => {
                      return (
                        <option key={key} value={key}>
                          {e}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-6">
              {customField.map((e, key) => (
                <>
                  <label className="mt-4 col-12">{e.nomeCampo}</label>
                  <div className="col-lg-12 col-xl-12">
                    <div className="input-group input-group-lg input-group-solid">
                      <select
                        onChange={formik.handleChange}
                        className="form-control form-control-lg form-control-solid"
                        name={e.slug}
                      >
                        <option>Seleziona colonna corrispondente</option>
                        {colonne.map((e, key) => {
                          return (
                            <option key={key} value={key}>
                              {e}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleCloseImport}>
          Annulla
        </Button>
        <Button onClick={formik.handleSubmit} variant="primary">
          Conferma
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
