import React, {useState} from "react";
import DateCalendar from "../../_metronic/_partials/widgets/mixed/DateCalendar";
import { TabellaPresenze } from "../../_metronic/_partials/widgets/advance-tables/TabellaPresenze";

export function Presenze() {

  const [count, setCount] = useState(0)

  return (
    <>
      <div className="col-12">
        <DateCalendar
          genCount = {count}
        />

        <TabellaPresenze 
          genCount = {count}
          setGenCount = {setCount}
        />
      </div>
    </>
  );
}