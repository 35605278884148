import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { ImportCsvOfficina } from "../../mixed/ImportCsvOfficina";
import {
  Grid,
  Table,
  PagingPanel,
  TableHeaderRow,
  Toolbar,
  ColumnChooser,
  TableColumnVisibility,
} from "@devexpress/dx-react-grid-bootstrap4";
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import config from "../../../../../config/config";

export function TabellaOfficina(props) {
  const { user } = useSelector((state) => state.auth);

  const [importModal, setImportModal] = useState(false);
  const handleOpenImport = () => setImportModal(true);
  const handleCloseImport = () => setImportModal(false);

  const [tableColumnExtensions] = useState([
    { columnName: "id", align: "left", width: "auto" },
    { columnName: "nomeMeccanico", align: "left", width: "auto" },
    { columnName: "orTot", align: "center", width: "auto" },
    { columnName: "totAttivita", align: "center", width: "auto" },
    { columnName: "numCommessa", align: "center", width: "auto" },
    { columnName: "addebito", align: "center", width: "auto" },
    { columnName: "desLavoro", align: "center", width: "auto" },
    { columnName: "unLavoro", align: "center", width: "auto" },
    { columnName: "desMarca", align: "center", width: "auto" },
    { columnName: "desSede", align: "center", width: "auto" },
  ]);
  const [pageSizes] = useState([10, 25, 50, 100, 0]);
  const [defaultHiddenColumnNames] = useState(["id"]);
  const [sorting, setSorting] = useState([
    { columnName: "id", direction: "desc" },
  ]);

  const columns = [
    { name: "id", title: "ID" },
    { name: "nomeMeccanico", title: "Nome meccanico" },
    { name: "orTot", title: "Ore totali" },
    { name: "totAttivita", title: "Orario attività" },
    { name: "numCommessa", title: "Numero commessa" },
    { name: "addebito", title: "Addebito" },
    { name: "desLavoro", title: "Descrizione lavoro" },
    { name: "unLavoro", title: "Unità lavoro" },
    { name: "desMarca", title: "Marca automobile" },
    { name: "desSede", title: "Sede lavoro" },
  ];

  const [rows, setRows] = useState([]);

  const GetWorks = () => {
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idUser: props.idUser,
        authToken: props.authToken,
      }),
    };
    fetch(config.apiUrl + "obiettivi/GetAllWorksEmployee.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setRows(data));
  };

  useEffect(() => {
    if (props.idUser) {
      GetWorks();
    }
    /*eslint-disable-next-line*/
  }, [props.idUser]);

  const TableComponent = ({ ...restProps }) => (
    <Table.Table {...restProps} className="table-striped" />
  );

  return (
    <>
      <ImportCsvOfficina close={handleCloseImport} show={importModal} />
      <Grid rows={rows} columns={columns}>
        <SortingState sorting={sorting} onSortingChange={setSorting} />
        <IntegratedSorting />
        <PagingState defaultCurrentPage={0} defaultPageSize={10} />
        <IntegratedPaging />
        <FilteringState defaultFilters={[]} />
        <IntegratedFiltering />
        <Table
          tableComponent={TableComponent}
          columnExtensions={tableColumnExtensions}
        />

        <TableHeaderRow showSortingControls />

        <PagingPanel pageSizes={pageSizes} />
        <TableColumnVisibility
          defaultHiddenColumnNames={defaultHiddenColumnNames}
        />
        <Toolbar />
        <ColumnChooser />
      </Grid>
      <div className="row">
        {user.aggiungiChiamata === true ? (
          <div className="col-6 float-left">
            <Button
              //onClick={() => props.handleShow()}
              variant="primary"
              size="sm"
              className="float-left mt-4 mb-4"
            >
              Aggiungi lavoro
            </Button>
          </div>
        ) : null}

        {user.importCsv === true ? (
          <div className="col-6 float-left">
            <Button
              onClick={() => handleOpenImport()}
              variant="primary"
              className="float-left ml-4 mt-4 mb-4"
              size="sm"
            >
              Import CSV
            </Button>
          </div>
        ) : null}

        {user.exportCsv === true ? (
          <div className="col-6 float-right">
            <CSVLink
              //data={props.tickets}
              data={[]}
              filename={"chiamate.csv"}
              className="btn btn-primary btn-sm font-weight-bold mt-4 mb-4 mr-4 float-right"
              size={"small"}
              separator={";"}
            >
              Esporta CSV{" "}
            </CSVLink>
          </div>
        ) : null}
      </div>
    </>
  );
}
