import React, { useEffect, useState } from "react";
import { scaleBand } from "@devexpress/dx-chart-core";
import { ArgumentScale } from "@devexpress/dx-react-chart";
import "react-date-range/dist/styles.css"; // main style file
import { line, curveStep } from "d3-shape";
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  LineSeries,
  Title,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";
import { Card } from "react-bootstrap";
import { ValueScale, Animation, Stack } from "@devexpress/dx-react-chart";
import config from "../../../../../config/config";

export const ChartVenditori = (props) => {
  const [dati, setDati] = useState([]);

  const Line = (props) => (
    <LineSeries.Path
      {...props}
      path={line()
        .x(({ arg }) => arg)
        .y(({ val }) => val)
        .curve(curveStep)}
    />
  );

  const Root = (props) => (
    <Legend.Root {...props} className="m-auto flex-row" />
  );

  const Marker = (props) => {
    const { className, color } = props;
    return (
      <svg className={className} fill={color} width="10" height="10">
        <rect x={0} y={0} width={100} height={100} />
      </svg>
    );
  };

  const stacks = [{ series: ["riuscite", "fallite", "attesa"] }];

  const format = () => (tick) => tick;

  const GetVenditoriChart = () => {
    const options = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idUser: props.idUser,
        startDate: props.startDate,
        endDate: props.endDate,
        idOrganization: props.idOrganization,
      }),
    };
    fetch(
      config.apiUrl + "obiettivi/CalculateGraficoVenditoriEmployee.php",
      options
    )
      .then((response) => response.json())
      .then((data) => setDati(data));
  };

  useEffect(() => {
    if (props.idUser) {
      GetVenditoriChart();
    }
    /*eslint-disable-next-line*/
  }, [props.idUser, props.startDate, props.endDate]);

  return (
    <>
      <Card className="col-12 col-lg-12 col-sm-12 mt-4">
        <Card.Header>
          <Card.Title>Statistiche</Card.Title>
        </Card.Header>
        <Card.Body>
          <Chart data={dati}>
            <ValueScale name="minuti" />
            <ArgumentScale factory={scaleBand} />
            <ArgumentAxis tickFormat={format} />
            <ValueAxis scaleName="minuti" showLine showTicks />
            <BarSeries
              name="Riuscite"
              valueField="riuscite"
              argumentField="month"
              scaleName="minuti"
              color="#2a9d8f"
            />
            <BarSeries
              name="Fallite"
              valueField="fallite"
              argumentField="month"
              scaleName="minuti"
              color="#d62246"
            />
            <BarSeries
              name="In attesa"
              valueField="attesa"
              argumentField="month"
              scaleName="minuti"
              color="#f0cf65"
            />
            <LineSeries
              name="Obiettivo vendite"
              valueField="obiettivo"
              argumentField="month"
              scaleName="minuti"
              color="#2b2d42"
              seriesComponent={Line}
            />
            <Animation />
            <Legend
              position="right"
              rootComponent={Root}
              markerComponent={Marker}
            />
            <Title text="Statische vendite" />
            <Stack stacks={stacks} />
          </Chart>
        </Card.Body>
      </Card>
    </>
  );
};
