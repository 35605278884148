import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { Obiettivi } from "./pages/NewObiettivi";
import { DashboardPage } from "./pages/DashboardPage";
import { Documenti } from "./pages/Documenti";
import { Dipendenti } from "./pages/Dipendenti";
import { Presenze } from "./pages/Presenze";
import { BustePaga } from "./pages/BustePaga";
import { Admin } from "./pages/Admin";
import { AutoValutazione } from "./pages/valutazioni/AutoValutazione";
import { ValutazioneDipendente } from "./pages/valutazioni/ValutazioneDipendente";
import { ResocontoValutazione } from "./pages/valutazioni/ResocontoValutazione";
import { ValutazioniSettings } from './pages/valutazioni/ValutazioniSettings';

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/obiettivi" component={Obiettivi} />
        <ContentRoute path="/documenti" component={Documenti} />
        <ContentRoute path="/buste-paga" component={BustePaga} />
        <ContentRoute path="/presenze" component={Presenze} />
        <ContentRoute path="/dipendenti" component={Dipendenti} />
        <ContentRoute path="/pannello-admin" component={Admin} />
        <Route path="/valutazioni/auto-valutazione" component={AutoValutazione} />
        <Route path="/valutazioni/resoconto-valutazione" component={ResocontoValutazione} />
        <Route path="/valutazioni/impostazioni-valutazione" component={ValutazioniSettings} />
        <Route path="/valutazioni/valuta-dipendenti" component={ValutazioneDipendente} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
