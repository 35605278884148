/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import config from "../../../config/config";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { RowDetailState } from "@devexpress/dx-react-grid";
import { isMobile } from "react-device-detect";
import {
  PagingState,
  IntegratedPaging,
  SearchState,
  IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  PagingPanel,
  Toolbar,
  SearchPanel,
} from "@devexpress/dx-react-grid-bootstrap4";
import { Card, OverlayTrigger, Popover } from "react-bootstrap";
import { OfficinaForm } from "./formSettings/OfficinaForm";
import { EditField } from "./modalField/EditField";
import { DeleteField } from "./modalField/DeleteField";

export const ValutazioniSettings = () => {
  /*eslint-disable */
  const { user } = useSelector((state) => state.auth);

  const [rowsOfficina, setRowsOfficina] = useState([]);
  const [messageOfficina, setMessageOfficina] = useState("");
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageSizes] = useState([5, 10, 15, 25, 0]);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [selectedField, setSelectedField] = useState({});
  const handleOpenEdit = (index, name, description, module) => {
    setSelectedField({
      id: index,
      name: name,
      description: description,
      module: module,
    });
    setShowEdit(true);
  };
  const handleCloseEdit = () => setShowEdit(false);
  const columns = [
    { name: "nomeCampo", title: "Nome campo" },
    { name: "module", title: "Modulo" },
  ];

  const options = {
    headers: {
      Authorization:
        "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
    },
    method: "POST",
    body: JSON.stringify({
      idOrganization: user.id_organization,
      idUser: user.id,
      idDepartment: user.idDepartment,
      idModule: user.idModule,
      authToken: user.authToken,
      idRole: user.id_role,
    }),
  };
  const [showDelete, setShowDelete] = useState(false);
  const handleOpenDelete = (index, name, description, module) => {
    setSelectedField({
      id: index,
      name: name,
      description: description,
      module: module,
    });
    setShowDelete(true);
  };
  const handleCloseDelete = () => setShowDelete(false);

  const GetRows = async () => {
    setLoading(true);
    fetch(config.apiUrl + "obiettivi/GetValutationField.php", options)
      .then((response) => response.json())
      .then((data) => {
        setRowsOfficina(data);
      })
      .then(() => setLoading(false));
  };

  useEffect(() => {
    GetRows();
  }, [count]);

  const TableComponent = ({ ...restProps }) => (
    <>
      <Table.Table
        {...restProps}
        className="table-striped table-hover table-bordered"
      />
    </>
  );

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
      color: "black !important",
    },
  }));

  const classes = useStyles();

  const RowDetail = ({ row }) => (
    <div style={{ backgroundColor: "whitesmoke" }} className="row">
      <div style={{ backgroundColor: "whitesmoke" }} className="col-12">
        <label className="col-12 mt-3">
          {row.descrizioneCampo === "" ? "Nessuna descrizione" : "Descrizione:"}
        </label>
        <label className="col-12">{row.descrizioneCampo}</label>
      </div>
      <div style={{ backgroundColor: "whitesmoke" }} className="col-12 mb-3">
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="top"
          overlay={
            <Popover id={`popover-positioned-top`}>
              <Popover.Content>Elimina</Popover.Content>
            </Popover>
          }
        >
          <i
            onClick={() =>
              handleOpenDelete(
                row.id,
                row.nomeCampo,
                row.descrizioneCampo,
                row.idModule
              )
            }
            style={{ color: "red", cursor: "pointer" }}
            className="float-right far fa-trash-alt mx-3 my-2"
          ></i>
        </OverlayTrigger>
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="top"
          overlay={
            <Popover id={`popover-positioned-top`}>
              <Popover.Content>Modifica</Popover.Content>
            </Popover>
          }
        >
          <i
            onClick={() =>
              handleOpenEdit(
                row.id,
                row.nomeCampo,
                row.descrizioneCampo,
                row.idModule
              )
            }
            style={{ color: "black", cursor: "pointer" }}
            className="float-right far fa-edit my-2 mx"
          ></i>
        </OverlayTrigger>
      </div>
    </div>
  );

  return (
    <>
      <DeleteField
        handleClose={handleCloseDelete}
        handleOpen={handleOpenDelete}
        show={showDelete}
        field={selectedField}
        loading={loading}
        setLoading={setLoading}
        count={count}
        setCount={setCount}
      />
      <EditField
        handleClose={handleCloseEdit}
        handleOpen={handleOpenEdit}
        show={showEdit}
        field={selectedField}
        loading={loading}
        setLoading={setLoading}
        count={count}
        setCount={setCount}
      />
      {/**Officina campi*/}
      <Card className="mt-4">
        <Card.Header style={{ backgroundColor: "#264653" }}>
          <h6 className={classes.heading}>CREA CAMPI VALUTAZIONE</h6>
        </Card.Header>
        <div className="row">
          <OfficinaForm
            user={user}
            setMessageOfficina={setMessageOfficina}
            messageOfficina={messageOfficina}
            count={count}
            setCount={setCount}
            setLoading={setLoading}
          />
          {!loading ? (
            <div
              className={!isMobile ? "col-5 ml-10 float-right " : "col-12 mx-1"}
            >
              <Grid rows={rowsOfficina} columns={columns}>
                <SearchState
                  value={searchValue}
                  onValueChange={setSearchValue}
                />
                <IntegratedFiltering />
                <PagingState
                  currentPage={currentPage}
                  onCurrentPageChange={setCurrentPage}
                  pageSize={pageSize}
                  onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />
                <RowDetailState />
                <Table tableComponent={TableComponent} />
                <TableHeaderRow />
                <Toolbar />
                <SearchPanel />
                <TableRowDetail contentComponent={RowDetail} />
                <PagingPanel pageSizes={pageSizes} />
              </Grid>
            </div>
          ) : (
            <div className="col-5 ml-10 mt-11 mb-4 float-right">
              <div className="divLoading" style={{ padding: "50px" }}>
                <span
                  className="spinner-border"
                  style={
                    loading
                      ? { width: "6rem", height: "6rem" }
                      : { display: "none" }
                  }
                  role="status"
                >
                  <span className="sr-only">Caricamento...</span>
                </span>
                <p className="my-7 mt-4">Caricamento tabella campi...</p>
              </div>
            </div>
          )}
        </div>
      </Card>
    </>
  );
};
