import React from "react";
import { Button, Modal, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import config, { roles } from "../../../../config/config";
import { useSelector } from "react-redux";
import moment from "moment";

export default function ModalForm(props) {
  const { user } = useSelector((state) => state.auth);

  const eventSchema = Yup.object({
    title: Yup.string("Inserire un titolo valido")
      .required("Inserire un titolo valido")
      .min(3, "Minimo 3 caratteri")
      .max(50, "Massimo 50 caratteri"),
    description: Yup.string("Inserire una descrizione valida")
      .notRequired()
      .min(3, "Minimo 3 caratteri")
      .max(255, "Massimo 255 caratteri"),
    startDate: Yup.date("Inserire una data valida").required(
      "Inserire una data valida"
    ),
    allDay: Yup.boolean(),
    endDate: Yup.string().when("allDay", {
      is: false,
      then: Yup.string().required("Inserire data fine evento"),
    }),
    startTime: Yup.string().when("allDay", {
      is: false,
      then: Yup.string().required("Inserire un orario di inizio evento"),
    }),
    endTime: Yup.string().when("allDay", {
      is: false,
      then: Yup.string().required("Inserire un orario di fine evento"),
    }),
  });

  const info = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Rendi l'evento/avviso visibile ad un dipartimento specifico{" "}
      {parseInt(user.id_role) === roles.admin ? "oppure tutta l'azienda" : null}
    </Tooltip>
  );

  const infoColor = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Seleziona con quale colore visualizzerai l'evento sul calendario
    </Tooltip>
  );

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      allDay: false,
      startDate: props.startDate,
      startTime: "",
      endDate: props.endDate,
      endTime: "",
      backgroundColor: "#126f8b",
      department: false,
      selectDepartment: 0,
    },
    validationSchema: eventSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const options = {
        headers: {
          Authorization:
            "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
        },
        method: "POST",
        body: JSON.stringify({
          title: values.title,
          description: values.description,
          allDay: values.allDay,
          startDate: values.startDate,
          startTime: values.startTime,
          endDate: values.endDate,
          endTime: values.endTime,
          department: values.department,
          backgroundColor: values.backgroundColor,
          selectDepartment: values.selectDepartment,
          idUser: user.id,
          idOrganization: user.id_organization,
        }),
      };
      fetch(config.apiUrl + "calendar/AddEvent.php", options).then(() =>
        props.setCount(props.count + 1)
      );
      props.setShow(false);
    },
  });

  return (
    <Modal
      animation="true"
      backdrop="static"
      show={props.show}
      onHide={props.handleClose}
      size="lg"
    >
      <Modal.Header style={{ backgroundColor: "#caf0f8" }} closeButton>
        <Modal.Title>Nuovo evento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Row>
            <Form.Group className="col-12">
              <Form.Label className="col-12">Titolo evento</Form.Label>
              <Form.Control
                type="text"
                name="title"
                placeholder="Titolo..."
                value={formik.values.title}
                onChange={formik.handleChange}
                className={getInputClasses("title")}
                {...formik.getFieldProps("title")}
              />
              {formik.touched.title && formik.errors.title ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.title}</div>
                </div>
              ) : null}
            </Form.Group>
            <Form.Group className="col-xxl-6 col-lg-6 col-sm-12">
              <Form.Label className="col-12">Tutto il giorno?</Form.Label>
              <label className="checkbox mt-2">
                <input
                  type="checkbox"
                  color="primary"
                  className="checkbox-inline"
                  checked={formik.values.allDay}
                  onChange={formik.handleChange}
                  name="allDay"
                />
                <span style={{ marginRight: "5px" }}></span>
                {formik.values.allDay ? "Si" : "No"}
              </label>
            </Form.Group>
            <Form.Group className="col-xxl-6 col-lg-6 col-sm-12">
              <Form.Label className="col-12">
                Seleziona colore evento
                <OverlayTrigger placement="top" overlay={infoColor}>
                  <i className="ml-2 far fa-question-circle fa-sm"></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type="color"
                name="backgroundColor"
                className="col-4 ml-3"
                value={formik.values.backgroundColor}
                onChange={formik.handleChange}
              />
            </Form.Group>
            <Form.Group className="col-xxl-6 col-lg-6 col-sm-12">
              <Form.Label className="col-12">Data evento</Form.Label>
              <Form.Control
                type="date"
                name="startDate"
                value={moment(formik.values.startDate).format("YYYY-MM-DD")}
                onChange={formik.handleChange}
                className={getInputClasses("startDate")}
                {...formik.getFieldProps("startDate")}
              />
              {formik.touched.startDate && formik.errors.startDate ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.startDate}</div>
                </div>
              ) : null}
            </Form.Group>
            {!formik.values.allDay ? (
              <>
                <Form.Group className="col-xxl-6 col-lg-6 col-sm-12">
                  <Form.Label className="col-12">Ora evento</Form.Label>
                  <Form.Control
                    type="time"
                    name="startTime"
                    disabled={formik.values.allDay}
                    value={formik.values.allDay ? "" : formik.values.startTime}
                    onChange={formik.handleChange}
                    className={getInputClasses("startTime")}
                    {...formik.getFieldProps("startTime")}
                  />
                  {formik.touched.startTime && formik.errors.startTime ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.startTime}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>
                <Form.Group className="col-xxl-6 col-lg-6 col-sm-12">
                  <Form.Label className="col-12">Data fine evento</Form.Label>
                  <Form.Control
                    type="date"
                    name="endDate"
                    disabled={formik.values.allDay}
                    min={moment(formik.values.startDate).format("YYYY-MM-DD")}
                    value={
                      formik.values.allDay
                        ? moment(formik.values.startDate).format("YYYY-MM-DD")
                        : moment(formik.values.endDate).format("YYYY-MM-DD")
                    }
                    onChange={formik.handleChange}
                    className={getInputClasses("endDate")}
                    {...formik.getFieldProps("endDate")}
                  />
                  {formik.touched.endDate && formik.errors.endDate ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.endDate}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>
                <Form.Group className="col-xxl-6 col-lg-6 col-sm-12">
                  <Form.Label className="col-12">Ora fine evento</Form.Label>
                  <Form.Control
                    type="time"
                    name="endTime"
                    disabled={formik.values.allDay}
                    value={formik.values.allDay ? "" : formik.values.endTime}
                    onChange={formik.handleChange}
                    className={getInputClasses("endTime")}
                    {...formik.getFieldProps("endTime")}
                  />
                  {formik.touched.endTime && formik.errors.endTime ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.endTime}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>{" "}
              </>
            ) : null}
            {parseInt(user.id_role) === roles.admin ||
            parseInt(user.id_role) === roles.hr ? (
              <Form.Group className="col-xxl-6 col-lg-6 col-sm-12">
                <Form.Label className="col-12">
                  Aggiungi dipartimento
                  <OverlayTrigger placement="top" overlay={info}>
                    <i className="ml-2 far fa-question-circle fa-sm"></i>
                  </OverlayTrigger>
                </Form.Label>
                <label className="checkbox mt-2">
                  <input
                    type="checkbox"
                    color="primary"
                    className="checkbox-inline"
                    checked={formik.values.department}
                    onChange={formik.handleChange}
                    name="department"
                  />
                  <span style={{ marginRight: "5px" }}></span>
                  {formik.values.department ? "Si" : "No"}
                </label>
              </Form.Group>
            ) : null}
            {formik.values.department ? (
              <Form.Group className="col-12">
                <Form.Label className="col-12">
                  Seleziona dipartimento
                </Form.Label>
                <select
                  className="form-control"
                  value={formik.values.selectDepartment}
                  name="selectedDepartment"
                  onChange={formik.handleChange}
                >
                  {parseInt(user.id_role) === roles.admin ? (
                    <>
                      <option value={0}>Tutta l'azienda</option>
                      <option value={user.id_department}>
                        {user.nameDepartment}
                      </option>
                    </>
                  ) : (
                    <>
                      <option value={user.id_department}>
                        {user.nameDepartment}
                      </option>
                    </>
                  )}
                </select>
              </Form.Group>
            ) : null}
            <Form.Group className="col-12">
              <Form.Label className="col-12">Descrizione evento</Form.Label>
              <textarea
                rows="3"
                value={formik.values.description}
                onChange={formik.handleChange}
                className={`form-control ${getInputClasses("description")}`}
                name="description"
                placeholder="Descrizione..."
                style={{ resize: "none" }}
                {...formik.getFieldProps("description")}
              ></textarea>
              {formik.touched.description && formik.errors.description ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.description}
                  </div>
                </div>
              ) : null}
            </Form.Group>
          </Form.Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Annulla
        </Button>
        <Button variant="primary" onClick={formik.handleSubmit}>
          Salva evento
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
