import React, { useEffect, useState } from "react";
import { Button, Form, OverlayTrigger, Popover } from "react-bootstrap";
import {isMobile} from 'react-device-detect';
import * as Yup from "yup";
import { useFormik } from "formik";
import config from "../../../../config/config";

export const OfficinaForm = (props) => {
  /*eslint-disable */
  const [module, setModules] = useState([]);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [count, setCount] = useState(0);

  const GetModules = async () => {
    setLoadingAdd(true);
    const options = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
    };
    fetch(config.apiUrl + "users/GetModules.php", options)
      .then((response) => response.json())
      .then((data) => setModules(data))
      .then(() => setLoadingAdd(false));
  };

  useEffect(() => {
    GetModules();
  }, [count]);

  const schema = Yup.object({
    nomeCampo: Yup.string()
      .required("Inserire un nome campo")
      .max(20, "Massimo 20 caratteri")
      .min(3, "Minimo 3 caratteri"),
    module: Yup.number()
      .required("Selezionare il modulo corrispondente")
      .positive("Selezionare il modulo corrispondente"),
    descrizione: Yup.string()
      .notRequired()
      .min(3, "Minimo 3 caratteri")
      .max(150, "Massimo 150 caratteri"),
  });

  const formik = useFormik({
    initialValues: {
      nomeCampo: "",
      module: "",
      descrizione: "",
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      const options = {
        headers: {
          Authorization:
            "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
        },
        method: "POST",
        body: JSON.stringify({
          idUser: props.user.id,
          idOrganization: props.user.id_organization,
          values: values,
        }),
      };
      setLoadingAdd(true);
      props.setLoading(true);
      fetch(config.apiUrl + "obiettivi/CreateField.php", options)
        .then((response) => response.json())
        .then((data) => props.setMessageOfficina(data))
        .then(() => {
          props.setCount(props.count + 1);
          setCount(count + 1);
        })
        .then(() => {
          props.setLoading(false);
          setLoadingAdd(false);
        });
    },
  });

  const getInputClassesForHandle = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  return (
    <Form
      onKeyPress={(e) => {
        e.key === "Enter" && e.preventDefault();
      }}
      className={!isMobile ? "mt-4 col-6" : "mt-4 mx-1 col-12"}
    >
      {!loadingAdd ? (
        <>
          {" "}
          <Form.Group className="col-12">
            <Form.Label>Nome campo</Form.Label>
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="top"
                    overlay={
                      <Popover
                        style={{ width: "110%" }}
                        id={`popover-positioned-top`}
                      >
                        <Popover.Title as="h3">Campi valutazione</Popover.Title>
                        <Popover.Content>
                          Per poter eseguire la valutazione dell'utente vanno
                          creati i campi secondo i quali questo verrà valutato.
                          Sono consentiti <strong>massimo </strong>20 campi per
                          modulo. Il nome è obbligatorio, mentre la descrizione
                          no, ma occorre a dare una spiegazione più dettagliata
                          di cosa si punta a valutare del dipendente.
                        </Popover.Content>
                      </Popover>
                    }
                  >
                    <i className="far fa-question-circle"></i>
                  </OverlayTrigger>
                </div>
              </div>
              <Form.Control
                className={`form-control ${getInputClassesForHandle(
                  "nomeCampo"
                )}`}
                type="text"
                value={formik.values.nomeCampo}
                name="nomeCampo"
                onChange={formik.handleChange}
                {...formik.getFieldProps("nomeCampo")}
                placeholder="Inserire nome campo..."
              />
              {props.messageOfficina !== "" ? (
                <p className="bg-white col-12" style={{ color: "red" }}>
                  {props.messageOfficina}
                </p>
              ) : null}
              {formik.touched.nomeCampo && formik.errors.nomeCampo ? (
                <div className="col-12 bg-white fv-plugins-message-container">
                  <div className="bg-white fv-help-block">
                    {formik.errors.nomeCampo}
                  </div>
                </div>
              ) : null}
            </div>
          </Form.Group>
          <Form.Group className="col-12">
            <Form.Label>Seleziona modulo corrispondente</Form.Label>
            <select
              className={`form-control ${getInputClassesForHandle("module")}`}
              name="module"
              value={formik.values.module}
              {...formik.getFieldProps("module")}
              onChange={formik.handleChange}
            >
              <option value="">Seleziona modulo</option>
              {module.map((e, key) => {
                return (
                  <option key={key} value={e.id} name={e.slug}>
                    {e.name}
                  </option>
                );
              })}
            </select>
            {formik.touched.module && formik.errors.module ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.module}</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group className="col-12">
            <Form.Label>Descrizione campo</Form.Label>
            <Form.Control
              style={{ resize: "none" }}
              className={`form-control ${getInputClassesForHandle(
                "descrizione"
              )}`}
              as="textarea"
              rows={4}
              value={formik.values.descrizione}
              name="descrizione"
              onChange={formik.handleChange}
              {...formik.getFieldProps("descrizione")}
              placeholder="Descrizione campo..."
            />
            {formik.touched.descrizione && formik.errors.descrizione ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.descrizione}</div>
              </div>
            ) : null}
          </Form.Group>{" "}
        </>
      ) : (
        <div className="divLoading" style={{ padding: "50px" }}>
          <span
            className="spinner-border"
            style={
              loadingAdd
                ? { width: "6rem", height: "6rem" }
                : { display: "none" }
            }
            role="status"
          >
            <span className="sr-only">Caricamento...</span>
          </span>
          <p className="my-7 mt-4">Caricamento moduli...</p>
        </div>
      )}
      <Button
        className="ml-3 mb-6"
        variant="primary"
        onClick={formik.handleSubmit}
        disabled={loadingAdd}
      >
        Crea campo
      </Button>
    </Form>
  );
};
