import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import * as Yup from "yup";
import { Switch } from "@material-ui/core";
import config from "../../../../config/config";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";

export const CreaSottoDipartimento = (props) => {
  /*eslint-disable */
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");
  const [departments, setDepartments] = useState([]);
  const [show, setShow] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const options = {
    headers: {
      Authorization:
        "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
    },
    method: "POST",
    body: JSON.stringify({
      idUtente: user.id,
      idOrganization: user.id_organization,
      page: "gestioneDipartimenti",
    }),
  };

  const GetDepartment = () => {
    fetch(config.apiUrl + "users/GetAllDepartments.php", options)
      .then((response) => response.json())
      .then((data) => setDepartments(data));
  };

  const schema = Yup.object({
    nameDepartment: Yup.string()
      .required("Inserire un nome valido per il dipartimento")
      .min(3, "Il nome deve contenere minimo 3 caratteri")
      .max(30, "Il nome non può contenere più di 30 caratteri"),
    orarioCheckIn: Yup.string().required("Inserire un orario di Check-in"),
    idPadre: Yup.number()
      .positive("Selezionare il macrodipartimento di riferimento")
      .required("Selezionare macrodipartimento di riferimento"),
    orarioCheckout: Yup.string().required(
      "Inserire un orario di Check-out valido"
    ),
    minutiPausa: Yup.number()
      .required("Inserire i minuti di pausa")
      .positive("I minuti di pausa non possono avere numeri negativi")
      .integer("I minuti di pausa non possono avere numeri decimali"),
    delta: Yup.number()
      .required("Inserire il delta per calcolare la produttività")
      .positive("Il delta non può contenere numeri negativi")
      .integer("Il delta non può contenere numeri decimali"),
    minutiGestionePratica: Yup.string().required(
      "Inserire minuti gestione pratica"
    ),
  });

  const formik = useFormik({
    initialValues: {
      idPadre: -1,
      nameDepartment: "",
      autoCheckin: false,
      orarioCheckIn: "09:00",
      orarioCheckout: "18:00",
      minutiPausa: 60,
      delta: 78,
      minutiGestionePratica: "00:03:20",
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      const options = {
        headers: {
          Authorization:
            "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
        },
        method: "POST",
        body: JSON.stringify({
          idUtente: user.id,
          idOrganization: user.id_organization,
          values,
        }),
      };
      fetch(config.apiUrl + "users/CreateDepartment.php", options)
        .then((response) => response.json())
        .then((data) => {
          setResult(data[0]);
          setMessage(data[1]);
          setShow(true);
          props.setCount(props.count + 1);
        });
    },
  });

  const handleReinitialize = () => {
    formik.values.idPadre = -1;
    formik.values.nameDepartment = "";
    formik.values.autoCheckin = false;
    formik.values.orarioCheckIn = "09:00";
    formik.values.orarioCheckout = "18:00";
    formik.values.minutiPausa = 60;
    formik.values.delta = 78;
    formik.values.minutiGestionePratica = "00:03:20";
  };

  useEffect(() => {
    GetDepartment();
    if (result === "green") {
      handleReinitialize();
    }
  }, [props.count]);

  const getInputClassesForHandle = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-12">
          <Form.Group>
            <Form.Label className="col-12">Nome dipartimento</Form.Label>
            <Form.Control
              onClick={() => {
                setShow(false);
              }}
              onChange={formik.handleChange}
              {...formik.getFieldProps("nameDepartment")}
              className={`form-control ${getInputClassesForHandle(
                "nameDepartment"
              )}`}
              name="nameDepartment"
              value={formik.values.nameDepartment}
              type="text"
              placeholder="Inserisci nome dipartimento..."
            />
            {formik.touched.nameDepartment && formik.errors.nameDepartment ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.nameDepartment}
                </div>
              </div>
            ) : null}
          </Form.Group>
        </div>
        <div className="col-6">
          <Form.Group>
            <Form.Label className="col-12">Macrodipartimento</Form.Label>
            <select
              onChange={formik.handleChange}
              value={formik.values.idPadre}
              name="idPadre"
              {...formik.getFieldProps("idPadre")}
              className={`form-control ${getInputClassesForHandle("idPadre")}`}
            >
              <option value={-1}>Seleziona macrodipartimento</option>
              {departments.map((e, key) => {
                return (
                  <option
                    key={key}
                    name={e.nameDepartment}
                    value={parseInt(e.idDepartment)}
                  >
                    {e.nameDepartment}
                  </option>
                );
              })}
            </select>
            {formik.touched.nameDepartment && formik.errors.nameDepartment ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.nameDepartment}
                </div>
              </div>
            ) : null}
          </Form.Group>
        </div>
        <div className="col-6">
          <Form.Group>
            <Form.Label className="col-12">
              Auto check-in/check-out
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="top"
                overlay={
                  <Popover
                    style={{ width: "110%" }}
                    id={`popover-positioned-top`}
                  >
                    <Popover.Title as="h3">Auto Check-In</Popover.Title>
                    <Popover.Content>
                      Se attivo, il check-in sarà gestito in automatico dal
                      sistema e verrà
                      <strong> disattivato </strong>
                      il tasto dedicato alla funzione manuale.
                    </Popover.Content>
                  </Popover>
                }
              >
                <i className="far fa-question-circle ml-3"></i>
              </OverlayTrigger>
            </Form.Label>

            <span className="col-12">
              <Switch
                color="primary"
                name="autoCheckin"
                checked={formik.values.autoCheckin}
                onChange={formik.handleChange}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
              {/* <label className="checkbox col-1 mt-2 ml-2">
                <input
                  onChange={formik.handleChange}
                  type="checkbox"
                  color="primary"
                  checked={formik.values.autoCheckin}
                  name="autoCheckin"
                />
                <span style={{ marginRight: "5px" }}></span>
                {formik.values.autoCheckin === true ? "On" : "Off"}
              </label> */}
            </span>
          </Form.Group>
        </div>
        <div className="col-6">
          <Form.Group>
            <Form.Label className="col-12">
              Orario check-in
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="top"
                overlay={
                  <Popover id={`popover-positioned-top`}>
                    <Popover.Title as="h3">
                      Orari Check-in/Check-out
                    </Popover.Title>
                    <Popover.Content>
                      L'orario di check-in e check-out verrà utilizzato per
                      gestire il calcolo degli obiettivi in base all'algoritmo
                      di calcolo. Se la funzione di chek-in automatico
                      <strong> è attiva, </strong>
                      gestirà anche gli orari di inizio e fine turno.
                    </Popover.Content>
                  </Popover>
                }
              >
                <i className="far fa-question-circle ml-3"></i>
              </OverlayTrigger>
            </Form.Label>
            <Form.Control
              onChange={formik.handleChange}
              {...formik.getFieldProps("orarioCheckIn")}
              className={`form-control ${getInputClassesForHandle(
                "orarioCheckIn"
              )}`}
              name="orarioCheckIn"
              value={formik.values.orarioCheckIn}
              type="time"
              required
            />
            {formik.touched.orarioCheckIn && formik.errors.orarioCheckIn ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.orarioCheckIn}
                </div>
              </div>
            ) : null}
          </Form.Group>
        </div>
        <div className="col-6">
          <Form.Group>
            <Form.Label className="col-12">Orario check-out</Form.Label>
            <Form.Control
              onChange={formik.handleChange}
              {...formik.getFieldProps("orarioCheckout")}
              className={`form-control ${getInputClassesForHandle(
                "orarioCheckout"
              )}`}
              name="orarioCheckout"
              value={formik.values.orarioCheckout}
              type="time"
              required
            />
            {formik.touched.orarioCheckout && formik.errors.orarioCheckout ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.orarioCheckout}
                </div>
              </div>
            ) : null}
          </Form.Group>
        </div>
        <div className="col-4">
          <Form.Group>
            <Form.Label className="col-12">
              Minuti pausa
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="top"
                overlay={
                  <Popover id={`popover-positioned-top`}>
                    <Popover.Title as="h3">Minuti pausa</Popover.Title>
                    <Popover.Content>
                      Indicare i minuti di pausa durante il turno lavorativo per
                      il calcolo delgli obbiettivi
                    </Popover.Content>
                  </Popover>
                }
              >
                <i className="far fa-question-circle ml-3"></i>
              </OverlayTrigger>
            </Form.Label>
            <Form.Control
              onChange={formik.handleChange}
              {...formik.getFieldProps("minutiPausa")}
              className={`form-control ${getInputClassesForHandle(
                "minutiPausa"
              )}`}
              name="minutiPausa"
              value={formik.values.minutiPausa}
              type="number"
              required
            />
            {formik.touched.minutiPausa && formik.errors.minutiPausa ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.minutiPausa}</div>
              </div>
            ) : null}
          </Form.Group>
        </div>
        <div className="col-4">
          <Form.Group>
            <Form.Label className="col-12">
              Delta(%) produttività{" "}
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="top"
                overlay={
                  <Popover id={`popover-positioned-top`}>
                    <Popover.Title as="h3">Delta produttività</Popover.Title>
                    <Popover.Content>
                      Si suppone che il dipendente non lavori a pieno regime
                      durante il suo turno di lavoro. Il delta produttività
                      esprime in percentuale a quanto deve equivalere il suo
                      reale turno di lavoro. Ad esempio: "se un dipendente
                      lavora per 8 ore e si vuole che durante il suo turno
                      lavorativo, le ore effettive siano di 6 ore e 15 minuti
                      (Ogni ora di lavoro quindici minuti di cool down)
                      <strong> il delta sarà del 78%</strong> ". Se si vuole
                      esprimere il calcolo degli obiettivi e della produttività
                      come se il dipendente lavori a pieno regime per tutto il
                      turno
                      <strong> impostare il delta su 100 </strong>
                    </Popover.Content>
                  </Popover>
                }
              >
                <i className="far fa-question-circle ml-3"></i>
              </OverlayTrigger>
            </Form.Label>
            <Form.Control
              onChange={formik.handleChange}
              {...formik.getFieldProps("delta")}
              className={`form-control ${getInputClassesForHandle("delta")}`}
              name="delta"
              value={formik.values.delta}
              type="number"
              required
            />
            {formik.touched.delta && formik.errors.delta ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.delta}</div>
              </div>
            ) : null}
          </Form.Group>
        </div>
        <div className="col-4">
          <Form.Group>
            <Form.Label className="col-12">
              Minuti gestione{" "}
              <OverlayTrigger
                trigger="hover"
                placement="top"
                overlay={
                  <Popover id={`popover-positioned-top`}>
                    <Popover.Title as="h3">Minuti gestione</Popover.Title>
                    <Popover.Content>
                      Si suppone che il dipendente, terminato un compito, abbia
                      una fase in cui dovrà occuparsi della gestione di
                      quest'ultimo. I minuti di gestione indicano quei minuti
                      che l'impiegato in media utilizza per portare a termine un
                      lavoro. Esempio: "Nel caso di un impiegato customer care,
                      esso, al termine della chiamata, impiega 3 minuti e 20
                      secondi per gestire la pratica appena chiusa{" "}
                      <strong>oltre i minuti effettivi di chiamata</strong>"
                    </Popover.Content>
                  </Popover>
                }
              >
                <i className="far fa-question-circle ml-3"></i>
              </OverlayTrigger>
            </Form.Label>
            <Form.Control
              onChange={formik.handleChange}
              {...formik.getFieldProps("minutiGestionePratica")}
              className={`form-control ${getInputClassesForHandle(
                "minutiGestionePratica"
              )}`}
              name="minutiGestionePratica"
              value={formik.values.minutiGestionePratica}
              type="time"
              required
            />
            {formik.touched.minutiGestionePratica &&
            formik.errors.minutiGestionePratica ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.minutiGestionePratica}
                </div>
              </div>
            ) : null}
          </Form.Group>
        </div>
        <div className="col-6">
          {show === true ? (
            <p style={{ color: result }} className="float-left">
              {message}
            </p>
          ) : null}
        </div>
        <div className="col-6">
          <Button
            variant="primary"
            className="float-right my-4"
            onClick={formik.handleSubmit}
          >
            Crea dipartimento
          </Button>
        </div>
      </div>
    </Form>
  );
};
