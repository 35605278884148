import React, { useState } from "react";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  Toolbar,
  SearchPanel,
  TableRowDetail,
} from "@devexpress/dx-react-grid-bootstrap4";
import {
  PagingState,
  IntegratedPaging,
  DataTypeProvider,
  SearchState,
  IntegratedFiltering,
  RowDetailState,
} from "@devexpress/dx-react-grid";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { roles } from "../../../../../config/config";
import { Chip } from "@material-ui/core";
import {
  faCheck,
  faCircleCheck,
  faCirclePause,
  faCircleXmark,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { UpdateRequest } from "../../../../../config/api/richieste";

export const Permessi = (props) => {
  const role = parseInt(props.user.id_role);
  const [loading, setLoading] = useState(false);
  const [columns] = useState(
    role === roles.admin || role === roles.hr
      ? [
          { name: "type", title: "Tipo permesso" },
          { name: "nomeDipendente", title: "Dipendente" },
          { name: "dataRichiesta", title: "Data richiesta" },
          { name: "status", title: "Stato richiesta" },
          { name: "dateUpdated", title: "Ultimo aggiornamento" },
        ]
      : [
          { name: "status", title: "Stato richiesta" },
          { name: "dataRichiesta", title: "Data richiesta" },
          { name: "dateUpdated", title: "Ultimo aggiornamento" },
        ]
  );

  const [pageSizes] = useState([0, 10, 20, 50, 100]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchState] = useState("");
  const [rows] = useState(props.data);

  const DataRichiestaColumn = (props) => (
    <DataTypeProvider
      formatterComponent={({ value }) => moment(value).format("DD MMMM YYYY")}
      {...props}
    />
  );
  const DataAggiornamentoColumn = (props) => (
    <DataTypeProvider
      formatterComponent={({ value }) =>
        moment(value).format("DD MMMM YYYY, HH:mm")
      }
      {...props}
    />
  );

  const StatoColumn = ({ value }) => {
    value = parseInt(value);
    /*eslint-disable-next-line*/
    if (value == 1)
      return (
        // accettato 1
        <div className="pl-3">
          <Chip
            color="primary"
            variant="outlined"
            label="Accettata"
            icon={
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="svg-icon-success svg-icon-md"
              />
            }
            style={{ borderColor: "var(--success)", color: "var(--success)" }}
          />
        </div>
      );
    /*eslint-disable-next-line*/
    if (value == 0)
      return (
        // attesa 0
        <div className="pl-3">
          <Chip
            color="primary"
            variant="outlined"
            label="In attesa"
            icon={
              <FontAwesomeIcon
                icon={faCirclePause}
                className="svg-icon-warning svg-icon-md"
              />
            }
            style={{ borderColor: "var(--warning)", color: "var(--warning)" }}
          />
        </div>
      );
    /*eslint-disable-next-line*/
    if (value == -1)
      return (
        // rifiutato -1
        <div className="pl-3">
          <Chip
            color="primary"
            variant="outlined"
            label="Rifiutata"
            icon={
              <FontAwesomeIcon
                icon={faCircleXmark}
                className="svg-icon-danger svg-icon-md"
                color="danger"
              />
            }
            style={{ borderColor: "var(--danger)", color: "var(--danger)" }}
          />
        </div>
      );
  };

  const StatoFormattedColumn = (props) => (
    <DataTypeProvider formatterComponent={StatoColumn} {...props} />
  );

  const makeActionBtn = ({
    name,
    disabled,
    tooltip,
    value,
    variant,
    icon,
    idRichiesta,
    idPresenza,
    dipendenteMail,
    dipendente,
    type,
  }) => {
    const payload = {
      accessToken: props.user.authToken,
      value: value,
      idRichiesta: idRichiesta,
      idPresenza: idPresenza,
      dipendenteMail: dipendenteMail,
      dipendente: dipendente,
      type: type,
    };
    const btn = (
      <Button
        variant={variant}
        className={`mx-3 my-2`}
        disabled={disabled || loading ? true : false}
        title={name}
        onClick={() => {
          if (payload !== undefined) {
            setLoading(true);
            UpdateRequest(payload)
              .then(() => setLoading(false))
              .then(props.setCount(props.count + 1));
          }
        }}
      >
        {typeof icon == "string" ? (
          <span className={`svg-icon svg-icon-md svg-icon-white`}>
            {" "}
            <SVG src={toAbsoluteUrl(icon)} />
          </span>
        ) : (
          <FontAwesomeIcon
            icon={icon}
            className={`svg-icon-white`}
            style={{ marginRight: "0.5rem" }}
          />
        )}
        {/* <span className={`text-${variant}`}></span> */}
        {name}
      </Button>
    );
    if (!tooltip) return btn;
    return (
      <OverlayTrigger overlay={<Tooltip>{tooltip}</Tooltip>}>
        {btn}
      </OverlayTrigger>
    );
  };

  const RowDetail = ({ row }) => {
    if (row.allDay === 0) {
      return (
        <>
          <div className="card">
            <>
              <div className="col-12">
                <>
                  <div className="p-8">
                    <div className="d-flex align-items-center justify-content-xl-between mb-4">
                      <span className="font-weight-bold text-muted font-size-sm mr-2">
                        Inizio data richiesta:
                      </span>
                      <span className="font-weight-bolder text-primary text-right">
                        {moment(row.startDate).format("DD MMMM YYYY") +
                          " " +
                          row.startTime}
                      </span>
                    </div>
                    <div className="d-flex align-items-center justify-content-xl-between mb-7">
                      <span className="font-weight-bold text-muted font-size-sm mr-2">
                        Fine data richiesta:
                      </span>
                      <span className="font-weight-bolder text-dark-50 text-right">
                        {moment(row.endDate).format("DD MMMM YYYY") +
                          " " +
                          row.endTime}
                      </span>
                    </div>
                    {row.description !== "" ? (
                      <div className="d-flex align-items-center justify-content-xl-between mb-7">
                        <span className="font-weight-bold text-muted font-size-sm mr-2">
                          Descrizione:
                        </span>
                        <span className="font-weight-bolder text-dark-50 text-right">
                          {row.description}
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <div className="separator separator-solid"></div>
                </>
              </div>
            </>
            <div className="separator separator-solid"></div>

            {role === roles.admin || role === roles.hr ? (
              <div>
                <div className="float-xl-right">
                  {makeActionBtn({
                    name: "Accetta",
                    variant: "success",
                    disabled: row.status === 1 ? true : false,
                    icon: faCheck,
                    idRichiesta: row.idRichiesta,
                    idPresenza: row.idPresenza,
                    dipendente: row.nomeDipendente,
                    dipendenteMail: row.dipendenteEmail,
                    type: row.type,
                    value: 1,
                    action: "accept",
                  })}
                  {makeActionBtn({
                    name: "Rifiuta",
                    action: "delete",
                    disabled: row.status === -1 ? true : false,
                    idRichiesta: row.idRichiesta,
                    idPresenza: row.idPresenza,
                    dipendente: row.nomeDipendente,
                    dipendenteMail: row.dipendenteEmail,
                    type: row.type,
                    value: -1,
                    variant: "danger",
                    icon: faXmark,
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </>
      );
    } else
      return (
        <>
          <div className="card">
            <>
              <div className="col-12">
                <>
                  <div className="p-8">
                    <div className="d-flex align-items-center justify-content-xl-between mb-4">
                      <span className="font-weight-bold text-muted font-size-sm mr-2">
                        Giorno richiesto:
                      </span>
                      <span className="font-weight-bolder text-primary text-right">
                        {moment(row.startDate).format("DD MMMM YYYY")}
                      </span>
                    </div>
                    {row.description !== "" ? (
                      <div className="d-flex align-items-center justify-content-xl-between mb-7">
                        <span className="font-weight-bold text-muted font-size-sm mr-2">
                          Descrizione:
                        </span>
                        <span className="font-weight-bolder text-dark-50 text-right">
                          {row.description}
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <div className="separator separator-solid"></div>
                </>
              </div>
            </>
            <div className="separator separator-solid"></div>

            {role === roles.admin || role === roles.hr ? (
              <div>
                <div className="float-xl-right">
                  {makeActionBtn({
                    name: "Accetta",
                    variant: "success",
                    disabled: row.status === 1 ? true : false,
                    icon: faCheck,
                    idRichiesta: row.idRichiesta,
                    idPresenza: row.idPresenza,
                    dipendente: row.nomeDipendente,
                    dipendenteMail: row.dipendenteEmail,
                    type: row.type,
                    value: 1,
                    action: "accept",
                  })}
                  {makeActionBtn({
                    name: "Rifiuta",
                    action: "delete",
                    disabled: row.status === -1 ? true : false,
                    idRichiesta: row.idRichiesta,
                    idPresenza: row.idPresenza,
                    dipendente: row.nomeDipendente,
                    dipendenteMail: row.dipendenteEmail,
                    type: row.type,
                    value: -1,
                    variant: "danger",
                    icon: faXmark,
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </>
      );
  };

  const TableComponent = ({ ...restProps }) => (
    <Table.Table
      {...restProps}
      className="table table-hover table-head-custom table-vertical-center overflow-hidden"
    />
  );

  return (
    <>
      <Grid rows={rows} columns={columns}>
        <RowDetailState />
        <SearchState value={searchValue} onValueChange={setSearchState} />
        <DataRichiestaColumn for={["dataRichiesta"]} />
        <DataAggiornamentoColumn for={["dateUpdated"]} />
        <StatoFormattedColumn for={["status"]} />
        <IntegratedFiltering />
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
        />
        <IntegratedPaging />
        <Table tableComponent={TableComponent} />
        <TableHeaderRow />
        <PagingPanel pageSizes={pageSizes} />
        <Toolbar />
        <SearchPanel />
        <TableRowDetail contentComponent={RowDetail} />
      </Grid>
    </>
  );
};
