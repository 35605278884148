import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../_metronic/layout";
import { useSelector } from "react-redux";
import { GruppiLavoro } from "./GruppiLavoro";
import { Impostazioni } from "./Impostazioni";
import { GestisciRuoli } from "./GestisciRuoli";
import { GestisciSmartworking } from "./GestisciSmartworking";

export function Admin() {
  const { user } = useSelector((state) => state.auth);

  if (user[0].pannelloAdmin === false) {
    window.location.href = "/dashboard";
  }

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from eCommerce root URL to /customers */

          <Redirect
            exact={true}
            from="/pannello-admin"
            to="/pannello-admin/gestisci-gruppi"
          />
        }
        <ContentRoute
          path="/pannello-admin/gestisci-gruppi"
          component={GruppiLavoro}
        />
      </Switch>
      <Switch>
        {
          /* Redirect from eCommerce root URL to /customers */

          <Redirect
            exact={true}
            from="/pannello-admin"
            to="/pannello-admin/gestisci-ruoli"
          />
        }
        <ContentRoute
          path="/pannello-admin/gestisci-ruoli"
          component={GestisciRuoli}
        />
      </Switch>
      <Switch>
        {
          /* Redirect from eCommerce root URL to /customers */

          <Redirect
            exact={true}
            from="/pannello-admin"
            to="/pannello-admin/impostazioni"
          />
        }
        <ContentRoute
          path="/pannello-admin/impostazioni"
          component={Impostazioni}
        />
      </Switch>
      <Switch>
        {
          /* Redirect from eCommerce root URL to /customers */

          <Redirect
            exact={true}
            from="/pannello-admin"
            to="/pannello-admin/gestisci-smartworking"
          />
        }
        <ContentRoute
          path="/pannello-admin/gestisci-smartworking"
          component={GestisciSmartworking}
        />
      </Switch>
    </Suspense>
  );
}
