/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { CreaDipartimento } from "../../_metronic/_partials/widgets/mixed/CreaDipartimento";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { Tooltip, OverlayTrigger, Spinner } from "react-bootstrap";
import { CreaSottoDipartimento } from "../../_metronic/_partials/widgets/mixed/CreaSottoDipartimento";
import { Card, Row, Col, Toast, Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import config from "../../config/config";
/*eslint-disable */

export function GruppiLavoro() {
  const [result, setResult] = useState("");
  const [message, setMessage] = useState("");
  //stato che occorre per aggiornare lo use effect
  const [count, setCount] = useState(0);
  //stato per gestire il modal per eliminare i sotto dipartimenti
  const [deleteModal, setDeleteModal] = useState(false);
  const handleOpenDelete = (index) => {
    setDeleteModal(true);
    setselectedSubDepartment(listSubDepartment[index]);
  };
  const handleCloseDelete = () => {
    setDeleteModal(false);
    setSuccess(true);
  };
  //stato per gestire il modal per eliminare i dipartimenti
  const [deleteDepartment, setDeleteDepartment] = useState(false);
  const handleOpenDepDelete = (index) => {
    setDeleteDepartment(true);
    setSelectedDepartment(listRoles[index]);
  };
  const handleCloseDepDelete = () => {
    setDeleteDepartment(false);
    setSuccess(true);
  };
  //stato per gestire il modal per modificare o visualizzare i dettagli del dipartimento
  const [detailModal, setDetailModal] = useState(false);
  const handleOpenDetail = (index) => {
    setSelectedDepartment(listRoles[index]);
    setDetailModal(true);
  };
  const handleCloseDetail = () => setDetailModal(false);
  //stato per gestire il modal per modificare o visualizzare i dettagli del sotto dipartimento
  const [loading, setLoading] = useState(false);
  const [detailSubModal, setDetailSubModal] = useState(false);
  const handleOpenSubDetail = (index) => {
    setLoading(true);
    setselectedSubDepartment(listSubDepartment[index]);
    setDetailSubModal(true);
    if (selectedSubDepartment.nameDepartment !== "") {
      setLoading(false);
    }
  };
  const handleCloseSubDetail = () => setDetailModal(false);

  useEffect(() => {
    fetchDataAllRoles();
    GetAllSubDepartment();
    fetchDataAllHr();
  }, [count]);

  const deleteTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Elimina
    </Tooltip>
  );

  const editTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Modifica/Visualizza dettagli
    </Tooltip>
  );

  const ModalSubDelete = () => {
    return (
      <>
        <Modal
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Elimina sotto dipartimento
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Sei sicuro di voler eliminare <b>definitivamente</b>
            <br />
            il sotto dipartimento:
            <b>{selectedSubDepartment.nameDepartment}</b>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => handleCloseDelete()}>
              No
            </Button>
            <Button
              variant="danger"
              onClick={() =>
                removeDepartment(selectedSubDepartment.idDepartment)
              }
            >
              Si
            </Button>
            {success === false ? (
              <span className="col-12 float-right" style={{ color: "red" }}>
                Impossibile eliminare il sotto dipartimento assegnato a
                dipendenti
              </span>
            ) : null}
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const ModalDepDelete = () => {
    return (
      <>
        <Modal
          show={deleteDepartment}
          onHide={() => setDeleteDepartment(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Elimina dipartimento
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Sei sicuro di voler eliminare <b>definitivamente</b>
            <br />
            il dipartimento: <b>{selectedDepartment.nameDepartment}</b>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => handleCloseDepDelete()}>
              No
            </Button>
            <Button
              variant="danger"
              onClick={() => removeDepartment(selectedDepartment.idDepartment)}
            >
              Si
            </Button>
            {success === false ? (
              <span className="col-12 float-right" style={{ color: "red" }}>
                Impossibile eliminare il dipartimento assegnato a dipendenti
              </span>
            ) : null}
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const DetailDepModal = () => {
    const editDetail = useFormik({
      initialValues: {
        idUtente: user.id,
        idOrganization: user.id_organization,
        idDepartment: selectedDepartment.idDepartment,
        nameHr: selectedDepartment.nameHr,
        nameDepartment: selectedDepartment.nameDepartment,
        minutiPausa: selectedDepartment.minutiPausa,
        autoCheckin: selectedDepartment.autoCheckin === "0" ? false : true,
        oraCheckin: selectedDepartment.oraCheckin,
        oraCheckout: selectedDepartment.oraCheckout,
        deltaObiettivi: selectedDepartment.deltaObiettivi,
        kMinuti: selectedDepartment.kMinuti,
      },
      enableReinitialize: true,
      onSubmit: (values) => {
        const requesstarttions = {
          headers: {
            Authorization:
              "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
          },
          method: "POST",
          body: JSON.stringify({
            values,
          }),
        };

        fetch(config.apiUrl + "users/UpdateDepartment.php", requesstarttions)
          .then((response) => response.json())
          .then((data) => {
            setResult(data[0]);
            setMessage(data[1]);
          })
          .then(() => setDetailModal(false))
          .then(() => setCount(count + 1));
      },
    });

    return (
      <Modal
        show={detailModal}
        onHide={() => setDetailModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Modifica impostazioni di <b>{selectedDepartment.nameDepartment}.</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-12">
                <label className="col-12">Nome dipartimento</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Modifica nome dipartimento..."
                  value={editDetail.values.nameDepartment}
                  onChange={editDetail.handleChange}
                  name="nameDepartment"
                />
              </div>
              <div className="col-6">
                <label className="col-12 mt-4">Minuti pausa</label>
                <input
                  onChange={editDetail.handleChange}
                  type="text"
                  className="form-control"
                  value={editDetail.values.minutiPausa}
                  name="minutiPausa"
                />
              </div>
              <div className="col-6">
                <label className="col-12 mt-4">Auto check-in</label>
                <Switch
                  color="primary"
                  name="autoCheckin"
                  checked={editDetail.values.autoCheckin ? true : false}
                  onChange={editDetail.handleChange}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                {/* <label className="checkbox col-1 mt-2 ml-2">
                  <input
                    onChange={editDetail.handleChange}
                    type="checkbox"
                    color="primary"
                    checked={editDetail.values.autoCheckin}
                    name="autoCheckin"
                  />
                  <span style={{ marginRight: "5px" }}></span>
                  {selectedDepartment.autoCheckin === true ? "On" : "Off"}
                </label> */}
              </div>
              <div className="col-6">
                <label className="col-12 mt-4">Ora check-in</label>

                <input
                  onChange={editDetail.handleChange}
                  type="time"
                  className="form-control mt"
                  value={editDetail.values.oraCheckin}
                  name="oraCheckin"
                  required
                />
              </div>
              <div className="col-6">
                <label className="col-12 mt-4">Ora check-out</label>
                <input
                  onChange={editDetail.handleChange}
                  type="time"
                  className="form-control"
                  value={editDetail.values.oraCheckout}
                  name="oraCheckout"
                  required
                />
              </div>
              <div className="col-6">
                <label className="col-12 mt-4">Delta(%) obiettivi</label>
                <input
                  onChange={editDetail.handleChange}
                  type="text"
                  className="form-control"
                  value={editDetail.values.deltaObiettivi}
                  name="deltaObiettivi"
                />
              </div>
              <div className="col-6">
                <label className="col-12 mt-4">Tempo gestione ticket</label>
                <input
                  onChange={editDetail.handleChange}
                  type="time"
                  className="form-control"
                  value={editDetail.values.kMinuti}
                  name="kMinuti"
                  required
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={editDetail.handleSubmit}>Modifica</Button>
          <Button onClick={() => handleCloseDetail()}>Annulla</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const DetailSubModal = () => {
    const editSubDetail = useFormik({
      initialValues: {
        idUtente: user.id,
        idOrganization: user.id_organization,
        idDepartment: selectedSubDepartment.idDepartment,
        idPadre: selectedSubDepartment.idPadre,
        nameDepartment: selectedSubDepartment.nameDepartment,
        minutiPausa: selectedSubDepartment.minutiPausa,
        autoCheckin: selectedSubDepartment.autoCheckin === "0" ? false : true,
        oraCheckin: selectedSubDepartment.oraCheckin,
        oraCheckout: selectedSubDepartment.oraCheckout,
        deltaObiettivi: selectedSubDepartment.deltaObiettivi,
        kMinuti: selectedSubDepartment.kMinuti,
      },
      enableReinitialize: true,
      onSubmit: (values) => {
        const requesstarttions = {
          headers: {
            Authorization:
              "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
          },
          method: "POST",
          body: JSON.stringify({
            values,
          }),
        };

        fetch(config.apiUrl + "users/UpdateDepartment.php", requesstarttions)
          .then((response) => response.json())
          .then((data) => {
            setResult(data[0]);
            setMessage(data[1]);
          })
          .then(() => setDetailSubModal(false))
          .then(() => setCount(count + 1));
      },
    });

    return (
      <Modal
        show={detailSubModal}
        onHide={() => setDetailSubModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {loading === false ? (
            <Modal.Title id="contained-modal-title-vcenter">
              Modifica impostazioni di{" "}
              <b>{selectedSubDepartment.nameDepartment}.</b>
              <br />
              Sotto dipartimento di {selectedSubDepartment.nameFather}
            </Modal.Title>
          ) : (
            <Spinner animation="grow" />
          )}
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-12">
                <label className="col-12">Nome dipartimento</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Modifica nome dipartimento..."
                  value={editSubDetail.values.nameDepartment}
                  onChange={editSubDetail.handleChange}
                  name="nameDepartment"
                />
              </div>
              <div className="col-6">
                <label className="col-12 mt-4">Minuti pausa</label>
                <input
                  onChange={editSubDetail.handleChange}
                  type="text"
                  className="form-control"
                  value={editSubDetail.values.minutiPausa}
                  name="minutiPausa"
                />
              </div>
              <div className="col-6">
                <label className="col-12 mt-4">Auto check-in</label>
                <span className="col-12">
                  <Switch
                    color="primary"
                    name="autoCheckin"
                    checked={editSubDetail.values.autoCheckin ? true : false}
                    onChange={editSubDetail.handleChange}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </span>
                {/* <label className="checkbox col-1 mt-2 ml-2">
                  <input
                    onChange={editSubDetail.handleChange}
                    type="checkbox"
                    color="primary"
                    checked={editSubDetail.values.autoCheckin}
                    name="autoCheckin"
                  />
                  <span style={{ marginRight: "5px" }}></span>
                  {selectedDepartment.autoCheckin === true ? "On" : "Off"}
                </label> */}
              </div>
              <div className="col-6">
                <label className="col-12 mt-4">Ora check-in</label>
                <input
                  onChange={editSubDetail.handleChange}
                  type="time"
                  className="form-control mt"
                  value={editSubDetail.values.oraCheckin}
                  name="oraCheckin"
                  required
                />
              </div>
              <div className="col-6">
                <label className="col-12 mt-4">Ora check-out</label>
                <input
                  onChange={editSubDetail.handleChange}
                  type="time"
                  className="form-control"
                  value={editSubDetail.values.oraCheckout}
                  name="oraCheckout"
                  required
                />
              </div>
              <div className="col-6">
                <label className="col-12 mt-4">Delta(%) obiettivi</label>
                <input
                  onChange={editSubDetail.handleChange}
                  type="text"
                  className="form-control"
                  value={editSubDetail.values.deltaObiettivi}
                  name="deltaObiettivi"
                />
              </div>
              <div className="col-6">
                <label className="col-12 mt-4">Tempo gestione ticket</label>
                <input
                  onChange={editSubDetail.handleChange}
                  type="time"
                  className="form-control"
                  value={editSubDetail.values.kMinuti}
                  name="kMinuti"
                  required
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={editSubDetail.handleSubmit}>Modifica</Button>
          <Button onClick={() => setDetailSubModal(false)}>Annulla</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const removeDepartment = (idDepartment) => {
    const options = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idOrganization: user.id_organization,
        idUser: user.id,
        idDepartment: idDepartment,
      }),
    };
    fetch(config.apiUrl + "users/RemoveDepartment.php", options)
      .then((response) => response.json())
      .then((data) => {
        setSuccess(data);
        if (data) {
          handleCloseDelete();
          handleCloseDepDelete();
          setCount(count + 1);
        }
      });
  };

  const GetAllSubDepartment = async () => {
    const options = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idOrganization: user.id_organization,
        idUser: user.id,
      }),
    };
    fetch(config.apiUrl + "users/GetAllSubDepartments.php", options)
      .then((response) => response.json())
      .then((data) => setListSubDepartment(data));
  };

  const [listRoles, setListRoles] = useState([]);
  const [listSubDepartment, setListSubDepartment] = useState([]);
  const [listHr, setListHr] = useState([]);
  const [showDelete, setShowDelete] = useState(false);

  const { user } = useSelector((state) => state.auth);

  if (user[0].gestioneGruppi === false) {
    window.location.href = "/dashboard";
  }

  const ToastDelete = () => {
    return (
      <Row>
        <Col xs={6}>
          <Toast
            onClose={() => setShowDelete(false)}
            show={showDelete}
            delay={2000}
            autohide
          >
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">Dipartimento eliminato</strong>
            </Toast.Header>
            <Toast.Body>Dipartimento eliminato con successo!</Toast.Body>
          </Toast>
        </Col>
      </Row>
    );
  };

  const ColumnField = ({ column, index, detailColumn, removeColumn }) => {
    return (
      <tr>
        <td className="pl-0 py-8">
          <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg pl-3">
            {column.nameDepartment}
          </span>
        </td>
        <td>
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {column.oraCheckin}
          </span>
        </td>
        <td>
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {column.oraCheckout}
          </span>
        </td>
        <td>
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {column.minutiPausa}
          </span>
        </td>
        <td>
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {column.kMinuti}
          </span>
        </td>
        <td>
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {column.deltaObiettivi}
          </span>
        </td>
        <td>
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {parseInt(column.autoCheckin) === 1 ? "Si" : "No"}
          </span>
        </td>
        <td>
          <div>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={editTooltip}
            >
              <i
                onClick={() => detailColumn(index)}
                style={{ color: "#168aad", cursor: "pointer" }}
                className="fas fa-pen my-2 mr-2"
              ></i>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={deleteTooltip}
            >
              <i
                onClick={() => removeColumn(index)}
                style={{ color: "red", cursor: "pointer" }}
                className="far fa-trash-alt my-2 mx-3"
              ></i>
            </OverlayTrigger>
          </div>
        </td>
      </tr>
    );
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

  const fetchDataAllRoles = async () => {
    const requesstarttions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idUtente: user.id,
        idOrganization: user.id_organization,
        page: "gestioneDipartimenti",
      }),
    };

    const rawData = await fetch(
      config.apiUrl + "users/GetAllDepartments.php",
      requesstarttions
    );
    const data = await rawData.json();
    setListRoles(data);
  };

  const fetchDataAllHr = async () => {
    const requesstarttions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idUtente: user.id,
        idOrganization: user.id_organization,
      }),
    };

    const rawData = await fetch(
      config.apiUrl + "users/GetAllHr.php",
      requesstarttions
    );
    const data = await rawData.json();
    setListHr(data);
  };

  const [success, setSuccess] = useState(true);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [selectedSubDepartment, setselectedSubDepartment] = useState([]);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/*Accordion 1: Crea nuovo ruolo <begin>*/}

      <Card>
        <Card.Header style={{ backgroundColor: "#264653" }}>
          <h6 className={classes.heading}>CREA NUOVO DIPARTIMENTO</h6>
        </Card.Header>
        <div className="col-12 mt-3">
          <CreaDipartimento count={count} setCount={setCount} />
        </div>
      </Card>

      {/*Accordion 1: Crea nuovo ruolo <end>*/}

      {/*Accordion 2: Dipartimento figlio creazione <begin>*/}

      <Card className="mt-4">
        <Card.Header style={{ backgroundColor: "#264653" }}>
          <h6 className={classes.heading}>CREA SOTTO DIPARTIMENTO</h6>
        </Card.Header>
        <div className="col-12 mt-3">
          <CreaSottoDipartimento count={count} setCount={setCount} />
        </div>
      </Card>

      {/*Accordion 2: Dipartimento figlio creazione <end>*/}

      {/*Accordion 3: Gestione dipartimenti <begin>*/}
      <ModalSubDelete />
      <ModalDepDelete />
      <DetailDepModal />
      <DetailSubModal />

      <Card className="mt-4">
        <Card.Header style={{ backgroundColor: "#264653" }}>
          <h6 className={classes.heading}>GESTISCI DIPARTIMENTI</h6>
        </Card.Header>
        <Card.Body>
          <div className="tab-content">
            <div className="table-responsive">
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <thead>
                  <tr className="text-left text-uppercase">
                    <th className="pl-7" style={{ minWidth: "100px" }}>
                      <span className="text-dark-75">nome dipartimento</span>
                    </th>
                    <th>ora checkin</th>
                    <th>ora checkout</th>
                    <th>minuti pausa</th>
                    <th>minuti gestione</th>
                    <th>delta obiettivi</th>
                    <th>auto checkin</th>
                    <th>azioni</th>
                  </tr>
                </thead>
                <tbody>
                  {listRoles.map((columnField, index) => (
                    <ColumnField
                      key={index}
                      index={index}
                      column={columnField}
                      detailColumn={handleOpenDetail}
                      removeColumn={handleOpenDepDelete}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Card.Body>
      </Card>

      {/*End: Dipartimenti */}

      {/*Begin: Sotto dipartimenti */}

      <Card className="mt-4">
        <Card.Header style={{ backgroundColor: "#264653" }}>
          <h6 className={classes.heading}>GESTISCI SOTTO DIPARTIMENTI</h6>
        </Card.Header>
        <Card.Body>
          <div className="tab-content">
            <div className="table-responsive">
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <thead>
                  <tr className="text-left text-uppercase">
                    <th className="pl-7" style={{ minWidth: "100px" }}>
                      <span className="text-dark-75">nome dipartimento</span>
                    </th>
                    <th>ora checkin</th>
                    <th>ora checkout</th>
                    <th>minuti pausa</th>
                    <th>minuti gestione</th>
                    <th>delta obiettivi</th>
                    <th>auto checkin</th>
                    <th>azioni</th>
                  </tr>
                </thead>
                <tbody>
                  {listSubDepartment.map((columnField, index) => (
                    <ColumnField
                      key={index}
                      index={index}
                      column={columnField}
                      detailColumn={handleOpenSubDetail}
                      removeColumn={handleOpenDelete}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Card.Body>
      </Card>

      <ToastDelete />

      {/*Accordion 3: Gestione dipartimenti <end>*/}
    </div>
  );
}
