/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect} from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";

export function UserProfileDropdown() {
  

  const { user } = useSelector((state) => state.auth);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);

  /* async function CountNotifiche() {
    const rawData = await fetch(
      config.apiUrl + "users/CountNotifiche.php",
      requestOptions
    );
    const data = await rawData.json();
    setCountNotifiche(data);
  } */

  useEffect(() => {
    /* CountNotifiche(); */
  }, []);

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          }
        >
          <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
            Ciao,
          </span>{" "}
          <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
            {user.firstname} {user.lastname}
          </span>
          {/* <span className="symbol symbol-35 symbol-light-success">
            <div className="d-flex align-items-center">
              <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                <div
                  className="symbol-label"
                  style={{
                    backgroundImage: `url(${user.pic})`,
                    width: "40px",
                    height: "40px",
                  }}
                ></div> */}
                {/* style="background-i
                  mage:url('/metronic/theme/html/demo1/dist/assets/media/users/300_21.jpg')" */}
             {/*  </div>
            </div>
          </span> */}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light && (
            <>
              <div className="d-flex align-items-center p-8 rounded-top">
                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                  <img src={toAbsoluteUrl("/media/users/300_21.jpg")} alt="" />
                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                  {user.firstname} {user.lastname}
                </div>
                <span className="label label-light-success label-lg font-weight-bold label-inline">
                  3 messaggi
                </span>
              </div>
              <div className="separator separator-solid"></div>
            </>
          )}

          {/* {!layoutProps.light && (
            <div
              className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
              style={{backgroundColor: "#f4a261",
              }}
            >
              <div className="d-flex align-items-center">
                <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                  <div
                    className="symbol-label"
                    style={{
                      backgroundImage: `url(${user.pic})`,
                      width: "60px",
                      height: "60px",
                    }}
                  ><
              </div>

              <div style={{color: "#1e1e2d"}} className="text-bold m-0 flex-grow-1 mr-3 font-size-h5">
                {user.firstname} {user.lastname}
              </div>
            </div>
          )} */}
        </>

        <div className="navi navi-spacer-x-0 pt-5">
          <Link
            to="/user-profile/personal-information"
            className="navi-item px-8 cursor-pointer"
            style={{ marginLeft: "6.5%" }}
          >
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="far fa-user text-success"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">Profilo</div>
              </div>
            </div>
          </Link>

          <Link
            to="/user-profile/request-page"
            className="navi-item px-8 cursor-pointer"
          >
            <a className="navi-item px-8">
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <i className="far fa-bookmark text-danger"></i>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">Richieste</div>
                </div>
              </div>
            </a>
          </Link>

          {/* <Link to="/obiettivi/" className="navi-item px-8 cursor-pointer">
            <a className="navi-item px-8">
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <i className="fas fa-chart-line text-primary"></i>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">Obiettivi</div>
                </div>
              </div>
            </a>
          </Link> */}

          <div className="navi-separator mt-3"></div>

          <div className="navi-footer  px-8 py-5 float-right">
            <Link
              to="/logout"
              className="btn btn-light-primary font-weight-bold"
            >
              Disconnetti
            </Link>
            {/*             <a href="#" className="btn btn-clean font-weight-bold">
              Upgrade Plan
            </a> */}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
