/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import config from "../../config/config";
import MuiAlert from "@material-ui/lab/Alert";
import { OverlayTrigger, Popover, Modal } from "react-bootstrap";
import { Button, Card, Form, ListGroup } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";

export function Impostazioni() {
  /*eslint-disable */
  const { user } = useSelector((state) => state.auth);

  const [column, setColumn] = useState([
    {
      text: "This is a sampe todo",
      isDone: false,
    },
  ]);

  const [message, setMessage] = useState("");

  const [loadingFields, setLoadingFields] = useState(false);
  const [loadingButtons, setLoadingButtons] = useState(false);
  const [selectedField, setSelectedField] = useState("");
  const [vendite, setVendite] = useState(0);
  const [obiettivoVendite, setObiettivoVendite] = useState(0);

  const [show, setShow] = useState(false);
  const handleShow = (index) => {
    setShow(true);
    setSelectedField(column[index]);
  };
  const handleClose = () => {
    setShow(false);
  };

  if (user[0].impostazioni === false) {
    window.location.href = "/dashboard";
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  }));

  const style = makeStyles((theme) => ({
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
  }));

  const [setting, setSetting] = useState({
    aggiungiChiamata: true,
    importCsv: true,
    exportCsv: true,
  });

  const ObiettiviVenditori = () => {
    const options = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idOrganization: user.id_organization,
        idUser: user.id,
        vendite: vendite,
      }),
    };
    fetch(config.apiUrl + "obiettivi/AddObiettiviVenditori.php", options).then(() =>
      setCount(count + 1)
    );
  };

  const GetObiettiviVenditori = () => {
    const options = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idOrganization: user.id_organization,
        idUser: user.id,
      }),
    };

    fetch(config.apiUrl + "obiettivi/GetObiettiviVenditori.php", options)
    .then(response => response.json())
    .then(data=> setObiettivoVendite(data))
  }

  const ColumnField = ({ column, index, handleShow }) => {
    return (
      <ListGroup variant="flush">
        <div className="my-1 columnField">
          <div>
            <span className="ml-2">{column.nomeCampo}</span>
          </div>
          <div>
            <i
              onClick={() => handleShow(index)}
              style={{ color: "red", cursor: "pointer" }}
              className="far fa-trash-alt mx-2 my-2"
            ></i>
          </div>
        </div>
      </ListGroup>
    );
  };

  const FormColumn = ({ addColumn }) => {
    const [value, setValue] = useState("");

    const handleSubmitColumn = (e) => {
      e.preventDefault();
      if (!value) return;
      addColumn(value);
      setValue("");
    };

    return (
      <Form onSubmit={handleSubmitColumn}>
        <div className="col-auto">
          <label className="sr-only" for="inlineFormInputGroup">
            Aggiungi nuovo campo...
          </label>
          <div className="input-group mb-2">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="left"
                  overlay={
                    <Popover
                      style={{ width: "110%" }}
                      id={`popover-positioned-top`}
                    >
                      <Popover.Title as="h3">
                        Campi personalizzati
                      </Popover.Title>
                      <Popover.Content>
                        Aggiungere campi personalizzati che verranno utlizzati
                        come chiave <br />
                        per le tabelle dei componenti ed altre funzioni (es.
                        sipadX smart working)
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <i className="far fa-question-circle"></i>
                </OverlayTrigger>
              </div>
            </div>
            <input
              type="text"
              className="form-control"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                setMessage("");
              }}
              placeholder="Aggiungi nuovo campo..."
            />
            <button
              disabled={loadingFields}
              className="btn btn-primary btn-group"
              type="submit"
            >
              Aggiungi
            </button>
          </div>
        </div>
      </Form>
    );
  };

  const addColumn = (text) => {
    const options = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idOrganization: user.id_organization,
        idUser: user.id,
        customField: text,
      }),
    };
    setLoadingFields(true);
    fetch(config.apiUrl + "users/AddCustomField.php", options)
      .then((response) => response.json())
      .then((data) => setMessage(data))
      .then(() => setCount(count + 1))
      .then(() => setLoadingFields(false));
  };

  const removeColumn = (index) => {
    const options = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idOrganization: user.id_organization,
        idUser: user.id,
        idCustomField: index,
      }),
    };
    setLoadingFields(true);
    handleClose();
    fetch(config.apiUrl + "users/RemoveColumnField.php", options)
      .then((response) => response.json())
      .then((data) => setMessage(data))
      .then(() => setCount(count + 1))
      .then(() => setLoadingFields(false));
  };

  const getColumnField = async () => {
    const options = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idOrganization: user.id_organization,
        idUser: user.id,
      }),
    };
    setLoadingFields(true);
    fetch(config.apiUrl + "users/GetColumnField.php", options)
      .then((response) => response.json())
      .then((data) => setColumn(data))
      .then(() => setLoadingFields(false));
  };

  const getLastSetting = async () => {
    const options = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idOrganization: user.id_organization,
        idUser: user.id,
      }),
    };
    setLoadingButtons(true);
    fetch(config.apiUrl + "users/GetLastSettings.php", options)
      .then((response) => response.json())
      .then((data) => setSetting(data))
      .then(() => setLoadingButtons(false));
  };

  const [count, setCount] = useState(0);

  useEffect(() => {
    getLastSetting();
    getColumnField();
    GetObiettiviVenditori();
  }, [count]);

  const formik = useFormik({
    initialValues: {
      aggiungiChiamata: setting.aggiungiChiamata,
      importCsv: setting.importCsv,
      exportCsv: setting.exportCsv,
      idOrganization: user.id_organization,
      idUser: user.id,
      columnField: column,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const options = {
        headers: {
          Authorization:
            "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
        },
        method: "POST",
        body: JSON.stringify({
          values,
        }),
      };
      setLoadingFields(true);
      fetch(config.apiUrl + "users/updateSetting.php", options)
        .then(() => setCount(count + 1))
        .then(() => setLoadingFields(false));
    },
  });

  return (
    <>
      <Card>
        <form className="mx-6">
          <div className="row">
            <div className="col-6">
              <div className="col-12">
                <h5 className="mt-6">Tasti attivi</h5>
              </div>

              {loadingButtons === false ? (
                <>
                  {/* <label className="checkbox" style={{ marginTop: "7%" }}>
                    <input
                      type="checkbox"
                      color="primary"
                      className="checkbox-inline"
                      checked={formik.values.aggiungiChiamata}
                      onChange={formik.handleChange}
                      name="aggiungiChiamata"
                    />
                    <span style={{ marginRight: "5px" }}></span>
                    Aggiungi singolo ticket lavoro
                  </label> */}
                  <label className="checkbox" style={{ marginTop: "7%" }}>
                    <input
                      type="checkbox"
                      color="primary"
                      className="checkbox-inline"
                      checked={formik.values.importCsv}
                      onChange={formik.handleChange}
                      name="importCsv"
                    />
                    <span style={{ marginRight: "5px" }}></span>
                    Import CSV
                  </label>
                  <label className="checkbox" style={{ marginTop: "7%" }}>
                    <input
                      type="checkbox"
                      color="primary"
                      className="checkbox-inline"
                      checked={formik.values.exportCsv}
                      onChange={formik.handleChange}
                      name="exportCsv"
                    />
                    <span style={{ marginRight: "5px" }}></span>
                    Export CSV
                  </label>{" "}
                </>
              ) : (
                <>
                  {" "}
                  <div className="divLoading" style={{ padding: "50px" }}>
                    <span
                      className="spinner-border"
                      style={
                        loadingButtons
                          ? { width: "6rem", height: "6rem" }
                          : { display: "none" }
                      }
                      role="status"
                    >
                      <span className="sr-only">Caricamento...</span>
                    </span>
                    <p className="my-7 mt-4">
                      Caricamento tasti personalizzati...
                    </p>
                  </div>{" "}
                </>
              )}
            </div>
            <div className="col-6">
              <div className="col-12">
                <h5 className="mt-6">Campi personalizzati</h5>
              </div>
              <div className="mt-5">
                {loadingFields === false ? (
                  <>
                    {" "}
                    <Card>
                      {column.map((columnField, index) => (
                        <ColumnField
                          key={index}
                          index={index}
                          column={columnField}
                          handleShow={handleShow}
                        />
                      ))}
                    </Card>{" "}
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="divLoading" style={{ padding: "50px" }}>
                      <span
                        className="spinner-border"
                        style={
                          loadingFields
                            ? { width: "6rem", height: "6rem" }
                            : { display: "none" }
                        }
                        role="status"
                      >
                        <span className="sr-only">Caricamento...</span>
                      </span>
                      <p className="my-7 mt-4">
                        Caricamento tabella valutazione...
                      </p>
                    </div>{" "}
                  </>
                )}
              </div>
              <div className="mt-5">
                {message !== "" ? (
                  <p style={{ color: "red" }}>{message}</p>
                ) : null}
                <FormColumn addColumn={addColumn} />
              </div>
              <div className="col-6 mt-5" style={{ float: "left" }}></div>
            </div>
            <div className="col-6 mb-6">
              <div className="mr-3 mt-20 ml-5" style={{ float: "left" }}>
                <Button
                  disabled={
                    loadingFields === true || loadingButtons === true
                      ? true
                      : false
                  }
                  onClick={formik.handleSubmit}
                  variant="success"
                  size="sm"
                >
                  Conferma
                </Button>
              </div>
              <div className="mt-20 ml" style={{ float: "left" }}>
                <Button onClick={formik.handleReset} variant="danger" size="sm">
                  Annulla
                </Button>
              </div>
            </div>
          </div>
        </form>
        {/* <div className="col-12">
          <h5 className="mt-6 ml-5">Venditori</h5>
          <Form.Row>
            <Form.Group className="col-6 mt-4">
              <Form.Label className="col-12">Vendite mensili</Form.Label>
              <Form.Control
                type="number"
                placeholder="Vendite mensili..."
                name="vendite"
                value={vendite}
                onChange={(e) => setVendite(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="col-6 mt-4">
              <Form.Label className="col-12">Obiettivo attuale</Form.Label>
              <Form.Control
                type="number"
                disabled={true}
                value={obiettivoVendite}
              />
            </Form.Group>
          </Form.Row>
          <Button onClick={()=>ObiettiviVenditori()} variant="success" size="sm" className="mb-5 ml-5">
            Conferma
          </Button>
        </div> */}
      </Card>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton={true}>
          <Modal.Title>Elimina campo personalizzato</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Sei sicuro di voler <strong>eliminare definitivamente</strong>{" "}
          {selectedField.nomeCampo}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => handleClose()}>
            Annulla
          </Button>
          <Button
            variant="danger"
            onClick={() => removeColumn(selectedField.idColumnField)}
          >
            Elimina
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
