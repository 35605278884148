/* eslint-disable no-restricted-imports */
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  OverlayTrigger,
  Popover,
  Row,
} from "react-bootstrap";
import { useFormik } from "formik";
import config from "../../../../config/config";
import Rating from "@material-ui/lab/Rating";
import { Skeleton } from "@material-ui/lab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
}));

export const ModalAutovalutazione = (props) => {
  /*eslint-disable */
  const { user } = useSelector((state) => state.auth);

  const [month] = useState(new Date().getMonth());
  const months = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];
  const [fields, setFields] = useState([]);

  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const GetColumnFields = () => {
    setLoading(true);
    const adminOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        selectedUser: user.id,
        idRole: user.id_role,
        idOrganization: user.id_organization,
        idModule: user.idModule,
        page: "valutazione",
      }),
    };
    fetch(config.apiUrl + "obiettivi/GetColumnsDipendente.php", adminOptions)
      .then((response) => response.json())
      .then((data) => setFields(data))
      .then(() => {
        var object = {};
        fields.forEach((element) => {
          object[element.name] = 0;
        });
        setInitialValues(object);
      })
      .then(() => setLoading(false));
  };

  useEffect(() => {
    GetColumnFields();
  }, [props.show === true]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      const adminOptions = {
        headers: {
          Authorization:
            "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
        },
        method: "POST",
        body: JSON.stringify({
          selectedUser: user.id,
          type: "autovalutazione",
          idRole: user.id_role,
          idUser: user.id,
          idOrganization: user.id_organization,
          values: values,
          page: "valutazione"
        }),
      };
      fetch(config.apiUrl + "obiettivi/ValutazioneEmployee.php", adminOptions);
      props.setCount(props.count + 1);
      props.handleClose();
    },
  });

  const classes = useStyles();

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header>
          <Modal.Title>Autovalutazione di {months[month]}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!loading ? (
            <Form>
              <Row>
                <div className="col-12 mb-3"></div>
                {fields.map((e, key) => {
                  return (
                    <div className="col-6">
                      <Box
                        className={classes.root}
                        component="fieldset"
                        mb={3}
                        borderColor="transparent"
                      >
                        <Typography component="legend">
                          {e.title}
                          {e.description !== false ? (
                            <OverlayTrigger
                              trigger={["hover", "focus"]}
                              placement="right"
                              overlay={
                                <Popover id={`popover-positioned-top`}>
                                  <Popover.Content>
                                    {e.description}
                                  </Popover.Content>
                                </Popover>
                              }
                            >
                              <i
                                color="info"
                                className="far fa-question-circle fa-sm ml-3"
                              ></i>
                            </OverlayTrigger>
                          ) : null}
                        </Typography>
                        <Rating
                          size="large"
                          key={key}
                          name={e.name}
                          onChange={formik.handleChange}
                          precision={0.5}
                        />
                      </Box>
                    </div>
                  );
                })}
              </Row>
            </Form>
          ) : (
            <div className="col-12">
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="top"
            overlay={
              <Popover id={`popover-positioned-top`}>
                <Popover.Content>
                  Se è già stata effettuata un'autovalutazione questo mese,
                  aggiornerai l'ultima
                </Popover.Content>
              </Popover>
            }
          >
            <Button onClick={formik.handleSubmit} variant="primary" size="sm">
              Valuta
            </Button>
          </OverlayTrigger>

          <Button variant="danger" size="sm" onClick={props.handleClose}>
            Annulla
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
