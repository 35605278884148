import React, { useState, useEffect } from "react";
import SVG from 'react-inlinesvg'
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";

import config from "../../../../config/config";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import moment from 'moment-timezone';
import { Button, Modal } from 'react-bootstrap';

//import { TermsAndConditionsPage } from "../../../../Includes/TermsAndConditionsPage";

import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

// ES6 Modules or TypeScript
import Swal from 'sweetalert2'

function Registration(props) {
/*eslint-disable */
  var initial_arr = {
    firstname: "",
    lastname: "",
    email: "",
    birthday: "",
    password: "",
    changepassword: "",
    companyname: "",
    address: "",
    vat: "",
    country: "",
    region: "",
    city: "",
    zip_code: "",
    phone: "",
  };

  const [copy_address, set_copy_address] = useState(initial_arr);

  const initialValues = {
    // STEP 1
    firstname: copy_address.firstname,
    lastname: copy_address.lastname,
    email: copy_address.email,
    birthday: copy_address.birthday,
    password: copy_address.password,
    changepassword: copy_address.changepassword,

    // STEP 2
    companyname: copy_address.companyname,
    address: copy_address.address,    
    vat: copy_address.vat,
    country: copy_address.country,
    region: copy_address.region,
    city: copy_address.city,
    zip_code: copy_address.zip_code,
    phone: copy_address.phone,

    // // STEP 3
    companyname: copy_address.companyname,
    address: copy_address.address,    
    vat: copy_address.vat,
    country: copy_address.country,
    region: copy_address.region,
    city: copy_address.city,
    zip_code: copy_address.zip_code,
    phone: copy_address.phone,
    acceptTerms: false,
  };

  function expireDateTrial() {
    var expire = (new Date().getDate()+15)+'/'+(new Date().getMonth()+1)+'/'+new Date().getFullYear()
    return expire
  }

  useEffect(() => {
    // fetchDataCountries();
    fetchDataAllCountries();
    //fetchDataCustomerRoles();
    fetchDataprofiles();
  }, [])

  // const fetchDataCountries = async () => {

  //   const requestOptions = {
  //     headers: {
  //       'Authorization': "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
  //     },
  //     method: 'GET',
  //   };

  //   const rawData = await fetch(config.apiUrl + 'myCellar/GetCountries.php?id_user=0', requestOptions);
  //   const data = await rawData.json();
  //   setListCountries(data);

  // }

  const fetchDataAllCountries = async () => {

    const requestOptions = {
      headers: {
        'Authorization': "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: 'GET',
    };

    const rawData = await fetch(config.apiUrl + 'users/GetAllCountries.php?id_user=0', requestOptions);
    const data = await rawData.json();
    setListCountries(data);

  }

  const fetchDataprofiles = async () => {

    const requestOptions = {
      headers: {
        'Authorization': "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: 'GET',
    };

    const rawData = await fetch(config.apiUrl + 'users/GetAllProfilesActiveUser.php?id_user=0', requestOptions);
    const data = await rawData.json();
    setdataCustomUser(data);
  }

  const [modalShow, setModalShow] = useState(false);

  const TermsAndConditions = (props) => {

    return (
      <Modal
        {...props}
        size="xl"

        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Terms & Conditions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {/* <TermsAndConditionsPage /> */}

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }


  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const RegistrationSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(3, "Almeno 3 caratteri")
      .max(50, "Massimo 50 caratteri")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    lastname: Yup.string()
      .min(3, "Almeno 3 caratteri")
      .max(50, "Massimo 50 caratteri")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    email: Yup.string()
      .email("Formato email errato")
      .min(3, "Almeno 3 caratteri")
      .max(50, "Massimo 50 caratteri")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Almeno 3 caratteri")
      .max(50, "Massimo 50 caratteri")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Le due password non corrispondono"
        ),
      }),
    
    phone: Yup.string()
    .required("Il numero di telefono è obbligatorio")
    .matches(/^[0-9]+$/, "Il numero di telefono deve essere formato solo da numeri")
    .min(5, "Minimo 5 numeri")
    .max(12, "Massimo 12 numeri"),
    companyName: Yup.string().required("Il nome dell'azienda è obbligatorio"),
    address: Yup.string().required("L'indirizzo è obbligatorio"),
    country: Yup.string().required("La nazione è obbligatoria"),
    region: Yup.string().required("La regione è obbligatoria"),
    city: Yup.string().required("La città è obbligatoria"),
    vat: Yup.string().required("La partita iva è obbligatoria")
    .matches(/^[0-9]+$/, "La partita IVA deve essere formata solo da numeri")
    .min(11, "La partita IVA deve essere composta da 11 cifre")
    .max(11, "La partita IVA deve essere composta da 11 cifre"),
    zip_code: Yup.string().required("Il CAP è obbligatorio")
    .required("Il CAP è obbligatorio")
    .matches(/^[0-9]+$/, "Il CAP deve essere formato solo da numeri")
    .min(5, "Il CAP deve essere composto da 5 cifre")
    .max(5, "Il CAP deve essere composto da 5 cifre"),
    acceptTerms: Yup.bool().required(
      "Devi accettare prima i termini e le condizioni"
    ),
  });

  const [step1, setStep1] = useState(1);
  const [step2, setStep2] = useState(0);
  const [step3, setStep3] = useState(0);

  const [listCountries, setListCountries] = useState([]);
  const [listAllCountries, setListAllCountries] = useState([]);
  //const [dataCustomerRoles, setdataCustomerRoles] = useState([]);
  const [dataCustomUser, setdataCustomUser] = useState([]);

  const [input_role_customer, set_input_role_customer] = useState(0);
  const [input_recurring, set_input_recurring] = useState(0);
  const [input_id_profile, set_input_id_profile] = useState(0);
  const [input_id_customer_stripe, set_input_id_customer_stripe] = useState(0);
  const [detailPlanChoose, set_detailPlanChoose] = useState([]);

  // FOR STRIPE
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const [prepared, setPrepared] = useState(0);
  const [is_registered, set_is_registered] = useState(0);

  const [is_checked, set_is_checked] = useState(false);

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const PrepareCallStripe = () => {
    const requestOptions = {
      headers: {
        'Authorization': "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: 'POST',
      body: JSON.stringify({
        "email": formik.values.email,
        "name": formik.values.firstname + ' ' + formik.values.lastname,
        "phone": formik.values.phone,
      })
    };

    // Create PaymentIntent as soon as the page loads
    window
      .fetch(config.apiUrl + 'stripe/CreateCustomer.php', requestOptions)
      .then(res => {
        return res.json();
      })
      .then(data => {
        setPrepared(1);
        setClientSecret(data.clientSecret);
        set_input_id_customer_stripe(data.id_customer);
      });

  }

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const handleSubmitCard = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) {
      setError("Errore critico, ripeti l'operazione");
      setProcessing(false);
      return;
    }

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: 'Jenny Rosen',
        },
      }
    });

    if (result.error) {
      setError(`Pagamento fallito ${result.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      //setProcessing(false);
      //setSucceeded(true);
      formik.handleSubmit();
    }

  };
  // END FOR STRIPE

  // FUNCTION FOR THE PLANS
  const ChoosePlan = (profile) => {
    set_input_recurring(profile.recurring);
    set_detailPlanChoose(profile);
  }

  const ResetPlan = () => {

    set_input_role_customer(0);
    set_input_recurring(0);
    set_input_id_profile(0);

  }
  
  const ShowPlan = (props) => {
    switch(props.profile.type) {
      case 'prova' :
        var profile = {type: 'Prova', recurring: '15 giorni', price: 'Gratis'};
        break;
      case 'lite' :
        profile = {type: 'Lite', recurring: 'al mese', price: '80€'};
        break;
      case 'pro' :
        profile = {type: 'Pro', recurring: 'al mese', price: '100€'};
        break;
        default:
    }
    return (
      <><div  className="text-center" >

        <h1 style={{ paddingBottom: "0px" ,  marginBottom: "0px" }}>{profile.type} </h1>
        <p className="text-muted" style={{ fontsize: "20px" }}>{profile.price} {profile.recurring} </p>

        <button className="btn btn-primary" onClick={(e) => { ChoosePlan(profile) }} >Seleziona</button>
      </div>
      </>);
  }

  // END FUNCTION FOR THE PLANS

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();

      setTimeout(() => {
        //Do request to your server for user update, we just imitate user update there, For example:

        const requestOptions = {
          headers: {
            'Authorization': "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
          },
          method: 'POST',
          body: JSON.stringify({
            "name": values.name,
            "last_name": values.last_name,
            "email": values.email,
            "birthday": values.birthday,
            "password": values.password,

            // STEP 2
            "companyname": values.companyname,
            "address": values.address,
            "address": values.address,
            "vat": values.vat,
            "country": values.country,
            "region": values.region,
            "city": values.city,
            "postalcode": values.postalcode,
            "phone": values.phone,

            //"type_profile": values.type_profile,
            "type_profile": input_role_customer,
            "recurring": input_recurring,
            "id_profile": input_id_profile,
            "id_customer_stripe": input_id_customer_stripe,
          })
        };

        fetch(config.apiUrl + 'users/CreateUserRegistration.php', requestOptions)
          .then(response => response.json())
          .then(result => {

            if (result > 0) {
              set_is_registered(1);

              disableLoading();
              setSubmitting(false);

            } else if (result === -1) {

              setSubmitting(false);
              disableLoading();
              Swal.fire({
                title: "L'email è già stata registrata",
                text: "Please try again using another email",
                icon: "warning",
                button: "Okay",
              }).then((result) => {
                //window.location.href = config.siteUrl;
                setPrepared(0);
                handlingStep(1, 0, 0, 0);
                setSucceeded(false);
                setProcessing(false);
              });

            }
            else {
              setSubmitting(false);
              // setStatus(
              //   intl.formatMessage({
              //     id: "AUTH.VALIDATION.INVALID_LOGIN",
              //   })
              // );
              disableLoading();
              Swal.fire({
                title: "Error on registration user",
                text: "Please try again",
                icon: "error",
                button: "Okay",
              }).then((result) => {
                window.location.reload();
              });

            }
          })
          .catch(error => {

            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
            disableLoading();
          });

      }, 1000);
      // register(values.email, values.fullname, values.username, values.password)
      //   .then(({ data: { accessToken } }) => {
      //     props.register(accessToken);
      //     disableLoading();
      //     setSubmitting(false);
      //   })
      //   .catch(() => {
      //     setSubmitting(false);
      //     setStatus(
      //       intl.formatMessage({
      //         id: "AUTH.VALIDATION.INVALID_LOGIN",
      //       })
      //     );
      //     disableLoading();
      //   });
    },
  });

  const handlingStep = (step1, step2, step3) => {
    setStep1(step1);
    setStep2(step2);
    setStep3(step3);

    if (step2 && is_checked) {
      setStep3(1);
      step3 = 1;
    }

    if (step3 > 0 && prepared === 0) {
      PrepareCallStripe();
    }
  }

  // const handlingCopyAddress = (e) => {
  //   if (is_checked) {
  //     set_is_checked(false);

  //     set_copy_address([]);
  //   } else {
  //     set_is_checked(true);

  //     var new_arr = {
  //       firstname: formik.values.name,
  //       lastname: formik.values.lastname,
  //       email: formik.values.email,
  //       birthday: formik.values.birthday,
  //       password: formik.values.password,
  //       changepassword: formik.values.changepassword,
  //       companyname: formik.values.companyname,
  //       address: formik.values.address,
  //       vat: formik.values.vat,
  //       country: formik.values.country,
  //       region: formik.values.region,
  //       city: formik.values.city,
  //       zip_code: formik.values.zip_code,
  //       phone: formik.values.phone,
  //     };

  //     set_copy_address(new_arr);
  //   }
  // }

  var startDate = new Date();
  startDate.setFullYear(startDate.getFullYear() - 18);
  var startDate = moment(startDate).format("YYYY-MM-DD")

  return (

    <>
      {is_registered ?
        <>
          <div className="text-center mb-5 mb-lg-5">
            <i style={{ fontSize: "50px" }} class="fas fa-envelope-open-text"></i>
            <h1>Controlla la tua mail!</h1>
            <h3>É stata inviata una mail per confermare la tua registrazione</h3>
          </div>
        </>
        :
        <>
          <TermsAndConditions
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
          <div style={input_recurring === 0 ? { display: "block", width: "100%" } : { display: "none", width: "100%" }}>

            <div className="text-center mb-10 mb-lg-20">
              <div className="text-center mb-5 mb-lg-5">
                <h1>Seleziona il tuo piano</h1>
              </div>
              <div className="row">
                {/* {dataCustomUser.length > 0 ? dataCustomUser.map((plan, key)=>
                  <ShowPlan profile={plan} />
                ):
                <div className="divLoading">
                <span className="spinner-border" style={{ width: "3rem", height: "3rem" }} role="status">
                  <span className="sr-only">Caricamento...</span>
                </span>
              </div>
              
              } */}
                {dataCustomUser.length > 0 ? dataCustomUser.map((role, key) => {
                  let colLarge = 12 / dataCustomUser.length;
                  const pic = toAbsoluteUrl(`/media/customer-card/background/${role.type}.jpg`);

                  return <div key={key} className={`col-lg-${colLarge}`}>
                    <div className="cont-plan">
                      <ShowPlan profile={role} />
                    </div>
                  </div>;
                  }) :
                  <div className="divLoading">
                    <span className="spinner-border" style={{ width: "3rem", height: "3rem" }} role="status">
                      <span className="sr-only">Caricamento...</span>
                    </span>
                  </div>
                }
              </div>

              <Link to="/auth/login">
                <button
                  type="button"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Ritorna al login
                </button>
              </Link>
            </div>
          </div>
          <div className="login-form login-signin" style={input_recurring !== 0 ? { display: "block", width: "100%" } : { display: "none", width: "100%" }}>
            <div className="text-center mb-10">

              <h3 className="font-size-h1">
                <FormattedMessage id="AUTH.REGISTER.TITLE" />
              </h3>
              <p className="font-weight-bold">
                {detailPlanChoose.type} <small>(Scadenza: {detailPlanChoose.recurring} )</small>
              </p>
              <p className="text-muted font-weight-bold">
                {step3 ? "Inserisci una carta di credito o debito" : "Inserisci le tue informazioni per creare l'account"}
              </p>
                {detailPlanChoose.type === "Prova" ? (<p className="text-black text-center" style={{ fontsize: "20px", fontWeight:"bold" }}>NON TI VERRÀ ADDEBBITATO NESSUN COSTO 
                  <br></br>
                  fino al termine del periodo di prova
                </p>) : <p className="text-black text-center" style={{ fontsize: "20px", fontWeight:"bold" }}>TI VERRÀ ADDEBBITATO MENSILMENTE IL COSTO DI {detailPlanChoose.price}. 
                  <br></br>
                   Puoi disattivare in ogni momento.
                </p>} 
              
              <div className="PannelStep"> <span className={step1 ? "step active" : "step"}>Step <span className="step">1</span></span> - <span className={step2 ? "step active" : "step"}>Step <span className="step">2</span></span> - <span className={step3 ? "step active" : "step"}>Step <span className="step">3</span></span></div>
            </div>

            <form
              id="kt_login_signin_form"
              className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            >
              {/* begin: Alert */}
              {formik.status && (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">{formik.status}</div>
                </div>
              )}
              {/* end: Alert */}

              <div id="step1" style={step1 ? { display: "" } : { display: "none" }}>

                {/* begin: name */}
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="Nome"
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "firstname"
                    )}`}
                    name="firstname"
                    {...formik.getFieldProps("firstname")}
                  />
                  {formik.touched.firstname && formik.errors.firstname ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.firstname}</div>
                    </div>
                  ) : null}
                </div>
                {/* end: name */}

                {/* begin: last_name */}
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="Cognome"
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "lastname"
                    )}`}
                    name="lastname"
                    {...formik.getFieldProps("lastname")}
                  />
                  {formik.touched.lastname && formik.errors.lastname ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.lastname}</div>
                    </div>
                  ) : null}
                </div>
                {/* end: last_name */}

                {/* begin: Email */}
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="Email"
                    type="email"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "email"
                    )}`}
                    name="email"
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.email}</div>
                    </div>
                  ) : null}
                </div>
                {/* end: Email */}

                {/* begin: Birthday */}
                <div className="form-group fv-plugins-icon-container">
                  <label>Data di nascita</label>
                  <input
                    placeholder="Birthday"
                    type="date"
                    max={startDate}
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "firstname"
                    )}`}
                    name="firstname"
                    {...formik.getFieldProps("birthday")}
                  />
                  {formik.touched.birthday && formik.errors.birthday ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.birthday}</div>
                    </div>
                  ) : null}
                </div>
                {/* end: Birthday */}

                {/* begin: Password */}
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="Password"
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "password"
                    )}`}
                    name="password"
                    {...formik.getFieldProps("password")}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.password}</div>
                    </div>
                  ) : null}
                </div>
                {/* end: Password */}

                {/* begin: Confirm Password */}
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="Conferma Password"
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "changepassword"
                    )}`}
                    name="changepassword"
                    {...formik.getFieldProps("changepassword")}
                  />
                  {formik.touched.changepassword && formik.errors.changepassword ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.changepassword}
                      </div>
                    </div>
                  ) : null}
                </div>
                {/* end: Confirm Password */}

                <div className="form-group d-flex flex-wrap flex-center">

                  <button
                    type="button"
                    onClick={(e) => { ResetPlan() }}
                    className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  >
                    Annulla
                  </button>

                  <button
                    type="button"
                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    onClick={(e) => handlingStep(0, 1, 0, 0)}
                    disabled={
                      !formik.values.firstname ||
                      formik.touched.firstname && formik.errors.firstname ||
                      !formik.values.lastname ||
                      formik.touched.lastname && formik.errors.lastname ||
                      !formik.values.email ||
                      formik.touched.email && formik.errors.email ||
                      !formik.values.birthday ||
                      formik.touched.birthday && formik.errors.birthday ||
                      !formik.values.password ||
                      formik.touched.password && formik.errors.password ||
                      !formik.values.changepassword ||
                      formik.touched.changepassword && formik.errors.changepassword
                    }
                  >
                    <span>Avanti</span>
                  </button>

                </div>

              </div>

              <div id="step2" style={step2 ? { display: "" } : { display: "none" }}>

                <div className="row">
                  <div className="col-lg-12 col-xl-12">
                    <h5 className="font-weight-bold mb-6">Dati Azienda</h5>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-12 col-xl-12">
                    <input
                      type="text"
                      placeholder="Nome azienda"
                      className={`form-control form-control-lg form-control-solid`}
                      name="companyName"
                      {...formik.getFieldProps("companyName")}
                    />
                  </div>
                  {formik.touched.companyName && formik.errors.companyName ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.companyName}</div>
                    </div>
                  ) : null}
                </div>

                <div className="form-group row">
                  <div className="col-lg-12 col-xl-12">
                    <div className="input-group input-group-lg input-group-solid">
                      <input
                        type="text"
                        placeholder="Partita IVA"
                        className={`form-control form-control-lg form-control-solid ${getInputClasses(
                          "vat"
                        )}`}
                        name="vat"
                        {...formik.getFieldProps("vat")}
                      />
                      {formik.touched.vat && formik.errors.vat ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.vat}</div>
                    </div>
                  ) : null}
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-12 col-xl-12">
                    <div className="input-group input-group-lg input-group-solid">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-phone"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        placeholder="Numero telefono"
                        className={`form-control form-control-lg form-control-solid ${getInputClasses(
                          "phone"
                        )}`}
                        name="phone"
                        {...formik.getFieldProps("phone")}
                      />
                      {formik.touched.phone && formik.errors.phone ? (
                        <div className="invalid-feedback">{formik.errors.phone}</div>
                      ) : null}
                    </div>
                  </div>
                </div>


                <div className="row">
                  <label className="col-xl-3"></label>
                  <div className="col-lg-12 col-xl-12">
                    <h5 className="font-weight-bold mt-10 mb-6">Indirizzo</h5>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12 col-xl-12">
                    <div className="input-group input-group-lg input-group-solid">
                      <input
                        type="text"
                        placeholder="Indirizzo"
                        className={`form-control form-control-lg form-control-solid ${getInputClasses(
                          "address"
                        )}`}
                        name="address"
                        {...formik.getFieldProps("address")}
                      />
                    </div>
                    {formik.touched.address && formik.errors.address ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.address}</div>
                    </div>
                  ) : null}
                  </div>
                </div>
                
                <div className="form-group row">
                  <label className="col-xl-12 col-lg-12 col-form-label">
                    Nazione
                  </label>
                  <div className="col-lg-12 col-xl-12">
                    <div className="input-group input-group-lg input-group-solid">
                      <select
                        className="form-control form-control-lg form-control-solid"
                        name="country"
                        {...formik.getFieldProps("country")}
                      >
                        <option>Seleziona la nazione</option>
                        {listCountries.map((e, key) => {

                          return <option key={key} value={e.id_country}>{e.country_name}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                  {formik.touched.country && formik.errors.country ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.country}</div>
                    </div>
                  ) : null}
                </div>

                <div className="form-group row">
                  <div className="col-lg-12 col-xl-12">
                    <div className="input-group input-group-lg input-group-solid">
                      <input
                        type="text"
                        placeholder="Regione"
                        className={`form-control form-control-lg form-control-solid ${getInputClasses(
                          "region"
                        )}`}
                        name="region"
                        {...formik.getFieldProps("region")}
                      />
                    </div>
                    {formik.touched.region && formik.errors.region ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.region}</div>
                    </div>
                  ) : null}
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-12 col-xl-12">
                    <div className="input-group input-group-lg input-group-solid">
                      <input
                        type="text"
                        placeholder="Città"
                        className={`form-control form-control-lg form-control-solid ${getInputClasses(
                          "city"
                        )}`}
                        name="city"
                        {...formik.getFieldProps("city")}
                      />
                    </div>
                    {formik.touched.city && formik.errors.city ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.city}</div>
                    </div>
                  ) : null}
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-12 col-xl-12">
                    <div className="input-group input-group-lg input-group-solid">
                      <input
                        type="text"
                        placeholder="CAP"
                        className={`form-control form-control-lg form-control-solid ${getInputClasses(
                          "zip_code"
                        )}`}
                        name="zip_code"
                        {...formik.getFieldProps("zip_code")}
                      />
                    </div>
                    {formik.touched.zip_code && formik.errors.zip_code ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.zip_code}</div>
                    </div>
                  ) : null}
                  </div>
                </div>

                <div className="form-group d-flex flex-wrap flex-center">
                  <button
                    type="button"
                    className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    onClick={(e) => handlingStep(1, 0, 0)}
                  >
                    Indietro
                  </button>

                  <button
                    type="button"
                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    onClick={(e) => handlingStep(0, 0, 1)}
                    disabled={
                      // phone: Yup.string().required("Phone  in Shippment Address is required"),
                      // address: Yup.string().required("Street Address in Shippment Address is required"),
                      // country: Yup.string().required("Country in Shippment Address is required"),
                      // region: Yup.string().required("Region in Shippment Address is required"),
                      // city: Yup.string().required("City in Shippment Address is required"),
                      // postalcode: Yup.string().required("Postal Code in Shippment Address is required"),
                      
                      !formik.values.companyName ||
                      formik.touched.companyname && formik.errors.companyname ||
                      !formik.values.address ||
                      formik.touched.address && formik.errors.address ||
                      !formik.values.phone ||
                      formik.touched.phone && formik.errors.phone ||
                      !formik.values.country ||
                      formik.touched.country && formik.errors.country ||
                      !formik.values.vat ||
                      formik.touched.vat && formik.errors.vat ||
                      !formik.values.region ||
                      formik.touched.region && formik.errors.region ||
                      !formik.values.city ||
                      formik.touched.city && formik.errors.city ||
                      !formik.values.zip_code ||
                      formik.touched.zip_code && formik.errors.zip_code
                    }
                  >
                    <span>Successivo</span>
                  </button>

                </div>
              </div>
              
              <div id="step3" style={step3 ? { display: "" } : { display: "none" }}>

                



                <div className="orderSummary">
                  <div className="title">Il tuo piano: {detailPlanChoose.type}</div>
                    {detailPlanChoose.price === "Gratis" ? <div><p>La prova scadrà il {expireDateTrial()}</p></div>:<div>COSTO MENSILE:</div>}
                    <div><b>{detailPlanChoose.price}</b></div>
                </div>

                <div style={{marginBottom:"1%"}}>
                  <span className="svg-icon svg-icon-payment svg-icon-primary ml-1" >
                    <SVG src={toAbsoluteUrl("/media/svg/payment/american-express.svg")}/>
                  </span>
                  <span className="svg-icon svg-icon-payment svg-icon-primary ml-1" >
                    <SVG src={toAbsoluteUrl("/media/svg/payment/discover.svg")}/>
                  </span>
                  <span className="svg-icon svg-icon-payment svg-icon-primary ml-1" >
                    <SVG src={toAbsoluteUrl("/media/svg/payment/maestro.svg")}/>
                  </span>
                  <span className="svg-icon svg-icon-payment svg-icon-primary ml-1" >
                    <SVG src={toAbsoluteUrl("/media/svg/payment/mastercard.svg")}/>
                  </span>
                  <span className="svg-icon svg-icon-payment svg-icon-primary ml-1" >
                    <SVG src={toAbsoluteUrl("/media/svg/payment/visa.svg")}/>
                  </span>
                </div>

                <div className="contStripe">
                  <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
                  <button
                  
                    disabled={processing ||
                      disabled ||
                      succeeded ||
                      formik.isSubmitting ||
                      !formik.isValid ||
                      !formik.values.acceptTerms ||
                      input_recurring === 0 ||
                      input_role_customer === 0 ||
                      input_id_customer_stripe === 0}
                    className="primary"
                    id="submit"
                    onClick={handleSubmitCard}
                  >
                    <span id="button-text">
                      {processing ? (
                        //<div className="spinner" id="spinner"></div>
                        <>Processando... <span className="ml-3 spinner spinner-white"></span></>
                      ) : (
                        "Abbonati"
                      )}
                    </span>
                  </button>
                  {/* Show any error that happens when processing the payment */}
                  {error && (
                    <div className="card-error" role="alert">
                      {error}
                    </div>
                  )}
                  {/* Show a success message upon completion 
              <p className={succeeded ? "result-message" : "result-message hidden"}>profile Completed!</p>*/}
                </div>

                {/* begin: Terms and Conditions */}
                <div className="form-group row" style={{ marginTop: "10px" }}>


                  <div class="col-6 d-flex justify-content-between ant">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        name="acceptTerms"
                        className="m-1"
                        {...formik.getFieldProps("acceptTerms")}
                      />
                      <br></br>

                      <Link to="#" onClick={() => setModalShow(true)}>
                        Iscrivendoti accetti i nostri termini.
                      </Link>
                      <span />
                    </label>
                    {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.acceptTerms}</div>
                      </div>
                    ) : null}
                  </div>
                </div>

                {/* end: Terms and Conditions */}

                <div className="form-group d-flex flex-wrap flex-center">

                  <button
                    type="button"
                    className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    onClick={(e) => handlingStep(0, 1, 0)}
                  >
                    Indietro
                  </button>

                  {/*<button
                type="submit"
                disabled={
                  formik.isSubmitting ||
                  !formik.isValid ||
                  !formik.values.acceptTerms ||
                  input_recurring == '' ||
                  input_role_customer == 0
                }
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                <span>Submit</span>
                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>*/}
                </div>

              </div>

            </form>
          </div>
        </>}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));