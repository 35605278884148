/*eslint-disable */
import React, { useState, useEffect } from "react";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  Toolbar,
  SearchPanel,
  ColumnChooser,
  TableColumnVisibility,
} from "@devexpress/dx-react-grid-bootstrap4";
import {
  PagingState,
  IntegratedPaging,
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import {
  Card,
  Modal,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import config, { roles } from "../../../../config/config";
import { useSelector } from "react-redux";
import { Skeleton } from "@material-ui/lab";

export const TestDrive = (props) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [show, setShow] = useState(false);
  const handleNew = () => setShow(true);
  const handleEdit = (drive) => {
    setDrive(drive);
    setShow(true);
  };
  const handleClose = () => {
    setDrive({
      id: 0,
      firstname: "",
      lastname: "",
      phone: "",
      veicolo: "",
      idTrattativa: "",
      dateTest: "",
      startTime: "",
      endTime: "",
    });
    setShow(false);
  };
  const [showDelete, setShowDelete] = useState(false);
  const handleOpenDelete = (drive) => {
    setDrive(drive);
    setShowDelete(true);
  };
  const handleCloseDelete = () => {
    setDrive({
      id: 0,
      firstname: "",
      lastname: "",
      phone: "",
      veicolo: "",
      idTrattativa: "",
      dateTest: "",
      startTime: "",
      endTime: "",
    });
    setShowDelete(false);
  };
  const [sorting, setSorting] = useState([
    { columnName: "dateTest", direction: "desc" },
  ]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [pageSizes] = useState([15, 30, 45, 0]);
  const [buttonCol] = useState(["button"]);
  const [searchValue, setSearchState] = useState("");
  const [defaultHiddenColumnNames] = useState(["id"]);
  const [tableColumnVisibilityColumnExtensions] = useState([
    { columnName: "id", togglingEnabled: false },
  ]);
  const [drive, setDrive] = useState({
    id: 0,
    firstname: "",
    lastname: "",
    phone: "",
    veicolo: "",
    idTrattativa: "",
    dateTest: "",
    startTime: "",
    endTime: "",
  });

  const columns = [
    { name: "id", title: "ID" },
    { name: "firstname", title: "Nome" },
    { name: "lastname", title: "Cognome" },
    { name: "phone", title: "Telefono" },
    { name: "veicolo", title: "Veicolo" },
    { name: "idTrattativa", title: "ID Trattativa" },
    { name: "dateTest", title: "Data test" },
    { name: "startTime", title: "Ora inizio" },
    { name: "endTime", title: "Ora fine" },
    { name: "button", title: "Azioni" },
  ];

  const ModalDelete = () => {
    const Delete = () => {
      const options = {
        headers: {
          Authorization:
            "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
        },
        method: "POST",
        body: JSON.stringify({
          idTest: drive.id,
        }),
      };
      fetch(config.apiUrl + "obiettivi/DeleteTestDrive.php", options).then(() =>
        setCount(count + 1)
      );
      handleCloseDelete();
    };

    const formatDate = (d) => {
      let monthNames = [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre",
      ];

      let date = new Date(d);

      let day = date.getDate();

      let month = monthNames[date.getMonth()];

      let year = date.getFullYear();

      return `${day} ${month} ${year}`;
    };

    return (
      <Modal show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header>
          <Modal.Title>
            ELIMINA TEST DRIVE ({formatDate(drive.dateTest)})
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Sei sicuro di voler eliminare questo test drive definitivamente
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" size="sm" onClick={Delete}>
            Elimina
          </Button>
          <Button size="sm" onClick={handleCloseDelete}>
            Annulla
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ModalNewEdit = () => {
    const getInputClassesForHandle = (fieldname) => {
      if (formik.touched[fieldname] && formik.errors[fieldname]) {
        return "is-invalid";
      }

      if (formik.touched[fieldname] && !formik.errors[fieldname]) {
        return "is-valid";
      }
      return "";
    };

    const schema = Yup.object({
      firstname: Yup.string("Inserire un nome valido").required(
        "Inserire una nome valido"
      ),
      lastname: Yup.string("Inserire un cognome valido").required(
        "Inserire un cognome valido"
      ),
      phone: Yup.string("Inserire un nome di telefono valido")
        .notRequired()
        .matches(
          /^([0]([.][0-9]+)?|[1-9]([0-9]+)?([.][0-9]+)?)$/,
          "Solo caratteri numerici"
        )
        .max(30, "Massimo 30 caratteri")
        .min(6, "Minimo 6 caratteri"),
      dateTest: Yup.date("Inserire una data valida").required(
        "Inserire una data valida"
      ),
      startTime: Yup.string("Inserire un orario valido").required(
        "Inserire un orario valido"
      ),
      endTime: Yup.string("Inserire un orario valido").required(
        "Inserire un orario valido"
      ),
    });

    const formik = useFormik({
      initialValues: drive,
      enableReinitialize: true,
      validationSchema: schema,
      onSubmit: (values) => {
        const options = {
          headers: {
            Authorization:
              "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
          },
          method: "POST",
          body: JSON.stringify({
            idUser: props.idUser,
            idOrganization: props.idOrganization,
            idTest: values.id,
            firstname: values.firstname,
            lastname: values.lastname,
            phone: values.phone,
            veicolo: values.veicolo,
            idTrattativa: values.idTrattativa,
            dateTest: values.dateTest,
            startTime: values.startTime,
            endTime: values.endTime,
          }),
        };
        setLoading(true);
        fetch(config.apiUrl + "obiettivi/AddTestDrive.php", options)
          .then(() => setCount(count + 1))
          .then(() => handleClose());
      },
    });

    return (
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            {drive.id === 0
              ? "Aggiungi nuovo test drive"
              : "Modifica test drive"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Row>
              <Form.Group className="col-6">
                <Form.Label className="col-12">Nome</Form.Label>
                <Form.Control
                  type="text"
                  name="firstname"
                  placeholder="Nome cliente..."
                  value={formik.values.firstname}
                  onChange={formik.handleChange}
                  className={`form-control ${getInputClassesForHandle(
                    "firstname"
                  )}`}
                  {...formik.getFieldProps("firstname")}
                />
                {formik.touched.firstname && formik.errors.firstname ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.firstname}
                    </div>
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group className="col-6">
                <Form.Label className="col-12">Cognome</Form.Label>
                <Form.Control
                  type="text"
                  name="lastname"
                  placeholder="Cognome cliente..."
                  value={formik.values.lastname}
                  handleChange={formik.handleChange}
                  className={`form-control ${getInputClassesForHandle(
                    "lastname"
                  )}`}
                  {...formik.getFieldProps("lastname")}
                />
                {formik.touched.lastname && formik.errors.lastname ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.lastname}
                    </div>
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group className="col-6">
                <Form.Label className="col-12">Telefono</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  placeholder="Telefono cliente..."
                  value={formik.values.phone}
                  handleChange={formik.handleChange}
                  className={`form-control ${getInputClassesForHandle(
                    "phone"
                  )}`}
                  {...formik.getFieldProps("phone")}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.phone}</div>
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group className="col-6">
                <Form.Label className="col-12">Veicolo</Form.Label>
                <Form.Control
                  type="text"
                  name="veicolo"
                  placeholder="Veicolo testato..."
                  value={formik.values.lastname}
                  handleChange={formik.handleChange}
                  className={`form-control ${getInputClassesForHandle(
                    "veicolo"
                  )}`}
                  {...formik.getFieldProps("veicolo")}
                />
                {formik.touched.veicolo && formik.errors.veicolo ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.veicolo}</div>
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group className="col-6">
                <Form.Label className="col-12">ID Trattativa</Form.Label>
                <Form.Control
                  type="text"
                  name="idTrattativa"
                  placeholder="Id trattativa..."
                  value={formik.values.idTrattativa}
                  handleChange={formik.handleChange}
                  className={`form-control ${getInputClassesForHandle(
                    "idTrattativa"
                  )}`}
                  {...formik.getFieldProps("idTrattativa")}
                />
                {formik.touched.idTrattativa && formik.errors.idTrattativa ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.idTrattativa}</div>
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group className="col-12">
                <Form.Label className="col-12">Data test</Form.Label>
                <Form.Control
                  type="date"
                  name="dateTest"
                  placeholder="Data test..."
                  value={formik.values.dateTest}
                  handleChange={formik.handleChange}
                  className={`form-control ${getInputClassesForHandle(
                    "dateTest"
                  )}`}
                  {...formik.getFieldProps("dateTest")}
                />
                {formik.touched.dateTest && formik.errors.dateTest ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.dateTest}
                    </div>
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group className="col-6">
                <Form.Label className="col-12">Ora inizio test</Form.Label>
                <Form.Control
                  type="time"
                  name="startTime"
                  placeholder="Ora inizio test..."
                  value={formik.values.startTime}
                  handleChange={formik.handleChange}
                  className={`form-control ${getInputClassesForHandle(
                    "startTime"
                  )}`}
                  {...formik.getFieldProps("startTime")}
                />
                {formik.touched.startTime && formik.errors.startTime ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.startTime}
                    </div>
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group className="col-6">
                <Form.Label className="col-12">Ora fine test</Form.Label>
                <Form.Control
                  type="time"
                  name="endTime"
                  placeholder="Ora fine test..."
                  value={formik.values.endTime}
                  handleChange={formik.handleChange}
                  className={`form-control ${getInputClassesForHandle(
                    "endTime"
                  )}`}
                  {...formik.getFieldProps("endTime")}
                />
                {formik.touched.endTime && formik.errors.endTime ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.endTime}</div>
                  </div>
                ) : null}
              </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" size="sm" onClick={handleClose}>
            Annulla
          </Button>
          <Button onClick={formik.handleSubmit} variant="primary" size="sm">
            {drive.id === 0 ? "Crea" : "Modifica"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const options = {
    headers: {
      Authorization:
        "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
    },
    method: "POST",
    body: JSON.stringify({
      idUser: props.idUser,
      idOrganization: props.idOrganization,
    }),
  };

  const GetTestDrive = () => {
    setLoading(true);
    fetch(config.apiUrl + "obiettivi/GetTestDrive.php", options)
      .then((response) => response.json())
      .then((data) => setRows(data))
      .then(() => setLoading(false));
  };

  const renderEdit = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Modifica
    </Tooltip>
  );

  const renderDelete = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Elimina
    </Tooltip>
  );

  const editColum = ({ value }) => (
    <>
      <OverlayTrigger placement="top" overlay={renderEdit}>
        <i
          onClick={() => handleEdit(value)}
          style={{ cursor: "pointer", color: "#1565c0" }}
          className="fas fa-pen ml-3 mr-3 mt-1"
        ></i>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={renderDelete}>
        <i
          onClick={() => handleOpenDelete(value)}
          style={{ cursor: "pointer", color: "#bb3e03" }}
          className="fas fa-trash-alt ml-3 mt-1"
        ></i>
      </OverlayTrigger>
    </>
  );

  const ButtonColumn = (props) => (
    <DataTypeProvider formatterComponent={editColum} {...props} />
  );

  useEffect(() => {
    if (props.idUser) {
      GetTestDrive();
    }
  }, [count, props.idUser]);

  return (
    <Card className="p-5 mt-4">
      <Card.Header>
        <Card.Title className="card-title-h3">Tabell test drive</Card.Title>
      </Card.Header>
      <Card.Body>
        <div className="col-12">
          <Button onClick={handleNew} size="sm">
            Aggiungi nuovo
          </Button>
        </div>
        <ModalNewEdit />
        <ModalDelete />
        <div className="col-12">
          {!loading ? (
            <Grid rows={rows} columns={columns}>
              <ButtonColumn for={buttonCol} />
              <SortingState sorting={sorting} onSortingChange={setSorting} />
              <IntegratedSorting />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
              />
              <SearchState value={searchValue} onValueChange={setSearchState} />
              <IntegratedFiltering />
              <IntegratedPaging />
              <Table />
              <TableColumnVisibility
                defaultHiddenColumnNames={defaultHiddenColumnNames}
                columnExtensions={tableColumnVisibilityColumnExtensions}
              />
              <Toolbar />
              <SearchPanel />
              <TableHeaderRow showSortingControls />
              <PagingPanel pageSizes={pageSizes} />
              <ColumnChooser />
            </Grid>
          ) : (
            <Skeleton variant="text" />
          )}
        </div>
      </Card.Body>
    </Card>
  );
};
