/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { scaleBand } from "@devexpress/dx-chart-core";
import { ArgumentScale } from "@devexpress/dx-react-chart";
import "react-date-range/dist/styles.css"; // main style file
import { line, curveStep } from "d3-shape";
import "react-date-range/dist/theme/default.css"; // theme css file
import config, { roles } from "../../../../../config/config";
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  LineSeries,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";
import { Card } from "react-bootstrap";
import { ValueScale, Animation, Stack } from "@devexpress/dx-react-chart";

export function ChartOfficina(props) {
  const [dati, setDati] = useState([]);

  const Line = (props) => (
    <LineSeries.Path
      {...props}
      path={line()
        .x(({ arg }) => arg)
        .y(({ val }) => val)
        .curve(curveStep)}
    />
  );

  const Root = (props) => (
    <Legend.Root {...props} className="m-auto flex-row" />
  );

  const Marker = (props) => {
    const { className, color } = props;
    return (
      <svg className={className} fill={color} width="10" height="10">
        <rect x={0} y={0} width={100} height={100} />
      </svg>
    );
  };

  const format = () => (tick) => tick;

  const GetOfficinaChart = () => {
    const options = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idUser: props.idUser,
        startDate: props.startDate,
        endDate: props.endDate,
      }),
    };
    fetch(config.apiUrl + "/obiettivi/GetNumberWorksEmployee.php", options)
      .then((response) => response.json())
      .then((data) => setDati(data));
  };

  useEffect(() => {
    if (props.idUser) {
      GetOfficinaChart();
    }
    /*eslint-disable-next-line*/
  }, [props.idUser, props.startDate, props.endDate]);

  return (
    <>
      <Card className="col-12 col-lg-12 col-sm-12 mt-4">
        <Card.Header>
          <Card.Title>Statistiche</Card.Title>
        </Card.Header>
        <Card.Body>
          {
            //!props.loading ? (
            <Chart data={dati}>
              <ValueScale name="minuti" />
              <ArgumentScale factory={scaleBand} />
              <ArgumentAxis tickFormat={format} />
              <ValueAxis scaleName="minuti" showLine showTicks />
              <BarSeries
                name="Minuti raggiunti"
                valueField="minuti"
                argumentField="month"
                scaleName="minuti"
                color="#0f4c5c"
              />
              <BarSeries
                name="Numero lavori"
                valueField="nrWorks"
                argumentField="month"
                scaleName="minuti"
                color="#ff9f1c"
              />
              <LineSeries
                name="(μ)Obiettivo minuti"
                valueField="obiettivo"
                argumentField="month"
                scaleName="minuti"
                color="#ffba08"
                seriesComponent={Line}
              />
              <LineSeries
                name="(μ)Target giornaliero"
                valueField="target"
                argumentField="month"
                scaleName="minuti"
                color="#e71d36"
                seriesComponent={Line}
              />
              <Animation />
              <Legend
                position="right"
                rootComponent={Root}
                markerComponent={Marker}
              />
              <Stack />
            </Chart>
            /* ) : (
                <div className="row ml-4">
                  <div className="col-2">
                    <Skeleton animation="wave" height={500} width={50} />
                  </div>
                  <div className="col-2">
                    <Skeleton animation="wave" height={500} width={50} />
                  </div>
                  <div className="col-2">
                    <Skeleton animation="wave" height={500} width={50} />
                  </div>
                  <div className="col-2">
                    <Skeleton animation="wave" height={500} width={50} />
                  </div>
                  <div className="col-2">
                    <Skeleton animation="wave" height={500} width={50} />
                  </div>
                  <div className="col-2">
                    <Skeleton animation="wave" height={500} width={50} />
                  </div>
                </div>
              ) */
          }
        </Card.Body>
      </Card>
    </>
  );
}
