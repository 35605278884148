import {
  LOGIN_URL,
  ME_URL,
  REQUEST_PASSWORD_URL
} from "../_redux/authCrud";
import sha256 from 'crypto-js/sha256';
/*eslint-disable */

export default function mockAuth(mock,userTableMock) {

  mock.onPost(LOGIN_URL).reply(({ data }) => {

    const { email, password } = JSON.parse(data);
    
    //const password_cri = CryptoJS.MD5(password).toString();
    const password_cri = sha256(password).toString();

    if (email && password_cri) {
      const user = userTableMock.find(
        x =>
          x.email.toLowerCase() === email.toLowerCase() &&
          x.password === password_cri
      );

      if (user) {
        return [200, { ...user, password: undefined }];
      }
    }

    return [400];
  });

  mock.onPost(REQUEST_PASSWORD_URL).reply(({ data }) => {
    const { email } = JSON.parse(data);

    if (email) {
      const user = userTableMock.find(
        x => x.email.toLowerCase() === email.toLowerCase()
      );

      if (user) {
        user.password = undefined;

        return [200, { ...user, password: undefined }];
      }
    }

    return [400];
  });

  mock.onGet(ME_URL).reply(({ headers: { Authorization } }) => {
    const accessToken =
      Authorization &&
      Authorization.startsWith("Bearer ") &&
      Authorization.slice("Bearer ".length);

    if (accessToken) {
      const user = userTableMock.find(x => x.accessToken === accessToken);

      if (user) {
        return [200, { ...user, password: undefined }];
      }
    }

    return [401];
  });

  function generateUserId() {
    const ids = userTableMock.map(el => el.id);
    const maxId = Math.max(...ids);
    return maxId + 1;
  }

}