/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSubheader } from "../../../_metronic/layout";
import config, { modules, roles } from "../../../config/config";
import { Card } from "react-bootstrap";
import Select from "react-select";
import { Skeleton } from "@material-ui/lab";
import { TabellaCustomerCare } from "../../../_metronic/_partials/widgets/components/pageObiettivi/TabellaCustomerCare";
import { ChartCustomerCare } from "../../../_metronic/_partials/widgets/components/pageObiettivi/ChartCustomerCare";
import { ProgressCustomerCare } from "../../../_metronic/_partials/widgets/components/pageObiettivi/ProgressCustomerCare";
import moment from "moment";

export const CustomerCare = (props) => {
  const { user } = useSelector((state) => state.auth);
  const subheader = useSubheader();
  subheader.setTitle("Obiettivi - Customer care");

  const [loading, setLoading] = useState(false);
  const [selectedDepartment] = useState(user.idDepartment);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(user.id);

  /*Intervallo date per tutti i moduli*/
  const [minStart, setMinStart] = useState(new Date());
  const [maxStart, setMaxStart] = useState(new Date());
  const [maxEnd, setMaxEnd] = useState(new Date());
  const [start, setStart] = useState(minStart);
  const [end, setEnd] = useState(maxEnd);

  const [callsProgressBar, setCallsProgressBar] = useState([]);

  const GetRangeDateModules = () => {
    setLoading(true);
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idModule: modules.customerCare,
        idUser: selectedUser,
      }),
    };
    fetch(
      config.apiUrl + "pageObiettivi/GetRangeDateModules.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setStart(data[0].minDate);
        setEnd(data[0].maxDate);
        setMinStart(data[0].minDate);
        setMaxStart(data[0].maxDate);
        setMaxEnd(data[0].maxDate);
      })
      .then(() => setLoading(false));
  };

  const GetUsers = () => {
    setLoading(true);
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idDepartment: selectedDepartment,
      }),
    };
    fetch(config.apiUrl + "pageObiettivi/GetUsers.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setUsers(data))
      .then(() => setLoading(false));
  };

  const GetCallsProgressBar = () => {
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idUser: selectedUser,
      }),
    };
    fetch(
      config.apiUrl + "pageObiettivi/GetCallsProgressBar.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => setCallsProgressBar(data));
  };

  useEffect(() => {
    if (parseInt(user.id_role) === roles.hr) {
      GetUsers();
    }
    /*eslint-disable-next-line*/
  }, []);

  useEffect(() => {
    GetRangeDateModules();
    if (selectedUser) {
      GetCallsProgressBar();
    }
    /*eslint-disable-next-line*/
  }, [selectedUser]);

  return (
    <>
      {parseInt(user.id_role) === roles.hr ? (
        <Card className="p-5">
          {!loading ? (
            <div className="col-12 py-3">
              <label className="col-12">
                <h6>Seleziona dipendente</h6>
              </label>
              <Select
                placeholder={
                  users.length === 0
                    ? "Nessun dipendente disponibile"
                    : "Dipendente..."
                }
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.value)}
                isDisabled={
                  !selectedDepartment || users.length === 0 ? true : false
                }
                options={users}
              />
            </div>
          ) : (
            <div className="col-6 col-lg-6 col-sm-12 py-3">
              <Skeleton />
            </div>
          )}
        </Card>
      ) : null}
      <Card className="col-12 col-lg-12 col-sm-12 mt-4">
        <Card.Header>
          <Card.Title className="pt-4">Riepilogo</Card.Title>
        </Card.Header>
        <Card.Body>
          <ProgressCustomerCare
            loading={loading}
            callsProgressBar={callsProgressBar}
            selectedUser={selectedUser}
          />
        </Card.Body>
      </Card>
      <Card className="p-5 mt-4">
        <div className="row">
          <div className="col-12">
            <label className="col-12">
              <h6>Seleziona un intervallo di date</h6>
            </label>
          </div>
          <div className="col-6 col-lg-6 col-sm-12">
            <label className="col-12">Inizio</label>
            <input
              type="date"
              className="form-control"
              value={moment(start).format("YYYY-MM-DD")}
              min={moment(minStart).format("YYYY-MM-DD")}
              max={moment(maxStart).format("YYYY-MM-DD")}
              disabled={users.length === 0 ? true : false}
              onChange={(e) =>
                setStart(moment(e.target.value).format("YYYY-MM-DD"))
              }
            />
          </div>
          <div className="col-6 col-lg-6 col-sm-12">
            <label className="col-12">Fine</label>
            <input
              type="date"
              className="form-control"
              value={moment(end).format("YYYY-MM-DD")}
              min={moment(start).format("YYYY-MM-DD")}
              max={moment(maxEnd).format("YYYY-MM-DD")}
              disabled={users.length === 0 ? true : false}
              onChange={(e) =>
                setEnd(moment(e.target.value).format("YYYY-MM-DD"))
              }
            />
          </div>
        </div>
      </Card>
      <></>
      <ChartCustomerCare
        idUser={selectedUser}
        startDate={start}
        endDate={end}
      />
      <></>
      <Card className="col-12 col-lg-12 col-sm-12 mt-4">
        <Card.Header>
          <Card.Title className="pt-4">Chiamate</Card.Title>
        </Card.Header>
        <Card.Body>
          <TabellaCustomerCare
            idUser={selectedUser}
            authToken={props.authToken}
          />
        </Card.Body>
      </Card>
    </>
  );
};
