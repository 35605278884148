/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSubheader } from "../../../_metronic/layout";
import config, { modules, roles } from "../../../config/config";
import { Card } from "react-bootstrap";
import Select from "react-select";
import { Skeleton } from "@material-ui/lab";
import { ChartVenditori } from "../../../_metronic/_partials/widgets/components/pageObiettivi/ChartVenditori";
import { TabellaVenditori } from "../../../_metronic/_partials/widgets/components/pageObiettivi/TabellaVenditori";
import { ProgressVenditori } from "../../../_metronic/_partials/widgets/components/pageObiettivi/ProgressVenditori";
import { TestDrive } from "../../../_metronic/_partials/widgets/advance-tables/TestDrive";
import moment from "moment";

export const Venditori = (props) => {
  const { user } = useSelector((state) => state.auth);
  const subheader = useSubheader();
  subheader.setTitle("Obiettivi - Venditori");

  const [loading, setLoading] = useState(false);
  const [module] = useState(modules.venditori);
  const [selectedDepartment] = useState(user.idDepartment);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(user.id);

  /*Intervallo date test drive*/
  const [minStartDate, setMinStartDate] = useState(new Date());
  const [maxStartDate, setMaxStartDate] = useState(new Date());
  const [maxEndDate, setMaxEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(minStartDate);
  const [endDate, setEndDate] = useState(minStartDate);
  const [minEndDate, setMinEndDate] = useState(startDate);

  /*Intervallo date per tutti i moduli*/
  const [minStart, setMinStart] = useState(new Date());
  const [maxStart, setMaxStart] = useState(new Date());
  const [maxEnd, setMaxEnd] = useState(new Date());
  const [start, setStart] = useState(minStartDate);
  const [end, setEnd] = useState(minStart);

  const [contractsProgressBar, setContractsProgressBar] = useState([]);

  const GetRangeDate = () => {
    setLoading(true);
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idModule: module,
        idUser: selectedUser,
      }),
    };
    fetch(config.apiUrl + "pageObiettivi/GetRangeDate.php", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setStartDate(data[0].minDate);
        setEndDate(data[0].maxDate);
        setMinStartDate(data[0].minDate);
        setMaxStartDate(data[0].maxDate);
        setMaxEndDate(data[0].maxDate);
      })
      .then(() => setLoading(false));
  };

  const GetRangeDateModules = () => {
    setLoading(true);
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idModule: module,
        idUser: selectedUser,
      }),
    };
    fetch(
      config.apiUrl + "pageObiettivi/GetRangeDateModules.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setStart(data[0].minDate);
        setEnd(data[0].maxDate);
        setMinStart(data[0].minDate);
        setMaxStart(data[0].maxDate);
        setMaxEnd(data[0].maxDate);
      })
      .then(() => setLoading(false));
  };

  const GetContractsProgressBar = () => {
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idUser: selectedUser,
        startDate: startDate,
        endDate: endDate,
      }),
    };
    fetch(
      config.apiUrl + "pageObiettivi/GetContractsProgressBar.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => setContractsProgressBar(data));
  };

  const GetUsers = () => {
    setLoading(true);
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idDepartment: selectedDepartment.value,
      }),
    };
    fetch(config.apiUrl + "pageObiettivi/GetUsers.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setUsers(data))
      .then(() => setLoading(false));
  };

  useEffect(() => {
    if (selectedDepartment !== false && parseInt(user.id_role) === roles.hr) {
      GetUsers();
    }
    /*eslint-disable-next-line*/
  }, [selectedDepartment]);

  useEffect(() => {
    GetRangeDate();
    GetRangeDateModules();
    /*eslint-disable-next-line*/
  }, [selectedUser]);

  useEffect(() => {
    if (selectedUser) {
      GetContractsProgressBar();
    }
    /*eslint-disable-next-line*/
  }, [selectedUser, startDate, endDate]);

  return (
    <>
      <Card className="p-5">
        {parseInt(user.id_role) === roles.hr ? (
          !loading ? (
            <div className="col-12 py-3">
              <label className="col-12">
                <h6>Seleziona dipendente</h6>
              </label>
              <Select
                placeholder={
                  users.length === 0
                    ? "Nessun dipendente disponibile"
                    : "Dipendente..."
                }
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.value)}
                options={users}
              />
            </div>
          ) : (
            <div className="col-6 col-lg-6 col-sm-12 py-3">
              <Skeleton />
            </div>
          )
        ) : null}
        <>
          <div className="col-12">
            <label className="col-12">
              <h6>Seleziona un intervallo di date</h6>
            </label>
            <div className="row">
              <div className="col-6 col-lg-6 col-sm-12">
                <label className="col-12">Inizio</label>
                <input
                  type="date"
                  className="form-control"
                  value={moment(startDate).format("YYYY-MM-DD")}
                  min={moment(minStartDate).format("YYYY-MM-DD")}
                  max={moment(maxStartDate).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    setStartDate(moment(e.target.value).format("YYYY-MM-DD"));
                    setMinEndDate(moment(e.target.value).format("YYYY-MM-DD"));
                  }}
                />
              </div>
              <div className="col-6 col-lg-6 col-sm-12">
                <label className="col-12">Fine</label>
                <input
                  type="date"
                  className="form-control"
                  value={moment(endDate).format("YYYY-MM-DD")}
                  min={moment(minEndDate).format("YYYY-MM-DD")}
                  max={moment(maxEndDate).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setEndDate(moment(e.target.value).format("YYYY-MM-DD"))
                  }
                />
              </div>
            </div>
          </div>
        </>
      </Card>

      <Card className="col-12 col-lg-12 col-sm-12 mt-4">
        <Card.Header>
          <Card.Title className="pt-4">Riepilogo</Card.Title>
        </Card.Header>
        <Card.Body>
          <ProgressVenditori
            contractsProgressBar={contractsProgressBar}
            loading={loading}
            selectedUser={selectedUser}
            startDate={startDate}
            endDate={endDate}
          />
        </Card.Body>
      </Card>

      <TestDrive idUser={selectedUser} idOrganization={props.idOrganization} />

      <Card className="p-5 mt-4">
        <div className="row">
          <div className="col-12">
            <label className="col-12">
              <h6>Seleziona un intervallo di date</h6>
            </label>
          </div>
          <div className="col-6 col-lg-6 col-sm-12">
            <label className="col-12">Inizio</label>
            <input
              type="date"
              className="form-control"
              value={moment(start).format("YYYY-MM-DD")}
              min={moment(minStart).format("YYYY-MM-DD")}
              max={moment(maxStart).format("YYYY-MM-DD")}
              onChange={(e) => {
                setStart(moment(e.target.value).format("YYYY-MM-DD"));
              }}
            />
          </div>
          <div className="col-6 col-lg-6 col-sm-12">
            <label className="col-12">Fine</label>
            <input
              type="date"
              className="form-control"
              value={moment(end).format("YYYY-MM-DD")}
              min={moment(start).format("YYYY-MM-DD")}
              max={moment(maxEnd).format("YYYY-MM-DD")}
              onChange={(e) =>
                setEnd(moment(e.target.value).format("YYYY-MM-DD"))
              }
            />
          </div>
        </div>
      </Card>

      <></>
      <ChartVenditori
        idUser={selectedUser}
        startDate={start}
        endDate={end}
        idOrganization={props.idOrganization}
      />
      <></>

      <Card className="col-12 col-lg-12 col-sm-12 mt-4">
        <Card.Header>
          <Card.Title className="pt-4">Contratti</Card.Title>
        </Card.Header>
        <Card.Body>
          <TabellaVenditori idUser={selectedUser} idRuolo={props.idRole} />
        </Card.Body>
      </Card>
    </>
  );
};
