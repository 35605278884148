/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import config, { roles } from "../../../../config/config";
import { DateRangePicker } from "react-date-range";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import it from "date-fns/locale/it";
import {
  defaultStaticRanges,
  defaultInputRanges,
} from "react-date-range/dist/defaultRanges";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Card } from "react-bootstrap";
import { Tabs, Tab, Row } from "react-bootstrap";
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  LineSeries,
  Title,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";
import { scaleBand } from "@devexpress/dx-chart-core";
import { ArgumentScale } from "@devexpress/dx-react-chart";
import { line, curveStep } from "d3-shape";
import { ValueScale, Stack, Animation } from "@devexpress/dx-react-chart";
import { ModalAutovalutazione } from "./ModalAutovalutazione";
import {
  PagingState,
  IntegratedPaging,
  SearchState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
  RowDetailState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  Toolbar,
  SearchPanel,
} from "@devexpress/dx-react-grid-bootstrap4";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { Paper } from "@material-ui/core";
/*eslint-disable */

const useStyles = makeStyles({
  root: {
    width: 300,
    marginBottom: "1%",
  },
});

export const PaginaDipendenti = () => {
  const { user } = useSelector((state) => state.auth);

  const [count, setCount] = useState(0);
  const changeTab = () => setCount(count + 1);

  const [show, setShow] = useState(false);
  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);

  const [loadingRadar, setLoadingRadar] = useState(false);
  const [loadingRiepilogo, setLoadingRiepilogo] = useState(false);
  const [dataRadar, setDataRadar] = useState([]);
  const [dataRiepilogo, setDataRiepilogo] = useState([]);
  const [loadingRow, setLoadingRow] = useState(false);
  const [loadingCol, setLoadingCol] = useState(false);
  const [rowUtente, setRowUtente] = useState([]);
  const [columnUtente, setColumnUtente] = useState([]);
  const [searchValue, setSearchState] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizes] = useState([10, 20, 30, 0]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 2))
  );
  const [endDate, setEndDate] = useState(new Date());
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  const handleSelect = (ranges) => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  };

  const TableComponent = ({ ...restProps }) => (
    <>
      <Table.Table {...restProps} className="table-striped" />
    </>
  );

  const options = {
    headers: {
      Authorization:
        "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
    },
    method: "POST",
    body: JSON.stringify({
      start: startDate,
      end: endDate,
      idOrganization: user.id_organization,
      id: user.id,
      idDepartment: user.idDepartment,
      idModule: user.idModule,
      authToken: user.authToken,
      idRole: user.id_role,
    }),
  };

  const GetValutazioniDipendenti = () => {
    setLoadingRow(true);
    fetch(config.apiUrl + "obiettivi/GetValutazioniDipendente.php", options)
      .then((response) => response.json())
      .then((data) => setRowUtente(data))
      .then(() => setLoadingRow(false));
  };

  const GetColumnDipendenti = () => {
    setLoadingCol(true);
    fetch(config.apiUrl + "obiettivi/GetColumnsDipendente.php", options)
      .then((response) => response.json())
      .then((data) => setColumnUtente(data))
      .then(() => setLoadingCol(false));
  };

  const GetRiepilogoValutazioni = () => {
    setLoadingRiepilogo(true);
    fetch(config.apiUrl + "obiettivi/GetRiepilogoValutazioni.php", options)
      .then((response) => response.json())
      .then((data) => setDataRiepilogo(data))
      .then(() => setLoadingRiepilogo(false));
  };

  const GetRadarValutazioni = () => {
    setLoadingRadar(true);
    fetch(config.apiUrl + "obiettivi/GetValutazioni.php", options)
      .then((response) => response.json())
      .then((data) => setDataRadar(data))
      .then(() => setLoadingRadar(false));
  };

  useEffect(() => {
    if (parseInt(user.id_role) === roles.dipendente) {
      GetValutazioniDipendenti();
      GetColumnDipendenti();
      GetRadarValutazioni();
      GetRiepilogoValutazioni();
    }
  }, [count]);

  const Root = (props) => (
    <Legend.Root {...props} className="m-auto flex-row" />
  );

  const Marker = (props) => {
    const { className, color } = props;
    return (
      <svg className={className} fill={color} width="10" height="10">
        <rect x={0} y={0} width={100} height={100} />
      </svg>
    );
  };

  const Line = (props) => (
    <LineSeries.Path
      {...props}
      path={line()
        .x(({ arg }) => arg)
        .y(({ val }) => val)
        .curve(curveStep)}
    />
  );

  const format = () => (tick) => tick;

  const Label = (symbol) => (props) => {
    const { text } = props;
    return <ValueAxis.Label {...props} text={text + symbol} />;
  };

  const modifyValueDomain = (domain) => [domain[0], 100];
  const labelPercentual = Label(" %");

  const staticRangesLabels = {
    Today: "Oggi",
    Yesterday: "Ieri",
    "This Week": "Questa settimana",
    "Last Week": "Settimana scorsa",
    "This Month": "Questo mese",
    "Last Month": "Mese scorso",
  };

  const inputRangesLabels = {
    "days up to today": "giorni da oggi",
    "days starting today": "giorni a partire da oggi",
  };

  function translateRange(dictionary) {
    return (item) =>
      dictionary[item.label]
        ? { ...item, label: dictionary[item.label] }
        : item;
  }

  const itStaticRanges = defaultStaticRanges.map(
    translateRange(staticRangesLabels)
  );
  const itInputRanges = defaultInputRanges.map(
    translateRange(inputRangesLabels)
  );

  const classes = useStyles();

  return (
    <div className="bg-grey">
      <Paper className="col-12">
        <Tabs
          onSelect={changeTab}
          style={{ paddingTop: "15px" }}
          defaultActiveKey="chartRiepilogo"
        >
          <Tab eventKey="chartRiepilogo" title="Grafico andamento">
            <div className="col-12 mb-3"></div>
            <Row>
              <div className="col-12 my-2">
                <Accordion
                  className="col-9"
                  style={{ backgroundColor: "#fbfbfd" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Seleziona intervallo</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="row">
                      <div className="d-flex justify-content-center">
                        <DateRangePicker
                          staticRanges={itStaticRanges}
                          inputRanges={itInputRanges}
                          className="col-12 ml-20 mr-auto"
                          locale={it}
                          ranges={[selectionRange]}
                          onChange={handleSelect}
                        />
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="col-12 mb-3">
                {!loadingRiepilogo ? (
                  <Chart data={dataRiepilogo}>
                    <ValueScale name="value" modifyDomain={modifyValueDomain} />
                    <ArgumentScale factory={scaleBand} />
                    <ArgumentAxis tickFormat={format} />
                    <ValueAxis
                      scaleName="value"
                      labelComponent={labelPercentual}
                    />
                    <BarSeries
                      scaleName="value"
                      name="Valutazione"
                      valueField="valutation"
                      argumentField="field"
                      color="#0f4c5c"
                    />
                    <BarSeries
                      scaleName="value"
                      name="Autovalutazione"
                      valueField="autovalutation"
                      argumentField="field"
                      color="#ff9f1c"
                    />
                    <LineSeries
                      scaleName="value"
                      name="Media valutazione/autovalutazione"
                      valueField="mediaValutazione"
                      argumentField="field"
                      color="#e71d36"
                      seriesComponent={Line}
                    />
                    <Animation />
                    <Legend
                      position="right"
                      rootComponent={Root}
                      markerComponent={Marker}
                    />
                    <Title text="Riepilogo valutazioni" />
                    <Stack />
                  </Chart>
                ) : (
                  <div className="col-12">
                    <Skeleton variant="text" animation="wave" width={1240} />
                    <Skeleton variant="text" animation="wave" width={1240} />
                    <Skeleton
                      variant="rect"
                      animation="wave"
                      width={1240}
                      height={300}
                    />
                  </div>
                )}
              </div>
            </Row>
          </Tab>
          <Tab eventKey="valutationChart" title="Skills">
            <div
              className="col-12 mb-3"
              style={{
                borderBottom: "#eaecf2 solid 1px",
              }}
            >
              {!loadingRadar ? (
                <Chart data={dataRadar}>
                  <ValueScale name="value" modifyDomain={modifyValueDomain} />
                  <ArgumentScale factory={scaleBand} />
                  <ArgumentAxis tickFormat={format} />
                  <ValueAxis
                    scaleName="value"
                    labelComponent={labelPercentual}
                  />
                  <BarSeries
                    scaleName="value"
                    name="Valutazione"
                    valueField="valutation"
                    argumentField="field"
                    color="#0f4c5c"
                  />
                  <BarSeries
                    scaleName="value"
                    name="Autovalutazione"
                    valueField="autovalutation"
                    argumentField="field"
                    color="#ff9f1c"
                  />
                  <LineSeries
                    scaleName="value"
                    name="Media valutazione/autovalutazione"
                    valueField="mediaValutazione"
                    argumentField="field"
                    color="#e71d36"
                    seriesComponent={Line}
                  />
                  <Animation />
                  <Legend
                    position="right"
                    rootComponent={Root}
                    markerComponent={Marker}
                  />
                  <Title text="Skills" />
                  <Stack />
                </Chart>
              ) : (
                <div className="col-12">
                  <Skeleton variant="text" animation="wave" width={1240} />
                  <Skeleton variant="text" animation="wave" width={1240} />
                  <Skeleton
                    variant="rect"
                    animation="wave"
                    width={1240}
                    height={300}
                  />
                </div>
              )}
            </div>
          </Tab>
        </Tabs>
      </Paper>
      <ModalAutovalutazione
        show={show}
        handleClose={handleClose}
        handleOpen={handleOpen}
        count={count}
        setCount={setCount}
      />
      <Card className="col-12 mt-3">
        <Card.Header>
          <Card.Title className="card-title-h3 col-6">
            Tabella valutazioni
          </Card.Title>
          <Button
            onClick={() => handleOpen()}
            className="float-right"
            size="sm"
          >
            <i className="far fa-edit pt-2 pb-1"></i>Autovalutazione
          </Button>
        </Card.Header>
        <Card.Body className="p-5">
          {loadingCol === false || loadingRow === false ? (
            <Grid rows={rowUtente} columns={columnUtente}>
              <RowDetailState defaultExpandedRowIds={[]} />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
              />
              <IntegratedPaging />
              <SearchState value={searchValue} onValueChange={setSearchState} />
              <IntegratedFiltering />
              <SortingState
                defaultSorting={[
                  { columnName: "data_valutazione", direction: "asc" },
                ]}
              />
              <IntegratedSorting />
              <Table tableComponent={TableComponent} />
              <TableHeaderRow showSortingControls />
              <Toolbar />
              <SearchPanel />
              <PagingPanel pageSizes={pageSizes} />
            </Grid>
          ) : (
            <div className={classes.root}>
              <Skeleton variant="text" animation="wave" width={1260} />
              <Skeleton variant="text" animation="wave" width={1260} />
              <Skeleton
                variant="rect"
                animation="wave"
                width={1260}
                height={300}
              />
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};
