/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../_metronic/layout";
import config, { modules } from "../../../config/config";
import { Card } from "react-bootstrap";
import Select from "react-select";
import { Skeleton } from "@material-ui/lab";
import { ChartCustomerCare } from "../../../_metronic/_partials/widgets/components/pageObiettivi/ChartCustomerCare";
import { ChartVenditori } from "../../../_metronic/_partials/widgets/components/pageObiettivi/ChartVenditori";
import { ChartOfficina } from "../../../_metronic/_partials/widgets/components/pageObiettivi/ChartOfficina";
import { TabellaVenditori } from "../../../_metronic/_partials/widgets/components/pageObiettivi/TabellaVenditori";
import { TabellaOfficina } from "../../../_metronic/_partials/widgets/components/pageObiettivi/TabellaOfficina";
import moment from "moment";
import { TabellaCustomerCare } from "../../../_metronic/_partials/widgets/components/pageObiettivi/TabellaCustomerCare";
import { ProgressCustomerCare } from "../../../_metronic/_partials/widgets/components/pageObiettivi/ProgressCustomerCare";
import { ProgressOfficina } from "../../../_metronic/_partials/widgets/components/pageObiettivi/ProgressOfficina";
import { ProgressVenditori } from "../../../_metronic/_partials/widgets/components/pageObiettivi/ProgressVenditori";
import { TestDrive } from "../../../_metronic/_partials/widgets/advance-tables/TestDrive";

export const Admin = (props) => {
  const subheader = useSubheader();
  subheader.setTitle("Obiettivi - Admin");

  const [loading, setLoading] = useState(false);
  const [module, setModule] = useState(false);
  const [department, setDepartment] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(false);
  const [userSelected, setUserSelected] = useState(false);

  /*Intervallo date test drive*/
  const [minStartDate, setMinStartDate] = useState(new Date());
  const [maxStartDate, setMaxStartDate] = useState(new Date());
  const [maxEndDate, setMaxEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(minStartDate);
  const [endDate, setEndDate] = useState(maxEndDate);

  /*Intervallo date per tutti i moduli*/
  const [minStart, setMinStart] = useState(new Date());
  const [maxStart, setMaxStart] = useState(new Date());
  const [maxEnd, setMaxEnd] = useState(new Date());
  const [start, setStart] = useState(minStart);
  const [end, setEnd] = useState(minStart);

  const [callsProgressBar, setCallsProgressBar] = useState([]);
  const [contractsProgressBar, setContractsProgressBar] = useState([]);
  const [worksProgressBar, setWorksProgressBar] = useState([]);

  const GetRangeDate = () => {
    setLoading(true);
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idModule: module,
        idUser: selectedUser,
      }),
    };
    fetch(config.apiUrl + "pageObiettivi/GetRangeDate.php", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setStartDate(data[0].minDate);
        setEndDate(data[0].maxDate);
        setMinStartDate(data[0].minDate);
        setMaxStartDate(data[0].maxDate);
        setMaxEndDate(data[0].maxDate);
      })
      .then(() => setLoading(false));
  };

  const GetRangeDateModules = () => {
    setLoading(true);
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idModule: module,
        idUser: selectedUser,
      }),
    };
    fetch(
      config.apiUrl + "pageObiettivi/GetRangeDateModules.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setStart(data[0].minDate);
        setEnd(data[0].maxDate);
        setMinStart(data[0].minDate);
        setMaxStart(data[0].maxDate);
        setMaxEnd(data[0].maxDate);
      })
      .then(() => setLoading(false));
  };

  const GetDepartments = () => {
    setLoading(true);
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idModule: module,
        idOrganization: props.idOrganization,
      }),
    };
    fetch(config.apiUrl + "pageObiettivi/GetDepartments.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setDepartment(data))
      .then(() => setLoading(false));
  };

  const GetUsers = () => {
    setLoading(true);
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idDepartment: selectedDepartment.value,
      }),
    };
    fetch(config.apiUrl + "pageObiettivi/GetUsers.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setUsers(data))
      .then(() => setLoading(false));
  };

  const GetCallsProgressBar = () => {
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idUser: selectedUser,
      }),
    };
    fetch(
      config.apiUrl + "pageObiettivi/GetCallsProgressBar.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => setCallsProgressBar(data));
  };

  const GetWorksProgressBar = () => {
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idUser: selectedUser,
      }),
    };
    fetch(
      config.apiUrl + "pageObiettivi/GetWorksProgressBar.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => setWorksProgressBar(data));
  };

  const GetContractsProgressBar = () => {
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idUser: selectedUser,
        startDate: startDate,
        endDate: endDate,
      }),
    };
    fetch(
      config.apiUrl + "pageObiettivi/GetContractsProgressBar.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => setContractsProgressBar(data));
  };

  useEffect(() => {
    if (module !== false) {
      GetDepartments();
    }
    /*eslint-disable-next-line*/
  }, [module]);

  useEffect(() => {
    if (selectedDepartment !== false) {
      GetUsers();
    }
    /*eslint-disable-next-line*/
  }, [selectedDepartment]);

  useEffect(() => {
    GetRangeDate();
    GetRangeDateModules();
    if (selectedUser) {
      if (parseInt(module) === modules.customerCare) {
        GetCallsProgressBar();
      } else if (parseInt(module) === modules.officina) {
        GetWorksProgressBar();
      } else if (parseInt(module) === modules.venditori) {
        GetContractsProgressBar();
      }
    }
    /*eslint-disable-next-line*/
  }, [selectedUser, startDate, endDate, start, end]);

  return (
    <>
      <Card className="p-5">
        <div className="row">
          <div className="col-12">
            <label className="col-12">
              <h6>Seleziona un modulo</h6>
            </label>
            <select
              className="form-control col-12"
              value={module}
              onChange={(e) => {
                setModule(e.target.value);
                setSelectedDepartment(false);
                setSelectedUser(false);
                setUserSelected(false);
              }}
            >
              <option value={false}>Seleziona modulo</option>
              <option value={modules.customerCare}>Customer care</option>
              <option value={modules.officina}>Officina</option>
              <option value={modules.venditori}>Venditori</option>
            </select>
          </div>
          {!loading ? (
            <div className="col-6 col-lg-6 col-sm-12 py-3">
              <label className="col-12">
                <h6>Seleziona dipartimento</h6>
              </label>
              <Select
                placeholder={
                  department.length === 0
                    ? "Nessun dipartimento disponibile"
                    : "Dipartimento..."
                }
                value={selectedDepartment}
                onChange={(value) => {
                  setSelectedDepartment(value);
                  setSelectedUser(false);
                  setUserSelected(false);
                }}
                isDisabled={!module || department.length === 0 ? true : false}
                options={department}
              />
            </div>
          ) : (
            <div className="col-6 col-lg-6 col-sm-12 py-3">
              <Skeleton />
            </div>
          )}
          {!loading ? (
            <div className="col-6 col-lg-6 col-sm-12 py-3">
              <label className="col-12">
                <h6>Seleziona dipendente</h6>
              </label>
              <Select
                placeholder={
                  users.length === 0
                    ? "Nessun dipendente disponibile"
                    : "Dipendente..."
                }
                value={userSelected}
                onChange={(e) => {
                  setSelectedUser(e.value);
                  setUserSelected(e);
                }}
                isDisabled={
                  !selectedDepartment || users.length === 0 ? true : false
                }
                options={users}
              />
            </div>
          ) : (
            <div className="col-6 col-lg-6 col-sm-12 py-3">
              <Skeleton />
            </div>
          )}
          {parseInt(module) === modules.venditori ? (
            <>
              <div className="col-12">
                <label className="col-12">
                  <h6>Seleziona un intervallo di date</h6>
                </label>
                <div className="row">
                  <div className="col-6 col-lg-6 col-sm-12">
                    <label className="col-12">Inizio</label>
                    <input
                      type="date"
                      className="form-control"
                      value={moment(startDate).format("YYYY-MM-DD")}
                      min={moment(minStartDate).format("YYYY-MM-DD")}
                      max={moment(maxStartDate).format("YYYY-MM-DD")}
                      disabled={users.length === 0 ? true : false}
                      onChange={(e) => {
                        setStartDate(
                          moment(e.target.value).format("YYYY-MM-DD")
                        );
                      }}
                    />
                  </div>
                  <div className="col-6 col-lg-6 col-sm-12">
                    <label className="col-12">Fine</label>
                    <input
                      type="date"
                      className="form-control"
                      value={moment(endDate).format("YYYY-MM-DD")}
                      min={moment(startDate).format("YYYY-MM-DD")}
                      max={moment(maxEndDate).format("YYYY-MM-DD")}
                      disabled={users.length === 0 ? true : false}
                      onChange={(e) =>
                        setEndDate(moment(e.target.value).format("YYYY-MM-DD"))
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </Card>
      {module ? (
        <Card className="col-12 col-lg-12 col-sm-12 mt-4">
          <Card.Header>
            <Card.Title className="pt-4">Riepilogo</Card.Title>
          </Card.Header>
          <Card.Body>
            {parseInt(module) === modules.venditori ? (
              <ProgressVenditori
                loading={loading}
                contractsProgressBar={contractsProgressBar}
                selectedUser={selectedUser}
                startDate={startDate}
                endDate={endDate}
              />
            ) : null}
            {parseInt(module) === modules.officina ? (
              <ProgressOfficina
                loading={loading}
                worksProgressBar={worksProgressBar}
                selectedUser={selectedUser}
              />
            ) : null}
            {parseInt(module) === modules.customerCare ? (
              <ProgressCustomerCare
                loading={loading}
                callsProgressBar={callsProgressBar}
                selectedUser={selectedUser}
              />
            ) : null}
          </Card.Body>
        </Card>
      ) : null}

      {parseInt(module) === modules.venditori ? (
        <TestDrive
          idUser={selectedUser}
          idOrganization={props.idOrganization}
        />
      ) : null}

      {parseInt(module) ? (
        <Card className="p-5 mt-4">
          <div className="row">
            <div className="col-12">
              <label className="col-12">
                <h6>Seleziona un intervallo di date</h6>
              </label>
            </div>
            <div className="col-6 col-lg-6 col-sm-12">
              <label className="col-12">Inizio</label>
              <input
                type="date"
                className="form-control"
                value={moment(start).format("YYYY-MM-DD")}
                min={moment(minStart).format("YYYY-MM-DD")}
                max={moment(maxStart).format("YYYY-MM-DD")}
                disabled={users.length === 0 ? true : false}
                onChange={(e) => {
                  setStart(moment(e.target.value).format("YYYY-MM-DD"));
                }}
              />
            </div>
            <div className="col-6 col-lg-6 col-sm-12">
              <label className="col-12">Fine</label>
              <input
                type="date"
                className="form-control"
                value={moment(end).format("YYYY-MM-DD")}
                min={moment(start).format("YYYY-MM-DD")}
                max={moment(maxEnd).format("YYYY-MM-DD")}
                disabled={users.length === 0 ? true : false}
                onChange={(e) =>
                  setEnd(moment(e.target.value).format("YYYY-MM-DD"))
                }
              />
            </div>
          </div>
        </Card>
      ) : null}
      {parseInt(module) === modules.customerCare ? (
        <ChartCustomerCare
          idUser={selectedUser}
          startDate={start}
          endDate={end}
        />
      ) : null}
      {parseInt(module) === modules.officina ? (
        <ChartOfficina idUser={selectedUser} startDate={start} endDate={end} />
      ) : null}
      {parseInt(module) === modules.venditori ? (
        <ChartVenditori
          idUser={selectedUser}
          startDate={start}
          endDate={end}
          idOrganization={props.idOrganization}
        />
      ) : null}

      {parseInt(module) === modules.venditori ? (
        <>
          <Card className="col-12 col-lg-12 col-sm-12 mt-4">
            <Card.Header>
              <Card.Title className="pt-4">Contratti</Card.Title>
            </Card.Header>
            <Card.Body>
              <TabellaVenditori idUser={selectedUser} idRuolo={props.idRole} />
            </Card.Body>
          </Card>
        </>
      ) : null}
      {parseInt(module) === modules.officina ? (
        <>
          <Card className="col-12 col-lg-12 col-sm-12 mt-4">
            <Card.Header>
              <Card.Title className="pt-4">Lavori</Card.Title>
            </Card.Header>
            <Card.Body>
              <TabellaOfficina
                idUser={selectedUser}
                authToken={props.authToken}
              />
            </Card.Body>
          </Card>
        </>
      ) : null}
      {parseInt(module) === modules.customerCare ? (
        <>
          <Card className="col-12 col-lg-12 col-sm-12 mt-4">
            <Card.Header>
              <Card.Title className="pt-4">Chiamate</Card.Title>
            </Card.Header>
            <Card.Body>
              <TabellaCustomerCare
                idUser={selectedUser}
                authToken={props.authToken}
              />
            </Card.Body>
          </Card>
        </>
      ) : null}
    </>
  );
};
