/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { Card, Form, Button } from "react-bootstrap";
import Switch from "@material-ui/core/Switch";
import { useSelector } from "react-redux";
import config from "../../config/config";
import { Skeleton } from "@material-ui/lab";

export const GestisciRuoli = () => {
  const { user } = useSelector((state) => state.auth);

  const [roleId, setRoleId] = useState(0);

  const [roles, setRoles] = useState([]);
  const [loadingRoles, setLoadingRoles] = useState(false);

  const [loadingSwitches, setLoadingSwitches] = useState(false);
  const [dashboard, setDashboard] = useState(true);
  const [pannelloAdmin, setPannelloAdmin] = useState(false);
  const [gestDipartimenti, setGestDipartimenti] = useState(false);
  const [gestRuoli, setGestRuoli] = useState(false);
  const [presenze, setPresenze] = useState(false);
  const [dipendenti, setDipendenti] = useState(false);
  const [impostazioni, setImpostazioni] = useState(false);

  const [done, setDone] = useState(false);

  const [count, setCount] = useState(0);

  const GetRoles = () => {
    setLoadingRoles(true);
    const options = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idOrganization:
          parseInt(user.id_organization) === 0 ? user.id : user.id_organization,
      }),
    };
    fetch(config.apiUrl + "users/GetAllRolesSettings.php", options)
      .then((response) => response.json())
      .then((data) => setRoles(data))
      .then(() => setLoadingRoles(false));
  };

  const GetOptionsRoles = () => {
    setLoadingSwitches(true);
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idRole: roleId,
        idOrganization:
          parseInt(user.id_organization) === 0 ? user.id : user.id_organization,
      }),
    };
    fetch(config.apiUrl + "users/GetOptionsRoles.php", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setDashboard(data.dashboard);
        setPannelloAdmin(data.pannelloAdmin);
        setGestDipartimenti(data.gestDipartimenti);
        setGestRuoli(data.gestRuoli);
        setPresenze(data.presenze);
        setDipendenti(data.dipendenti);
        setImpostazioni(data.impostazioni);
      })
      .then(() => setLoadingSwitches(false));
  };

  const SaveSettings = () => {
    const settings = {
      dashboard: dashboard,
      pannelloAdmin: pannelloAdmin,
      gestDipartimenti: gestDipartimenti,
      gestRuoli: gestRuoli,
      presenze: presenze,
      dipendenti: dipendenti,
      impostazioni: impostazioni,
    };
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idRole: roleId,
        idOrganization:
          parseInt(user.id_organization) === 0 ? user.id : user.id_organization,
        settings,
      }),
    };
    fetch(config.apiUrl + "users/SaveSettings.php", requestOptions).then(() => {
      setRoleId(roleId);
      setCount(count + 1);
      setDone(true);
    });
  };

  useEffect(() => {
    GetRoles();
    /*eslint-disable-next-line*/
  }, [count]);

  useEffect(() => {
    if (parseInt(roleId) !== 0) {
      GetOptionsRoles();
    }
    /*eslint-disable-next-line*/
  }, [roleId]);

  return (
    <Card>
      <Card.Body>
        <div className="col-12">
          {!loadingRoles ? (
            <select
              onChange={(e) => {
                setRoleId(e.target.value);
                setDone(false);
              }}
              className="form-control"
              value={roleId}
            >
              <option value={0}>Seleziona ruolo</option>
              {roles.map((e, key) => (
                <option key={key} value={e.id}>
                  {e.role}
                </option>
              ))}
            </select>
          ) : (
            <Skeleton />
          )}
        </div>
        {parseInt(roleId) !== 0 ? (
          <Form>
            <Form.Row className="col-12 m-8">
              <Form.Group className="col-4">
                <Form.Label className="col-12">Dashboard</Form.Label>
                {!loadingSwitches ? (
                  <Switch
                    color="primary"
                    name="dashboard"
                    checked={dashboard}
                    onChange={(e) => {
                      setDashboard(e.target.checked);
                      setDone(false);
                    }}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                ) : (
                  <Skeleton />
                )}
              </Form.Group>
              <Form.Group className="col-4">
                <Form.Label className="col-12">Pannello admin</Form.Label>
                {!loadingSwitches ? (
                  <Switch
                    color="primary"
                    name="pannelloAdmin"
                    checked={pannelloAdmin}
                    onChange={(e) => {
                      setPannelloAdmin(e.target.checked);
                      setDone(false);
                    }}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                ) : (
                  <Skeleton />
                )}
              </Form.Group>
              <Form.Group className="col-4">
                <Form.Label className="col-12">
                  Gestione dipartimenti
                </Form.Label>
                {!loadingSwitches ? (
                  <Switch
                    color="primary"
                    name="gestDipartimenti"
                    checked={gestDipartimenti}
                    onChange={(e) => {
                      setGestDipartimenti(e.target.checked);
                      setDone(false);
                    }}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                ) : (
                  <Skeleton />
                )}
              </Form.Group>
              <Form.Group className="col-4">
                <Form.Label className="col-12">Gestione ruoli</Form.Label>
                {!loadingSwitches ? (
                  <Switch
                    color="primary"
                    name="gestRuoli"
                    checked={gestRuoli}
                    onChange={(e) => {
                      setGestRuoli(e.target.checked);
                      setDone(false);
                    }}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                ) : (
                  <Skeleton />
                )}
              </Form.Group>
              <Form.Group className="col-4">
                <Form.Label className="col-12">Presenze</Form.Label>
                {!loadingSwitches ? (
                  <Switch
                    color="primary"
                    name="presenze"
                    checked={presenze}
                    onChange={(e) => {
                      setPresenze(e.target.checked);
                      setDone(false);
                    }}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                ) : (
                  <Skeleton />
                )}
              </Form.Group>
              <Form.Group className="col-4">
                <Form.Label className="col-12">Dipendenti</Form.Label>
                {!loadingSwitches ? (
                  <Switch
                    color="primary"
                    name="dipendenti"
                    checked={dipendenti}
                    onChange={(e) => {
                      setDipendenti(e.target.checked);
                      setDone(false);
                    }}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                ) : (
                  <Skeleton />
                )}
              </Form.Group>
              <Form.Group className="col-4">
                <Form.Label className="col-12">Impostazioni</Form.Label>
                {!loadingSwitches ? (
                  <Switch
                    color="primary"
                    name="impostazioni"
                    checked={impostazioni}
                    onChange={(e) => {
                      setImpostazioni(e.target.checked);
                      setDone(false);
                    }}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                ) : (
                  <Skeleton />
                )}
              </Form.Group>
            </Form.Row>
            <div className="col-12 text-center">
              <div className="col-12 float-right pb-4">
                <Button onClick={SaveSettings} className="float-right">
                  Salva cambiamenti
                </Button>
              </div>
              {done ? (
                <span className="col-12 mt-2 text-center">
                  <p style={{ color: "green" }} className="float-right">
                    Ruolo modificato con successo!
                  </p>
                </span>
              ) : null}
            </div>
          </Form>
        ) : null}
      </Card.Body>
    </Card>
  );
};
