import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import config, { roles } from "../../../../config/config";

function formatDate(date) {
  var d = new Date(date),
    month = d.getMonth(),
    day = "" + d.getDate(),
    year = d.getFullYear();
  var months = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];
  if (day.length < 2) day = "0" + day;
  month = months[month];
  return day + " " + month + " " + year;
}

function formatTime(date) {
  var d = new Date(date),
    hour = d.getHours(),
    minutes = d.getMinutes();
  if (minutes < 10) minutes = "0" + minutes;
  return hour + ":" + minutes;
}

export default function ModalEvent(props) {
  const { user } = useSelector((state) => state.auth);

  var disabled;

  if (
    (parseInt(user.id_role) === roles.admin ||
      parseInt(user.id_role) === roles.hr) &&
    props.evento.department === "Tutto il dipartimento"
  ) {
    disabled = false;
  } else if (
    parseInt(user.id_role) === roles.admin &&
    props.evento.department === "Aziendale"
  ) {
    disabled = false;
  } else if (props.evento.department === undefined) {
    disabled = false;
  } else {
    disabled = true;
  }
  
  const requestOptions = {
    headers: {
      Authorization:
        "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
    },
    method: "POST",
    body: JSON.stringify({
      idEvento: props.evento.id,
    }),
  };

  const DeleteEvento = () => {
    fetch(
      config.apiUrl + "calendar/DeleteEvento.php",
      requestOptions
    ).then(() => props.setCount(props.count + 1));
    props.close();
  };

  return (
    <Modal show={props.showEvento} onHide={props.close} backdrop="static">
      <Modal.Header style={{ backgroundColor: "#caf0f8" }} closeButton>
        <Modal.Title>
          <b>Evento:</b> {props.evento.title}
        </Modal.Title>
      </Modal.Header>
      {props.evento.allday === false ? (
        <Modal.Body>
          <b>Descrizione: </b>
          <br />
          {props.evento.description}

          {props.evento.department !== undefined ? (
            <>
              <br />
              <b>Dipartimento: </b>
              <br />
              {props.evento.department}
            </>
          ) : null}
          <br />
          <b>Data evento: </b>
          <br />
          {formatDate(props.evento.start)}
          <br />
          <b>Ora inizio evento: </b>
          <br />
          {formatTime(props.evento.start)}
          <br />
          <b>Ora fine evento: </b>
          <br />
          {formatTime(props.evento.end)}
        </Modal.Body>
      ) : (
        <Modal.Body>
          <b>Descrizione: </b>
          <br />
          {props.evento.description}
          <br />
          <b>Data evento: </b>
          <br />
          {formatDate(props.evento.start)}
          <br />
          <b>L'evento dura tutto il giorno </b>
          <br />
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button variant="secondary" onClick={props.close}>
          Chiudi
        </Button>
        {props.evento.title === "Presenza" ||
        props.evento.title === "Ferie" ||
        props.evento.title === "Permesso" ||
        props.evento.title === "Assenza" ||
        props.evento.title === "Straordinario" ? null : (
          <Button variant="danger" disabled={disabled} onClick={DeleteEvento}>
            Elimina
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
