/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { Button } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightToBracket,
  faArrowRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";

export function DropdownCheckIn({ handleClick, color, check, show, setShow }) {
  return (
    <Dropdown show={show} drop="down" alignRight>
      <Dropdown.Toggle
        onClick={() => setShow(true)}
        as={DropdownTopbarItemToggler}
        id="dropdown-check-in"
      >
        {!isMobile ? (
          <div
            className={`btn btn-${color} btn-icon btn-group w-lg-auto w-xl-auto d-flex btn-lg px-2 float-right`}
          >
            <span className="text-white font-size-base d-none d-md-inline px-2">
              {check}
            </span>
          </div>
        ) : (
          <div
            className={`btn btn-${color} btn-icon btn-group w-lg-auto w-xl-auto d-flex btn-lg px-2 float-right`}
          >
            <span className="text-white font-size-base d-none d-inline p-2">
              <FontAwesomeIcon
                icon={
                  check === "Check-In"
                    ? faArrowRightToBracket
                    : faArrowRightFromBracket
                }
              />
            </span>
          </div>
        )}
      </Dropdown.Toggle>
      <div className="navi navi-spacer-x-0 ml-5">
        <Dropdown.Menu className="p-0 mt-7 ml-15 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          <div className="row">
            <div className="col-12 pl-10 pt-7">
              Sei sicuro di voler effettuare il{" "}
              {check === "Check-In" ? "check-in" : "check-out"}
            </div>
            <div className="p-5 col-12">
              <hr />
              <Button
                onClick={() => {
                  handleClick();
                  setShow(false);
                }}
                variant="light-primary"
                className="float-right mr-5"
              >
                Conferma
              </Button>
            </div>
          </div>
        </Dropdown.Menu>
      </div>
    </Dropdown>
  );
}
