/* eslint-disable no-restricted-imports */
import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import Calendar from "react-select-date";
import { makeStyles } from "@material-ui/core/styles";

export const GestisciSmartworking = () => {
  const [selectedDate, setSelectedDate] = useState([]);
  const [errorDate, setErrorDate] = useState(false);
  const [message] = useState("Selezionare almeno una data");

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

  const classes = useStyles();

  return (
    <Card>
      <Card.Header>
        <h6 className={`${classes.heading}`}>GESTIONE SMARTWORKING</h6>
      </Card.Header>
      <Card.Body>
        <div className="row">
          <h5 className="font-weight-bold col-12">Dipendente</h5>
          <div className="col-6 mt-4">
            <select className="form-control">
              <option>Seleziona dipartimento</option>
            </select>
          </div>
          <div className="col-6">
            <select className="form-control">
              <option>Seleziona dipendente</option>
            </select>
          </div>
          <h5 className="font-weight-bold my-9 col-12">Date smartworking</h5>
          <div className="col-12 d-flex justify-content-center">
            <Calendar
              onSelect={(date) => setSelectedDate(date)}
              selectDateType="multiple"
            />
          </div>
          {errorDate ? (
            <div className="col-12">
              <p style={{ color: "red" }}>{message}</p>
            </div>
          ) : null}
        </div>
        <Button className="float-right ml mt-3">Conferma</Button>
        <Button variant="warning" className="float-right mr-3 mt-3">
          Reset
        </Button>
      </Card.Body>
    </Card>
  );
};
