import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import moment from "moment";

var days = [
  "Domenica",
  "Lunedì",
  "Martedì",
  "Mercoledì",
  "Giovedì",
  "Venerdì",
  "Sabato",
];
var month = [
  "Gen",
  "Feb",
  "Mar",
  "Apr",
  "Mag",
  "Giu",
  "Lug",
  "Ago",
  "Set",
  "Ott",
  "Nov",
  "Dic",
];

export function CurrentDate() {
  const [dateState, setDateState] = useState(new Date());
  useEffect(() => {
    setInterval(() => setDateState(new Date()), 30000);
  }, []);

  function formatClock(date) {
    var currentClock =
      date.getHours() +
      ":" +
      (date.getMinutes() < 10 ? "0" : "") +
      date.getMinutes() +
      " ";
    return currentClock;
  }
  function formatDate(date) {
    var currentDate =
      days[date.getDay()] +
      " " +
      date.getDate() +
      " " +
      month[date.getMonth()] +
      " " +
      date.getFullYear();
    return currentDate;
  }
  return (
    <>
      {!isMobile ? (
        <Button href="/presenze" variant="outline-secondary" size="lg">
          <span>{formatClock(dateState)}</span>
          <span>{formatDate(dateState)}</span>
        </Button>
      ) : (
        <Button href="/presenze" variant="outline-secondary" size="lg">
          <span>{formatClock(dateState)}</span>
        </Button>
      )}
    </>
  );
}
