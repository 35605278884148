import React, { useState, useEffect } from "react";
/*eslint-disable-next-line*/
import { shallowEqual, useSelector } from "react-redux";
//import { EditNewCampione } from "./components/EditNewCampione";
import { Chip } from "@material-ui/core";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { Skeleton } from "@material-ui/lab";
import * as PropTypes from "prop-types";
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
  DataTypeProvider,
  RowDetailState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
  TableRowDetail,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import SVG from "react-inlinesvg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faCamera,
  faCalendarXmark,
  faCalendarDays,
  faPen,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
/*eslint-disable-next-line*/
import {
  Card,
  Tooltip,
  OverlayTrigger,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import moment from "moment-timezone";

import { useFormik } from "formik";

/* function groupRichieste(list) {
  const map = new Map();
  list.forEach((item) => {
    const collection = map.get(item.idRichiesta);
    const {
      idRichiesta,
      idAgente,
      status,
      lastUpdate,
      agente,
      address,
      quantity,
      idCampione,
      campione,
      division,
      image,
      lot,
    } = item;
    if (!collection) {
      map.set(idRichiesta, {
        idRichiesta,
        idAgente,
        status,
        lastUpdate,
        agente,
        address,
        cart: [{ idCampione, name: campione, quantity, division, image, lot }],
      });
    } else {
      collection.cart.push({
        idCampione,
        name: campione,
        quantity,
        division,
        image,
        lot,
      });
    }
  });
  for (let [idRichiesta, richiesta] of map) {
    map.set(idRichiesta, {
      ...richiesta,
      quantity: richiesta.cart.reduce(
        (q, item) => q + parseInt(item.quantity),
        0
      ),
    });
  }
  return map;
} */

// Useful sub-components
const TableComponent = ({ ...restProps }) => (
  <Table.Table
    {...restProps}
    className="table table-hover table-head-custom table-vertical-center overflow-hidden"
  />
);

const FilterCell = (props) => {
  return <TableFilterRow.Cell {...props} />;
};
FilterCell.propTypes = {
  column: PropTypes.shape({ name: PropTypes.string }).isRequired,
};

const DateFormattedColumn = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) => moment(value).format("DD MMMM YYYY")}
    {...props}
  />
);

const LastUpdatedColumn = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) =>
      moment(value).format("DD MMMM YYYY, HH:mm")
    }
    {...props}
  />
);

const StatoColumn = ({ value }) => {
  value = parseInt(value);
  /*eslint-disable-next-line*/
  if (value == statusRichiesta.inAttesa)
    return (
      // in attesa
      <div className="pl-3">
        <Chip
          color="info"
          variant="outlined"
          label="In attesa"
          style={{ borderColor: "var(--info)" }}
        />
      </div>
    );
  /*eslint-disable-next-line*/
  if (value == statusRichiesta.approvato)
    return (
      // accettato
      <div className="pl-3">
        <Chip
          variant="outlined"
          label="Approvata"
          style={{ borderColor: "var(--primary)", color: "var(--primary)" }}
        />
      </div>
    );
  /*eslint-disable-next-line*/
  if (value == statusRichiesta.annullato)
    return (
      // cancellato
      <div className="pl-3">
        <Chip
          color="danger"
          variant="outlined"
          label="Rifiutato"
          style={{ borderColor: "var(--danger)", color: "var(--danger)" }}
        />
      </div>
    );
};

export const StatoFormattedColumn = (props) => (
  <DataTypeProvider formatterComponent={StatoColumn} {...props} />
);

// Messages
const tableMessages = {
  noData: "Nessun documento",
};
const filterRowMessages = {
  filterPlaceholder: "Filtra...",
};
const pagingPanelMessages = {
  showAll: "Tutti",
  rowsPerPage: "Righe per pagina",
  info: "Da {from} a {to} ({count} appuntamenti)",
};

// Paging and sorting
const pageSizes = [10, 20, 30, 0];
const initialPageSize = 10;
const initialSorting = [{ columnName: "id", direction: "desc" }];

export const Documenti = ({ setPage, setVideoconference, count, setCount }) => {
  /* { name: "file", title: "File" },
    { name: "sharedBy", title: "Condiviso da" },
    { name: "dateUploaded", title: "Data caricamento" },
    { name: "size", title: "Dimensioni" },
    { name: "type", title: "Tipo di documento" },
    { name: "actions", title: "Azioni" }, */
  // table data
  const [rows, setRows] = useState([
    {
      file: "CV",
      sharedBy: "Mario Rossi",
      dateUploaded: "20 gennaio 2022",
      size: "100 MB",
      type: ".PDF",
    },
    {
      file: "Video tutorial",
      sharedBy: "Luca Gentile",
      dateUploaded: "7 marzo 2022",
      size: "100 MB",
      type: ".ZIP",
    },
    {
      file: "Guida",
      sharedBy: "Andrea D'Ausilio",
      dateUploaded: "20 febbraio 2022",
      size: "50 MB",
      type: ".PDF",
    },
    {
      file: "File di testo",
      sharedBy: "Fabiana Licursi",
      dateUploaded: "12 marzo 2022",
      size: "120 MB",
      type: ".PDF",
    },
    {
      file: "Documento di progetto",
      sharedBy: "Vittorio Di Gennaro",
      dateUploaded: "10 settembre 2021",
      size: "100 MB",
      type: ".ZIP",
    },
    {
      file: "CV",
      sharedBy: "Nicola Eliseo",
      dateUploaded: "15 agosto 2021",
      size: "50 MB",
      type: ".XLS",
    },
    {
      file: "CV",
      sharedBy: "Nicola Melito",
      dateUploaded: "18 ottobre 2021",
      size: "100 MB",
      type: ".CSV",
    },
    {
      file: "Informazioni generali",
      sharedBy: "Alessandro Tortora",
      dateUploaded: "3 novembre 2021",
      size: "100 MB",
      type: ".PDF",
    },
    {
      file: "CV",
      sharedBy: "Maria Rossi",
      dateUploaded: "20 gennaio 2022",
      size: "100 MB",
      type: ".PDF",
    },
    {
      file: "CV",
      sharedBy: "Maria Di Leo",
      dateUploaded: "20 gennaio 2022",
      size: "100 MB",
      type: ".PDF",
    },
  ]);
  const [loading, setLoading] = useState(false);

  // User redux
  const user = useSelector((state) => state.auth.user, shallowEqual);

  const makeActionBtn = ({
    name,
    disabled,
    tooltip,
    callback,
    variant,
    icon,
    action,
    value,
    setShow,
    type,
    setEdit,
  }) => {
    const btn = (
      <Button
        variant={variant}
        className={`mx-3 my-2`}
        onClick={() => {
          if (type === "accept" || type === "decline") {
            /* AcceptDeclineAppointment({
              accessToken: user.authToken,
              action: action,
              idAppointment: value,
            }).then(() => setCount(count + 1)); */
          } else {
            setShow(true);
            setEdit(type);
          }
        }}
        disabled={disabled}
        title={name}
      >
        {typeof icon == "string" ? (
          <span className={`svg-icon svg-icon-md svg-icon-white`}>
            {" "}
            <SVG src={toAbsoluteUrl(icon)} />
          </span>
        ) : (
          <FontAwesomeIcon
            icon={icon}
            className={`svg-icon-white`}
            style={{ marginRight: "0.5rem" }}
          />
        )}
        {/* <span className={`text-${variant}`}></span> */}
        {name}
      </Button>
    );
    if (!tooltip) return btn;
    return (
      <OverlayTrigger overlay={<Tooltip>{tooltip}</Tooltip>}>
        {btn}
      </OverlayTrigger>
    );
  };

  const RowDetail = ({ row }) => {
    const [show, setShow] = useState(false);
    const [edit, setEdit] = useState(false);
    const [result, setResult] = useState([]);

    return (
      <>
        <EditAppointment
          show={show}
          setShow={setShow}
          editing={edit}
          idAppointment={row.idAppointment}
          startTime={row.startTime}
          endTime={row.endTime}
          dateAppointment={row.dateAppointment}
          result={result}
          setResult={setResult}
          count={count}
          setCount={setCount}
        />
        <div className="card">
          <>
            <div className="d-flex align-items-center justify-content-xl-between p-8">
              <div className="d-flex flex-column mr-2 order-2 order-xl-1 ml-2">
                {/*eslint-disable-next-line*/}
                <span className="font-weight-bold text-dark-75 font-size-lg text-hover-primary">
                  Servizio:
                </span>
                <div className="d-flex align-items-center mt-2">
                  <span className="font-weight-bold mr-1 text-dark-75 font-size-3">
                    {row.medicalservice}
                  </span>
                </div>
              </div>
              {typeof row.image !== "undefined" &&
              row.image !== "" &&
              row.image !== "0" ? ( // TODO: REMOVE THE 0 ?
                <div className="symbol symbol-70 flex-shrink-0 order-1 order-xl-2">
                  <img
                    src={
                      row.image.startsWith("/")
                        ? process.env.REACT_APP_API_URL + row.image
                        : row.image
                    }
                    alt=""
                  />
                </div>
              ) : null}
            </div>
            <div className="separator separator-solid"></div>
          </>
          <div className="p-8">
            <div className="d-flex align-items-center justify-content-xl-between mb-4">
              <span className="font-weight-bold text-muted font-size-sm mr-2">
                {/* {parseInt(row.idRole) === roles.doctor ? " */}Paziente
                {/* " : "Medico"} */}
              </span>
              <span className="font-weight-bolder text-primary text-right">
                {row.user}
              </span>
            </div>
            <div className="d-flex align-items-center justify-content-xl-between mb-7">
              <span className="font-weight-bold text-muted font-size-sm mr-2">
                Ora appuntamento
              </span>
              <span className="font-weight-bolder text-dark-50 text-right">
                {moment(row.startTime).format("HH:mm")}-
                {moment(row.endTime).format("HH:mm")}
              </span>
            </div>
            <div className="d-flex align-items-center justify-content-xl-between mb-4">
              <span className="font-weight-bold text-muted font-size-sm mr-2">
                Allegati:
              </span>
              <span className="font-weight-bolder text-dark-50 text-right">
                {row.attachments === "" ? (
                  "Nessun allegato"
                ) : (
                  <p>
                    <a href={toAbsoluteUrl(row.attachments)}>Allegato</a>
                  </p>
                )}
              </span>
            </div>
          </div>
          <div className="separator separator-solid"></div>

          {/* {parseInt(row.idRole) === roles.doctor ? ( */}
          <div>
            <div className="float-xl-right">
              {makeActionBtn({
                name: "Modifica",
                //callback: editCallback,
                variant: "info",
                icon: faCalendarDays,
                value: row.idRichiesta,
                setShow: setShow,
                setEdit: setEdit,
                type: "edit",
              })}
              {makeActionBtn({
                name: "Rifiuta", // "Annulla" per il non admin/magazziniere
                /* callback: cancelCallback, */
                variant: "danger",
                icon: faCalendarXmark,
                value: row.idAppointment,
                action: "decline",
                setShow: setShow,
                setEdit: setEdit,
                type: "decline",
              })}
              {makeActionBtn({
                name: "Accetta",
                //callback: evasoCallback,
                action: "accept",
                setShow: setShow,
                setEdit: setEdit,
                type: "accept",
                variant: "primary",
                icon: faCalendarCheck,
                value: row.idAppointment,
              })}
            </div>
          </div>
          {/*  ) : null} */}
        </div>
      </>
    );
  };

  const EditAppointment = ({
    idAppointment,
    show,
    setShow,
    startTime,
    endTime,
    dateAppointment,
    editing,
    setResult,
    result,
  }) => {
    const formik = useFormik({
      initialValues: {
        dateAppointment: moment(dateAppointment).format("YYYY-MM-DD"),
        endTime: moment(endTime).format("HH:mm"),
        startTime: moment(startTime).format("HH:mm"),
      },
      onSubmit: (values) => {
        let payload = {
          accessToken: user.authToken,
          idAppointment: idAppointment,
          ...values,
        };
        //api.EditAppointment(payload).then((data) => setResult(data));
      },
    });
    return (
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setCount(count + 1);
        }}
        className="card-custom gutter-b"
      >
        <Modal.Header className="border-0 py-4">
          <Modal.Title>
            {editing === "edit" ? <h3>Modifica appuntamento</h3> : null}
            {editing === "delete" ? <h3>Annulla appuntamento</h3> : null}
            {editing === "accept" ? <h3>Accetta appuntamento</h3> : null}
          </Modal.Title>
        </Modal.Header>
        {result.code === undefined ? (
          <Modal.Body>
            {editing === "edit" ? (
              <Form>
                <Form.Group className="col-12">
                  <Form.Label className="col-12">Data appuntamento</Form.Label>
                  <Form.Control
                    type="date"
                    name="dateAppointment"
                    {...formik.getFieldProps("dateAppointment")}
                    required
                  />
                </Form.Group>
                <Form.Group className="col-12">
                  <Form.Label className="col-12">
                    Ora inizio appuntamento
                  </Form.Label>
                  <Form.Control
                    type="time"
                    name="startTime"
                    {...formik.getFieldProps("startTime")}
                    required
                  />
                </Form.Group>
                <Form.Group className="col-12">
                  <Form.Label className="col-12">
                    Ora fine appuntamento
                  </Form.Label>
                  <Form.Control
                    type="time"
                    name="endTime"
                    {...formik.getFieldProps("endTime")}
                    required
                  />
                </Form.Group>
              </Form>
            ) : null}
            {editing === "delete" ? (
              <p>
                Sei sicuro di voler annullare l'appuntamento del
                <br />
                {moment(formik.values.dateAppointment).format(
                  "DD MMMM YYYY"
                )}{" "}
                {formik.values.startTime} - {formik.values.endTime}
              </p>
            ) : null}
            {editing === "accept" ? (
              <p>
                Sei sicuro di voler accettare l'appuntamento del
                <br />
                {moment(formik.values.dateAppointment).format(
                  "DD MMMM YYYY"
                )}{" "}
                {formik.values.startTime} - {formik.values.endTime}
              </p>
            ) : null}
          </Modal.Body>
        ) : result.code !== undefined && result.code === 200 ? (
          <Modal.Body>
            Appuntamento{" "}
            {editing === "accept" ? "accettato con successo!" : null}
            {editing === "edit" ? "modificato con successo!" : null}
            {editing === "delete" ? "eliminato con successo!" : null}
          </Modal.Body>
        ) : (
          <Modal.Body>C'è stato un errore nell'operazione</Modal.Body>
        )}
        <Modal.Footer className="border-0 py-4">
          <Button
            /* onClick={
              editing === "edit"
                ? formik.handleSubmit
                : () =>
                    api.AcceptDeclineAppointment({
                      idAppointment: idAppointment,
                      accessToken: user.authToken,
                      action: editing,
                    })
            } */
            variant="primary"
          >
            Continua
          </Button>
          <Button
            onClick={() => {
              setCount(count + 1);
              setShow(false);
            }}
            variant="danger"
          >
            Chiudi
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  // Columns definition
  const campioniColumns = [
    /* {
      name: "user",
      title: parseInt(user.idRole) === roles.doctor ? "Paziente" : "Medico",
    }, */
    { name: "file", title: "File" },
    { name: "sharedBy", title: "Condiviso da" },
    { name: "dateUploaded", title: "Data caricamento" },
    { name: "size", title: "Dimensioni" },
    { name: "type", title: "Tipo di documento" },
  ];

  const updateFromServer = () => {
    setLoading(true);
    /*  api
      .GetAppuntamentiTabella({
        accessToken: user.authToken,
        idOrganization: false,
      })
      .then((data) => setRows(data.result))
      .then(() => setLoading(false)); */
  };
  useEffect(() => {
    updateFromServer();
    /*eslint-disable-next-line*/
  }, [count]);

  // paging and sorting
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [sorting, setSorting] = useState(initialSorting);

  /*eslint-disable-next-line*/
  //const campioniMap = new Map(rows.map((c) => [c.id, c]));
  return (
    <>
      <Card className="card-custom gutter-b">
        <Card.Header className="border-0 py-5">
          <Card.Title className="float-left col-auto">
            <h3 className="font-weight-bolder text-dark">Documenti</h3>
          </Card.Title>
          {/*  {parseInt(user.idRole) === roles.doctor ? ( */}
          <div className="float-right col-auto">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="documentations-tooltip">
                  Nuovo appuntamento
                </Tooltip>
              }
            >
              <Button
                onClick={() => {
                  setVideoconference(0);
                  setPage("newAppointment");
                }}
                className="mr-2"
                variant="primary"
              >
                <FontAwesomeIcon className="p-auto" icon={faPen} />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="documentations-tooltip">
                  Nuovo teleconsulto
                </Tooltip>
              }
            >
              <Button
                onClick={() => {
                  setVideoconference(1);
                  setPage("newAppointment");
                }}
                className="ml"
                variant="danger"
              >
                <FontAwesomeIcon className="p-auto" icon={faTrashCan} />
              </Button>
            </OverlayTrigger>
          </div>
          {/*  ) : null} */}
        </Card.Header>
        <Card.Body className="pt-0">
          <Grid rows={rows /* [...rows.values()] */} columns={campioniColumns}>
            <DateFormattedColumn for={["dateAppointment"]} />
            <LastUpdatedColumn for={["lastUpdate"]} />
            <StatoFormattedColumn for={["status"]} />
            <RowDetailState />
            <FilteringState />
            <IntegratedFiltering />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <IntegratedPaging />
            <SortingState sorting={sorting} onSortingChange={setSorting} />
            <IntegratedSorting />
            <Table messages={tableMessages} tableComponent={TableComponent} />
            <TableHeaderRow showSortingControls />
            <TableFilterRow
              messages={filterRowMessages}
              cellComponent={FilterCell}
            />
            <TableRowDetail contentComponent={RowDetail} />
            <PagingPanel messages={pagingPanelMessages} pageSizes={pageSizes} />
          </Grid>
        </Card.Body>
      </Card>
    </>
  );
};
