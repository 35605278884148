/*eslint-disable */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux"
import config from '../../../../config/config'
import { ModalRequest } from '../mixed/ModalRequest';
import { List } from '@material-ui/core';
import { Button } from '@material-ui/core';

export function ListsWidget10({ className }) {
  
  const { user } = useSelector((state) => state.auth);
  const [notifiche, setNotifiche] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedNotification, setSelect] = useState("");

  const handleClose = () => setShow(false);
  function handleShow (notifiche) {
    setSelect(notifiche)
    setShow(true)
  }

  const requestOptions = {
    headers: {
      'Authorization': "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
    },
    method: 'POST',
    body: JSON.stringify({
      'idUtente': user.id,
      'idRole'  : user.id_role,
      'idDepartment' : user.idDepartment 
    })
  }
  
  async function fetchNotifiche() {
    const rawData = await fetch(config.apiUrl + 'users/GetNotification.php', requestOptions)
    const data = await rawData.json()
    setNotifiche(data);
  }
  
  useEffect(()=>{
    fetchNotifiche()
  }, [show])

 function handleStatus(notifica) {
   if (notifica.status === "Pending") {
     return "label label-lg label-light-warning label-inline font-weight-bold py-4";
   }else if (notifica.status === "Approvata") {
    return "label label-lg label-light-success label-inline font-weight-bold py-4";
  }else if (notifica.status === "Rifiutata") {
    return "label label-lg label-light-danger label-inline font-weight-bold py-4";
  } 
 }

  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-dashboard border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            Notifiche
          </h3>
      </div>
      
        {/* Body */}
        <div className="card-body pt-0">
          <List>
          {notifiche.map(notifiche => (
            <div key={notifiche} className="d-flex flex-wrap align-items-center justify-content-between w-100">
              <div className="d-flex flex-column align-items-cente py-2 w-75">
                <a
                  href={"../../../../../public"+notifiche.media}
                  className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"
                  download
                >
                  Richiesta {notifiche.event} - (download certificato) 
                </a>
                <span className="text-muted font-weight-bold">
                  Data richiesta: {notifiche.dateRequest} per le date: {notifiche.start} - {notifiche.end}<br />
                  {user.id_role === "4" ? "Effettuata da "+notifiche.utenteRichiesta+" (ID: "+notifiche.idUtente+")" : null}
                </span>
              </div>
              {user.id_role === "4" ? <Button onClick={()=>handleShow(notifiche)}>
              <span
               className={handleStatus(notifiche)}>
                {notifiche.status}
              </span>
              </Button> : <span
               className={handleStatus(notifiche)}>
                {notifiche.status}
              </span>} 
            </div>
            ))}
          </List>
        </div>
      </div>
      <ModalRequest
        notifica = {selectedNotification}
        handleShow = {handleShow}
        handleClose = {handleClose}
        show = {show}

      />
    </>
  );
}
