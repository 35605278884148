/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import { TabellaDipendenti } from "../../_metronic/_partials/widgets/advance-tables/TabellaDipendenti";
import { ModalImportCsvDipendenti } from "../../_metronic/_partials/widgets/mixed/ModalImportCsvDipendenti";
import { Button, Modal, Form } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import config, { roles } from "../../config/config";
import { useFormik } from "formik";
import * as yup from "yup";

export function Dipendenti() {
  const { user } = useSelector((state) => state.auth);

  const [departments, setDepartments] = useState([]);
  const [customfield, setCustomField] = useState([]);
  const [ruoli, setRuoli] = useState([]);
  const [selectedDepartment, setSelected] = useState("all");
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(0);
  const [result, setResult] = useState({
    message: "Messaggio",
    title: "Titolo",
    loading: false,
    open: false,
  });
  const [loading, setLoading] = useState(false);

  const [openToast, setOpenToast] = useState(false);
  const handleOpenToast = () => {
    setOpenToast(true);
  };
  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const getInputClassesForHandle = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");

  const [modalImport, setModalImport] = useState(false);
  const handleOpenModalImport = () => setModalImport(true);
  const handleCloseModalImport = () => setModalImport(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (user[0].dipendenti === false) {
    window.location.href = "/dashboard";
  }

  const handleResult = (result) => {
    setResult(result);
  };

  const handleCount = () => setCount(count + 1);

  const handleSelect = (e) => {
    setSelected(e.target.value);
    /* setNameDepartment(departments[key].nameDepartment) */
  };

  async function GetUsersByDepartment() {
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idUtente: user.id,
        idOrganization: user.id_organization,
        userDepartment: user.idDepartment,
        idRole: user.id_role,
        idDepartment: selectedDepartment,
      }),
    };
    const rawData = await fetch(
      config.apiUrl + "users/GetUserByDepartment.php",
      requestOptions
    );
    const data = await rawData.json();
    setData(data);
  }

  async function GetAllDepartments() {
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idUtente: user.id,
        idOrganization: user.id_organization,
        page: "tabellaDipendenti",
      }),
    };
    const rawData = await fetch(
      config.apiUrl + "users/GetAllDepartments.php",
      requestOptions
    );
    const data = await rawData.json();
    setDepartments(data);
  }

  const GetAllCustomField = async () => {
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idUser: user.id,
        idOrganization: user.id_organization,
      }),
    };
    const rawData = await fetch(
      config.apiUrl + "users/GetColumnField.php",
      requestOptions
    );
    const data = await rawData.json();
    setCustomField(data);
  };

  async function GetAllruoli() {
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idUtente: user.id,
        idOrganization: user.id_organization,
        page: "tabellaDipendenti",
      }),
    };
    const rawData = await fetch(
      config.apiUrl + "users/GetAllRoles.php",
      requestOptions
    );
    const data = await rawData.json();
    setRuoli(data);
  }

  useEffect(() => {
    GetUsersByDepartment();
    GetAllDepartments();
    GetAllruoli();
    GetAllCustomField();
  }, [
    selectedDepartment,
    count,
    modalImport === false,
    show === false,
    loading,
  ]);

  let schema = yup.object({
    email: yup
      .string()
      .email("Inserire una email valida")
      .required("Inserire una email valida"),
    emailAziendale: yup
      .string()
      .email("Inserire una email valida")
      .notRequired(),
    firstname: yup
      .string()
      .required("Inserire un nome")
      .min(3, "Minimo 3 caratteri")
      .max(20, "Massimo 20 caratteri"),
    lastname: yup
      .string()
      .required("Inserire un nome")
      .min(3, "Minimo 3 caratteri")
      .max(20, "Massimo 20 caratteri"),
    birthday: yup.date().required("Inserire il giorno di nascita"),
    idDepartment: yup.number().required("Selezionare il dipartimento"),
    idRole: yup.number().required("Selezionare il ruolo dipendente"),
    iban: yup
      .string()
      .notRequired()
      .min(30, "L'IBAN deve essere di 30 cifre")
      .max(30, "L'IBAN deve essere di 30 cifre"),
    vatNumber: yup
      .string()
      .notRequired()
      .matches(/^\d+$/, "La partita IVA può contenere solo numeri")
      .min(11, "La partita IVA deve avere 11 caratteri")
      .max(11, "La partita IVA deve avere 11 caratteri"),
    phone: yup
      .string()
      .required("Inserire un numero di telefono valido")
      .matches(/^\d+$/, "Il numero di telefono può contenere solo numeri")
      .min(10, "Il numero di telefono deve contenere minimo 10 cifre")
      .max(30, "Il numero di telefono non può contenere più di 30 cifre"),
  });

  const formik = useFormik({
    initialValues: {
      idOrganization: user.id_organization,
      idUser: user.id,
      email: "",
      emailAziendale: "",
      firstname: "",
      lastname: "",
      birthday: "",
      idDepartment: "",
      idRole: "",
      iban: "",
      vatNumber: "",
      phone: "",
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      const requestOptions = {
        headers: {
          Authorization:
            "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
        },
        method: "POST",
        body: JSON.stringify({
          values,
        }),
      };
      setLoading(true);
      setShow(false);
      setOpenToast(true);
      fetch(config.apiUrl + "users/CreateDipendente.php", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setMessage(data);
          setTitle("Creazione dipendente completata");
        })
        .then(() => setLoading(false));
    },
  });

  return (
    <>
      <div className="row" style={{ backgroundColor: "white" }}>
        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Aggiungi dipendente{" "}
              {parseInt(user.id_role) !== roles.admin
                ? " in " + user.nameDepartment
                : null}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Dati dipendente</h4>
            <Form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      onChange={formik.handleChange}
                      {...formik.getFieldProps("firstname")}
                      className={`form-control ${getInputClassesForHandle(
                        "firstname"
                      )}`}
                      name="firstname"
                      value={formik.values.firstname}
                      type="text"
                      placeholder="Inserisci nome"
                    />
                    {formik.touched.firstname && formik.errors.firstname ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.firstname}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Indirizzo e-mail</Form.Label>
                    <Form.Control
                      onChange={formik.handleChange}
                      {...formik.getFieldProps("email")}
                      className={`form-control ${getInputClassesForHandle(
                        "email"
                      )}`}
                      name="email"
                      value={formik.values.email}
                      type="email"
                      placeholder="Inserisci e-mail"
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.email}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Numero telefono</Form.Label>
                    <Form.Control
                      onChange={formik.handleChange}
                      {...formik.getFieldProps("phone")}
                      className={`form-control ${getInputClassesForHandle(
                        "phone"
                      )}`}
                      name="phone"
                      value={formik.values.phone}
                      type="text"
                      placeholder="Inserisci numero telefono"
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.phone}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Cognome</Form.Label>
                    <Form.Control
                      onChange={formik.handleChange}
                      {...formik.getFieldProps("lastname")}
                      className={`form-control ${getInputClassesForHandle(
                        "lastname"
                      )}`}
                      name="lastname"
                      value={formik.values.lastname}
                      type="text"
                      placeholder="Inserisci cognome"
                    />
                    {formik.touched.lastname && formik.errors.lastname ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.lastname}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Giorno di nascita</Form.Label>
                    <Form.Control
                      onChange={formik.handleChange}
                      {...formik.getFieldProps("birthday")}
                      className={`form-control ${getInputClassesForHandle(
                        "birthday"
                      )}`}
                      name="birthday"
                      value={formik.values.birthday}
                      type="date"
                      placeholder="Giorno di nascita"
                    />
                    {formik.touched.birthday && formik.errors.birthday ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.birthday}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Indirizzo e-mail aziendale</Form.Label>
                    <Form.Control
                      onChange={formik.handleChange}
                      {...formik.getFieldProps("emailAziendale")}
                      className={`form-control ${getInputClassesForHandle(
                        "emailAziendale"
                      )}`}
                      name="emailAziendale"
                      value={formik.values.emailAziendale}
                      type="email"
                      placeholder="Inserisci e-mail per notifiche"
                    />
                    {formik.touched.emailAziendale &&
                    formik.errors.emailAziendale ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.emailAziendale}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      <Form.Label>Dipartimento</Form.Label>
                      {parseInt(user.id_role) === roles.admin ? (
                        <select
                          name="idDepartment"
                          formik={formik.values.idDepartment}
                          {...formik.getFieldProps("idDepartment")}
                          className={`form-control ${getInputClassesForHandle(
                            "idDepartment"
                          )}`}
                          onChange={formik.handleChange}
                        >
                          <option>Seleziona dipartimento</option>
                          {departments.map((e, key) => {
                            return (
                              <option key={key} value={e.idDepartment}>
                                {e.nameDepartment}
                              </option>
                            );
                          })}
                        </select>
                      ) : (
                        <select className="form-control" disabled>
                          <option value={user.idDepartment}>
                            {user.nameDepartment}
                          </option>
                        </select>
                      )}
                      {formik.touched.idDepartment &&
                      formik.errors.idDepartment ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.idDepartment}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-6">
                      <Form.Label>Ruolo</Form.Label>
                      <select
                        name="idRole"
                        value={formik.values.idRole}
                        {...formik.getFieldProps("idRole")}
                        className={`form-control ${getInputClassesForHandle(
                          "idRole"
                        )}`}
                        onChange={formik.handleChange}
                      >
                        <option>Seleziona ruolo</option>
                        {ruoli.map((e, key) => {
                          return (
                            <option key={key} value={e.id}>
                              {e.role}
                            </option>
                          );
                        })}
                      </select>
                      {formik.touched.idRole && formik.errors.idRole ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.idRole}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-2">
                  <div className="row">
                    <div className="col-6">
                      <Form.Group className="mb-3">
                        <Form.Label>IBAN</Form.Label>
                        <Form.Control
                          onChange={formik.handleChange}
                          {...formik.getFieldProps("iban")}
                          className={`form-control ${getInputClassesForHandle(
                            "iban"
                          )}`}
                          name="iban"
                          value={formik.values.iban}
                          type="text"
                          placeholder="Inserisci IBAN dipendente"
                        />
                        {formik.touched.iban && formik.errors.iban ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.iban}
                            </div>
                          </div>
                        ) : null}
                      </Form.Group>
                    </div>
                    <div className="col-6">
                      <Form.Group className="mb-3">
                        <Form.Label>Partita IVA</Form.Label>
                        <Form.Control
                          onChange={formik.handleChange}
                          {...formik.getFieldProps("vatNumber")}
                          className={`form-control ${getInputClassesForHandle(
                            "vatNumber"
                          )}`}
                          name="vatNumber"
                          value={formik.values.vatNumber}
                          type="text"
                          placeholder="Inserisci P. IVA"
                        />
                        {formik.touched.vatNumber && formik.errors.vatNumber ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.vatNumber}
                            </div>
                          </div>
                        ) : null}
                      </Form.Group>
                    </div>
                    {customfield !== []
                      ? customfield.map((e, key) => {
                          return (
                            <div className="col-6">
                              <Form.Group className="mb-3">
                                <Form.Label>{e.nomeCampo}</Form.Label>
                                <Form.Control
                                  onChange={formik.handleChange}
                                  name={e.slug}
                                  value={formik.values[e.slug]}
                                  key={key}
                                  type="text"
                                  placeholder={"Inserisci " + e.nomeCampo}
                                />
                              </Form.Group>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Annulla
            </Button>
            <Button variant="primary" onClick={formik.handleSubmit}>
              Aggiungi
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="col-12">
          <div className="col-6" style={{ float: "left" }}>
            {user.role === "Admin" ? (
              <select
                value={selectedDepartment}
                onChange={(e) => handleSelect(e)}
                className="form-control my-2"
                style={{ float: "left" }}
              >
                <option> Seleziona dipartimento </option>
                <option value="all"> Tutti i dipendenti </option>
                {departments.map((e, key) => {
                  return (
                    <option key={key} value={e.idDepartment}>
                      {e.nameDepartment}
                    </option>
                  );
                })}
              </select>
            ) : (
              <h6 className="my-8">Dipartimento: {user.nameDepartment}</h6>
            )}
          </div>
          <div className="col-6" style={{ float: "right" }}>
            <Button
              onClick={() => handleShow()}
              className="my-5 ml-3"
              variant="outline-dark"
              style={{ float: "right" }}
            >
              Aggiungi dipendente
            </Button>
          </div>
        </div>
        <div className="col-12">
          <TabellaDipendenti
            customfield={customfield}
            selectedDepartment={selectedDepartment}
            data={data}
            roles={ruoli}
            departments={departments}
            count={handleCount}
            loading={loading}
            setLoading={setLoading}
            message={message}
            setMessage={setMessage}
            openToast={openToast}
            handleOpenToast={setOpenToast}
            title={title}
            setTitle={setTitle}
            result={result}
            setResult={() => handleResult}
          />
        </div>
        <ModalImportCsvDipendenti
          importModal={modalImport}
          handleCloseImport={handleCloseModalImport}
          loading={loading}
          setLoading={setLoading}
          message={message}
          setMessage={setMessage}
          openToast={openToast}
          handleOpenToast={handleOpenToast}
          title={title}
          setTitle={setTitle}
          result={result}
          setResult={() => handleResult}
        />
        <div className="col-6">
          {parseInt(user.id_role) === roles.admin ? (
            <Button
              onClick={() => handleOpenModalImport()}
              className="my-5 mr-3"
              style={{ float: "left" }}
            >
              Importa CSV
            </Button>
          ) : null}
          {user.exportCsv === true ? (
            <CSVLink
              data={data}
              filename={"lista-dipendenti.csv"}
              className="btn btn-primary font-weight-bold my-5 ml"
              style={{ float: "left" }}
              separator={";"}
            >
              Esporta CSV{" "}
            </CSVLink>
          ) : null}
        </div>
        <div className="col-6"></div>
      </div>
      {/* Modal messaggio esito */}
      <Modal
        backdrop="static"
        keyboard={false}
        centered
        show={openToast}
        onHide={handleCloseToast}
      >
        <Modal.Header closeButton>
          {loading === false ? (
            <Modal.Title>{title}</Modal.Title>
          ) : (
            <Modal.Title>Upload dipendenti in corso...</Modal.Title>
          )}
        </Modal.Header>
        {loading === false ? (
          <Modal.Body>{message}</Modal.Body>
        ) : (
          <Modal.Body>
            <div className="d-flex align-items-center">
              <div className="mr-2 text-muted">
                NON AGGIORNARE O ABBANDONARE LA PAGINA MENTRE VIENE EFFETTUATO
                L'UPLOAD!
              </div>
              <div className="spinner spinner-primary mr-10"></div>
            </div>
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button
            disabled={loading}
            variant="primary"
            onClick={handleCloseToast}
          >
            Chiudi
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Fine : Modal messaggio esito */}
    </>
  );
}
