import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import config from "../../../../config/config";

export function ModalRequest(props) {
  const [esito, setEsito] = useState("pending");
  const [loading, setLoading] = useState(false);

  function handleSubmit() {
    setLoading(true);
    const requestOptionsFile = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idNotifica: props.notifica.idPresenza,
        idUser: props.notifica.idDipendente,
        type: props.notifica.type,
        esito: esito,
      }),
    };
    fetch(config.apiUrl + "calendar/UpdateRequest.php", requestOptionsFile)
      .then(() => props.setCount(props.count + 1))
      .then(() => props.handleClose())
      .then(() => setLoading(false));
  }

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={!loading}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Gestisci richiesta di {props.notifica.nomeDipendente} -{" "}
            {props.notifica.type.includes("permesso")
              ? "Permesso"
              : props.notifica.type}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Label className="col-12">
              Seleziona esito richiesta:
            </Form.Label>
            <select
              className="form-control"
              value={esito}
              onChange={(e) => {
                setEsito(e.target.value);
              }}
            >
              <option value="0">In attesa</option>
              <option value="1">Accetta</option>
              <option value="-1">Rifiuta</option>
            </select>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            variant="secondary"
            onClick={props.handleClose}
          >
            Chiudi
          </Button>
          <Button disabled={loading} variant="primary" onClick={handleSubmit}>
            Conferma
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
