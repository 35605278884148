/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { Card, Modal } from "react-bootstrap";
import config from "../../../../config/config";
import { ValutationChart } from "./RadarChart";
import { ChartRiepilogo } from "./ChartRiepilogo";
import { Tabs, Tab } from "react-bootstrap";
import {
  PagingState,
  IntegratedPaging,
  SearchState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
  RowDetailState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  Toolbar,
  SearchPanel,
} from "@devexpress/dx-react-grid-bootstrap4";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    width: 300,
  },
});

export const TabellaValutazione = (props) => {
  /*eslint-disable */
  const [changeTab, setChangeTab] = useState(0);
  const [loadingCol, setLoadingCol] = useState(false);
  const [loadingRow, setLoadingRow] = useState(false);
  const [rowUtente, setRowUtente] = useState([]);
  const [columnUtente, setColumnUtente] = useState([]);
  const [searchValue, setSearchState] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizes] = useState([10, 20, 30, 0]);
  const [sorting, setSorting] = useState([
    { columnName: "nomeDipendente", direction: "asc" },
  ]);

  const onChangeTab = () => setChangeTab(changeTab + 1);

  const classes = useStyles();

  const adminOptions = {
    headers: {
      Authorization:
        "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
    },
    method: "POST",
    body: JSON.stringify({
      selectedUser: props.id,
      idRole: props.user.id_role,
      idUser: props.user.id,
      idOrganization: props.user.id_organization,
      page: "resoconto",
    }),
  };

  const GetColumnSupervisor = () => {
    setLoadingCol(true);
    fetch(config.apiUrl + "obiettivi/GetColumnSupervisor.php", adminOptions)
      .then((response) => response.json())
      .then((data) => setColumnUtente(data))
      .then(() => setLoadingCol(false));
  };
  const GetRowsUtente = () => {
    setLoadingRow(true);
    fetch(config.apiUrl + "obiettivi/GetRowsSupervisor.php", adminOptions)
      .then((response) => response.json())
      .then((data) => setRowUtente(data))
      .then(() => setLoadingRow(false));
  };

  useEffect(() => {
    GetColumnSupervisor();
    GetRowsUtente();
  }, [props.show]);

  const TableComponent = ({ ...restProps }) => (
    <>
      <Table.Table {...restProps} className="table-striped" />
    </>
  );

  return (
    <Modal show={props.show} onHide={props.handleClose} size="xl">
      <Modal.Header closeButton={false}>
        <Modal.Title>Riepilogo valutazione {props.nomeDipendente}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12 bg-grey">
          <Tabs onSelect={onChangeTab} defaultActiveKey="chartRiepilogo">
            <Tab eventKey="chartRiepilogo" title="Grafico andamento">
              <div
                className="col-12 mb-3"
                style={{
                  borderBottom: "#eaecf2 solid 1px",
                  borderLeft: "#eaecf2 solid 1px",
                  borderRight: "#eaecf2 solid 1px",
                }}
              >
                <ChartRiepilogo
                  tab={changeTab}
                  id={props.id}
                  idRole={props.user.id_role}
                />
              </div>
            </Tab>
            <Tab eventKey="valutationChart" title="Skills">
              <div
                className="col-12 mb-3"
                style={{
                  borderBottom: "#eaecf2 solid 1px",
                  borderLeft: "#eaecf2 solid 1px",
                  borderRight: "#eaecf2 solid 1px",
                }}
              >
                <ValutationChart
                  tab={changeTab}
                  user={props.user}
                  id={props.id}
                />
              </div>
            </Tab>
          </Tabs>

          <Card className="col-12">
            <Card.Header>
              <h6 className="card-title-h3">TABELLA VALUTAZIONI DIPENDENTE</h6>
            </Card.Header>
            {!loadingCol || !loadingRow ? (
              <Grid rows={rowUtente} columns={columnUtente}>
                <RowDetailState defaultExpandedRowIds={[]} />
                <PagingState
                  currentPage={currentPage}
                  onCurrentPageChange={setCurrentPage}
                  pageSize={pageSize}
                  onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />
                <SearchState
                  value={searchValue}
                  onValueChange={setSearchState}
                />
                <IntegratedFiltering />
                <SortingState sorting={sorting} onSortingChange={setSorting} />
                <IntegratedSorting />
                <Table tableComponent={TableComponent} />
                <TableHeaderRow />
                <Toolbar />
                <SearchPanel />
                <PagingPanel pageSizes={pageSizes} />
              </Grid>
            ) : (
              <div className={classes.root}>
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="rect" animation="wave" />
              </div>
            )}
          </Card>
        </div>
      </Modal.Body>
    </Modal>
  );
};
