import React from "react";
import { ProgressBar } from "react-bootstrap";
import { Skeleton } from "@material-ui/lab";

export const ProgressOfficina = (props) => (
  <>
    <div className="row">
      <div className="col-lg-6 col-sm-12">
        <h6 className="col-12 pl-7">LAVORI:</h6>
        <div className="col-12 p-7">
          <label className="col-12">
            Settimanale
            {props.worksProgressBar.weekWorks !== undefined
              ? ": " +
                props.worksProgressBar.weekWorks +
                "/" +
                props.worksProgressBar.obiettivoSettimanale
              : null}
          </label>
          {!props.loading && props.selectedUser ? (
            <ProgressBar
              animated
              variant="success"
              now={
                props.worksProgressBar !== undefined &&
                props.worksProgressBar.percentageWeek !== undefined
                  ? props.worksProgressBar.percentageWeek
                  : 0
              }
              label={`${
                props.worksProgressBar !== undefined &&
                props.worksProgressBar.percentageWeek !== undefined
                  ? props.worksProgressBar.percentageWeek
                  : 0
              }%`}
            />
          ) : (
            <Skeleton />
          )}
        </div>
        <div className="col-12 p-7">
          <label className="col-12">
            Mensile
            {props.worksProgressBar.monthWorks !== undefined
              ? ": " +
                props.worksProgressBar.monthWorks +
                "/" +
                props.worksProgressBar.obiettivoMensile
              : null}
          </label>
          {!props.loading && props.selectedUser ? (
            <ProgressBar
              animated
              variant="info"
              now={
                props.worksProgressBar !== undefined &&
                props.worksProgressBar.percentageMonth !== undefined
                  ? props.worksProgressBar.percentageMonth
                  : 0
              }
              label={`${
                props.worksProgressBar !== undefined &&
                props.worksProgressBar.percentageMonth !== undefined
                  ? props.worksProgressBar.percentageMonth
                  : 0
              }%`}
            />
          ) : (
            <Skeleton />
          )}
        </div>
      </div>
      <div className="col-lg-6 col-sm-12">
        <h6 className="col-12 pl-7">ORE PRODUTTIVITÀ:</h6>
        <div className="col-12 p-7">
          <label className="col-12">Settimanale</label>
          {!props.loading && props.selectedUser ? (
            <ProgressBar
              animated
              variant="success"
              now={
                props.worksProgressBar !== undefined &&
                props.worksProgressBar.percentageWeek !== undefined
                  ? props.worksProgressBar.percentageWeek
                  : 0
              }
              label={`${
                props.worksProgressBar !== undefined &&
                props.worksProgressBar.percentageWeek !== undefined
                  ? props.worksProgressBar.percentageWeek
                  : 0
              }%`}
            />
          ) : (
            <Skeleton />
          )}
        </div>
        <div className="col-12 p-7">
          <label className="col-12">Mensile</label>
          {!props.loading && props.selectedUser ? (
            <ProgressBar
              animated
              variant="info"
              now={
                props.worksProgressBar !== undefined &&
                props.worksProgressBar.percentageMonth !== undefined
                  ? props.worksProgressBar.percentageMonth
                  : 0
              }
              label={`${
                props.worksProgressBar !== undefined &&
                props.worksProgressBar.percentageMonth !== undefined
                  ? props.worksProgressBar.percentageMonth
                  : 0
              }%`}
            />
          ) : (
            <Skeleton />
          )}
        </div>
      </div>
    </div>
  </>
);
