export default [
    {
        id: 1,
        file_name: "Listino prezzi",
        full_name: "Mario Loffredo",
        created_date: "14/06/2021",
        extension: ".pdf",
        dimension: "6MB",
    },
    {
        id: 2,
        file_name: "Documenti",
        full_name: "Sonni Gabotti",
        created_date: "10/06/2021",
        extension: ".pdf",
        dimension: "6MB",
    },
    {
        id: 3,
        file_name: "Guida tecnica",
        full_name: "Naomi Galbreth",
        created_date: "09/06/2021",
        extension: ".pdf",
        dimension: "12MB",
    },
    {
        id: 4,
        file_name: "Resoconto mensile",
        full_name: "Mario Loffredo",
        created_date: "09/06/2021",
        extension: ".xls",
        dimension: "3MB",
    },
    {
        id: 5,
        file_name: "Report",
        full_name: "Sean Stark",
        created_date: "09/06/2021",
        extension: ".xls",
        dimension: "8MB",
    },
    {
        id: 6,
        file_name: "Resoconto tecnici",
        full_name: "Admin",
        created_date: "09/06/2021",
        extension: ".xls",
        dimension: "9MB",
    },
    {
        id: 7,
        file_name: "Resoconto customer care",
        full_name: "Admin",
        created_date: "07/06/2021",
        extension: ".csv",
        dimension: "12MB",
    },
    {
        id: 8,
        file_name: "Tutoring",
        full_name: "Admin",
        created_date: "07/06/2021",
        extension: ".doc",
        dimension: "3MB",
    },
    {
        id: 9,
        file_name: "Test",
        full_name: "Admin",
        created_date: "07/06/2021",
        extension: ".doc",
        dimension: "4MB",
    },
    {
        id: 10,
        file_name: "Prova",
        full_name: "Admin",
        created_date: "07/06/2021",
        extension: ".zip",
        dimension: "72MB",
    },
]