import React, { useState, useEffect } from "react";
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  LineSeries,
  Title,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";
import { scaleBand } from "@devexpress/dx-chart-core";
import { ArgumentScale } from "@devexpress/dx-react-chart";
import { line, curveStep } from "d3-shape";
import { ValueScale, Stack, Animation } from "@devexpress/dx-react-chart";
import config from "../../../../config/config";
import { Skeleton } from "@material-ui/lab";
/*eslint-disable */

export const ValutationChart = (props) => {
  const [loadingRadar, setLoadingRadar] = useState(false);
  const [data, setData] = useState([]);

  const Root = (props) => (
    <Legend.Root {...props} className="m-auto flex-row" />
  );

  const GetValutazioni = () => {
    const adminOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        selectedUser: props.id,
        idRole: props.user.id_role,
        idUser: props.user.id,
        idOrganization: props.user.id_organization,
        page: "resoconto",
      }),
    };
    setLoadingRadar(true);
    fetch(config.apiUrl + "obiettivi/GetValutazioni.php", adminOptions)
      .then((response) => response.json())
      .then((data) => setData(data))
      .then(() => setLoadingRadar(false));
  };

  const Marker = (props) => {
    const { className, color } = props;
    return (
      <svg className={className} fill={color} width="10" height="10">
        <rect x={0} y={0} width={100} height={100} />
      </svg>
    );
  };

  const Line = (props) => (
    <LineSeries.Path
      {...props}
      path={line()
        .x(({ arg }) => arg)
        .y(({ val }) => val)
        .curve(curveStep)}
    />
  );

  useEffect(() => {
    GetValutazioni();
  }, [props.tab]);

  const format = () => (tick) => tick;

  const Label = (symbol) => (props) => {
    const { text } = props;
    return <ValueAxis.Label {...props} text={text + symbol} />;
  };

  const modifyValueDomain = (domain) => [domain[0], 100];
  const labelPercentual = Label(" %");

  return (
    <>
      {!loadingRadar ? (
        <Chart data={data}>
          <ValueScale name="value" modifyDomain={modifyValueDomain} />
          <ArgumentScale factory={scaleBand} />
          <ArgumentAxis tickFormat={format} />
          <ValueAxis scaleName="value" labelComponent={labelPercentual} />
          <BarSeries
            scaleName="value"
            name="Valutazione"
            valueField="valutation"
            argumentField="field"
            color="#0f4c5c"
          />
          <BarSeries
            scaleName="value"
            name="Autovalutazione"
            valueField="autovalutation"
            argumentField="field"
            color="#ff9f1c"
          />
          <LineSeries
            scaleName="value"
            name="Media valutazione/autovalutazione"
            valueField="mediaValutazione"
            argumentField="field"
            color="#e71d36"
            seriesComponent={Line}
          />
          <Animation />
          <Legend
            position="right"
            rootComponent={Root}
            markerComponent={Marker}
          />
          <Title text="Skills" />
          <Stack />
        </Chart>
      ) : (
        <div className="col-12">
          <Skeleton animation="wave" variant="text" />
          <Skeleton animation="wave" variant="circle" width={40} height={40} />
          <Skeleton animation="wave" variant="rect" width={210} height={118} />
        </div>
      )}
    </>
  );
};
