/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import config from "../../../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";

export function ListsWidget9({ className }) {
  const { user } = useSelector((state) => state.auth);
  const [events, setEvents] = useState([]);

  const options = {
    headers: {
      Authorization:
        "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
    },
    method: "POST",
    body: JSON.stringify({
      idUser: user.id,
      idOrganization: user.id_organization,
      idDepartment: user.idDepartment,
    }),
  };

  const GetAvvisi = () => {
    fetch(config.apiUrl + "calendar/GetAvvisi.php", options)
      .then((response) => response.json())
      .then((data) => setEvents(data));
  };

  useEffect(() => {
    GetAvvisi();
  }, []);

  return (
    <div className={`card card-custom ${className}`}>
      {/* Head */}
      <div className="card-dashboard border-0 py-5">
        <h3
          style={{ color: "black !important" }}
          className="card-title align-items-start flex-column"
        >
          <span
            style={{ color: "black !important" }}
            className="card-label font-weight-bolder"
          >
            PROSSIMI EVENTI
          </span>
        </h3>
      </div>
      {/* Body */}
      <div className="card-body pt-0 pb-3">
        <div className="tab-content">
          <div className="table-responsive">
            <table className="table table-head table-head-custom table-borderless table-vertical-center ">
              <thead>
                <tr className="text-left text-uppercase">
                  <th className="pl-7" style={{ minWidth: "150px" }}>
                    <span className="text-color #b5b5c3">evento</span>
                  </th>
                  <th style={{ minWidth: "100px" }}>ora inizio evento</th>
                  <th style={{ minWidth: "100px" }}>ora fine evento</th>
                  <th style={{ minWidth: "100px" }}>all day</th>
                  <th style={{ minWidth: "100px" }}>data evento</th>
                  <th style={{ minWidth: "100px" }}>note</th>
                </tr>
              </thead>
              <tbody>
                {events !== "undefined"
                  ? events.map((e, key) => (
                      <tr>
                        <td className="pl-0 py-8">
                          <div className="d-flex align-items-center">
                            <div>
                              <a className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                {e.title}
                              </a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {e.startTime}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {e.endTime}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {parseInt(e.allDay) === 1 ? <FontAwesomeIcon size="xl" icon={faCircleCheck} color="#95d5b2"/>: null}
                            {parseInt(e.allDay) === 0 ? <FontAwesomeIcon size="xl" icon={faCircleXmark} color="#f64e60"/>: null}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {e.startDate}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {e.description}
                          </span>
                        </td>
                        <td></td>
                      </tr>
                    ))
                  : "NESSUNA VALUTAZIONE"}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
