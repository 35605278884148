import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { roles } from "../../../config/config";
import { Card } from "@material-ui/core";
import {
  Grid,
  Table,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import config from "../../../config/config";
/*eslint-disable */

export const ResocontoValutazione = () => {
  const { user } = useSelector((state) => state.auth);

  const TableComponent = ({ ...restProps }) => (
    <Table.Table {...restProps} className="table-striped table-hover" />
  );

  const [loadingCol, setLoadingCol] = useState(false);
  const [loadingRow, setLoadingRow] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedUser, setSelectedUser] = useState(-1);
  const [users, setUsers] = useState([]);

  const options = {
    headers: {
      Authorization:
        "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
    },
    method: "POST",
    body: JSON.stringify({
      idOrganization: user.id_organization,
      idDipendente: user.id,
      idDepartment: user.idDepartment,
      idModule: user.idModule,
      authToken: user.authToken,
      idRole: user.id_role,
    }),
  };

  const adminOptions = {
    headers: {
      Authorization:
        "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
    },
    method: "POST",
    body: JSON.stringify({
      selectedUser: selectedUser,
      idRole: user.id_role,
      idUser: user.id,
      idOrganization: user.id_organization,
    }),
  };

  const GetColumnsEmployee = async () => {
    setLoadingCol(true);
    fetch(config.apiUrl + "obiettivi/GetColumnsEmployee.php", options)
      .then((response) => response.json())
      .then((data) => {
        if (data !== []) {
          setColumns(data);
        }
      })
      .then(() => setLoadingCol(false));
  };

  const GetRowsEmployee = async () => {
    setLoadingRow(true);
    fetch(config.apiUrl + "obiettivi/GetRowsEmployee.php", options)
      .then((response) => response.json())
      .then((data) => {
        if (data !== []) {
          setRows(data);
        }
      })
      .then(() => setLoadingRow(false));
  };

  const GetAllUsers = async () => {
    fetch(config.apiUrl + "users/GetAllUsers.php", options)
      .then((response) => response.json())
      .then((data) => setUsers(data));
  };

  const GetColumnsSupervisor = async () => {
    setLoadingCol(true);
    fetch(config.apiUrl + "obiettivi/GetColumnSupervisor.php", adminOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data !== []) {
          setColumns(data);
        }
      })
      .then(() => setLoadingCol(false));
  };

  const GetRowsSupervisor = async () => {
    setLoadingRow(true);
    fetch(config.apiUrl + "obiettivi/GetRowsSupervisor.php", adminOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data !== []) {
          setRows(data);
        }
      })
      .then(() => setLoadingRow(false));
  };

  useEffect(() => {
    if (
      parseInt(user.id_role) === roles.admin ||
      parseInt(user.id_role) === roles.hr
    ) {
      if (selectedUser !== -1) {
        GetColumnsSupervisor();
        GetRowsSupervisor();
      }
      GetAllUsers();
    } else {
      GetColumnsEmployee();
      GetRowsEmployee();
    }
  }, [selectedUser]);

  return (
    <div>
      {parseInt(user.id_role) === roles.admin ||
      parseInt(user.id_role) === roles.hr ? (
        <Card className="mb-3">
          <select
            onChange={(e) => setSelectedUser(e.target.value)}
            className="form-control col-12"
          >
            <option value="">Seleziona dipendente</option>
            {users.map((e, key) => {
              return (
                <option key={key} value={e.id}>
                  {e.nomeCompleto}
                </option>
              );
            })}
          </select>
        </Card>
      ) : null}
      <Card>
        <div className="mt-6 mb-6 ml-2 mr-2">
          {loadingCol === false && loadingRow === false ? (
            <Grid rows={rows} columns={columns}>
              <Table tableComponent={TableComponent} />
              <TableHeaderRow />
            </Grid>
          ) : (
            <div className="divLoading" style={{ padding: "50px" }}>
              <span
                className="spinner-border"
                style={
                  loadingCol || loadingRow
                    ? { width: "6rem", height: "6rem" }
                    : { display: "none" }
                }
                role="status"
              >
                <span className="sr-only">Caricamento...</span>
              </span>
              <p className="my-7 mt-4">Caricamento tabella valutazione...</p>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};
