import fileTableMock from "./fileTableMock";
import MockUtils from "./mock.utils";

export default function mockFile(mock) {
  mock.onPost("api/Files").reply(({ data }) => {
    const { file } = JSON.parse(data);
    const {
        file_name= "",
        full_name= "",
        created_date= "14/06/2021",
        extension= ".pdf",
        dimension= "6MB"
    } = file;

    const id = generateUserId();
    const newFile = {
      id,
      file_name,
      full_name,
      created_date,
      extension,
      dimension,
    };
    fileTableMock.push(newFile);
    return [200, { file: newFile }];
  });

  mock.onPost("api/Files/find").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdFiles = mockUtils.baseFilter(
      fileTableMock,
      queryParams
    );
    return [200, filterdFiles];
  });

  mock.onPost("api/Files/deleteFiles").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = fileTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        fileTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/Files/updateStatusForFiles").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    fileTableMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/Files\/\d+/).reply(config => {
    const id = config.url.match(/api\/Files\/(\d+)/)[1];
    const File = fileTableMock.find(el => el.id === +id);
    if (!File) {
      return [400];
    }

    return [200, File];
  });

  mock.onPut(/api\/Files\/\d+/).reply(config => {
    const id = config.url.match(/api\/Files\/(\d+)/)[1];
    const { File } = JSON.parse(config.data);
    const index = fileTableMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    fileTableMock[index] = { ...File };
    return [200];
  });

  mock.onDelete(/api\/Files\/\d+/).reply(config => {
    const id = config.url.match(/api\/Files\/(\d+)/)[1];
    const index = fileTableMock.findIndex(el => el.id === +id);
    fileTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateUserId() {
  const ids = fileTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
