/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import config, { roles } from "../../../config/config";

import { Button } from "react-bootstrap";
import { Card, Typography } from "@material-ui/core";
import "react-svg-radar-chart/build/css/index.css";
import {
  PagingState,
  IntegratedPaging,
  SearchState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
  RowDetailState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  PagingPanel,
  Toolbar,
  SearchPanel,
} from "@devexpress/dx-react-grid-bootstrap4";
import { TabellaValutazione } from "./modalValutazione.js/TabellaValutazione";
import { NuovaValutazione } from "./modalValutazione.js/NuovaValutazione";
import { PaginaDipendenti } from "./dipendenti/PaginaDipendenti";

export const ValutazioneDipendente = () => {
  /*eslint-disable */
  const { user } = useSelector((state) => state.auth);

  const columns = [
    { name: "nomeDipendente", title: "Dipendente" },
    { name: "nameModule", title: "Modulo" },
    { name: "nameDepartment", title: "Dipartimento" },
    { name: "media", title: "Media valutazione" },
    { name: "stato", title: "Confronto" },
    { name: "lastValutation", title: "Ultima valutazione" },
  ];

  const TableComponent = ({ ...restProps }) => (
    <>
      <Table.Table {...restProps} className="table-striped" />
    </>
  );

  const [rows, setRows] = useState([]);
  const [searchValue, setSearchState] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizes] = useState([10, 20, 30, 0]);
  const [sorting, setSorting] = useState([
    { columnName: "nomeDipendente", direction: "asc" },
  ]);
  //STATO UTENTE SELEZIONATO
  const [selectedUser, setSelectedUser] = useState({ index: 0, nome: "" });
  //STATO PER MODAL RIEPILOGO
  const [showRiepilogo, setShowRiepilogo] = useState(false);
  const handleOpenRiepilogo = (index, nome) => {
    setSelectedUser({ index: index, nome: nome });
    setShowRiepilogo(true);
  };
  const handleCloseRiepilogo = () => {
    setShowRiepilogo(false);
    setSelectedUser({ index: 0, nome: "" });
  };
  //STATO PER MODAL VALUTAZIONE
  const [showValut, setShowValut] = useState(false);
  const handleOpenValut = (index, nome) => {
    setSelectedUser({ index: index, nome: nome });
    setShowValut(true);
  };
  const handleCloseValut = () => {
    setShowValut(false);
    setSelectedUser({ index: 0, nome: "" });
  };
  /*****************************/
  const [count, setCount] = useState(0);

  const options = {
    headers: {
      Authorization:
        "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
    },
    method: "POST",
    body: JSON.stringify({
      idOrganization: user.id_organization,
      idDipendente: user.id,
      idDepartment: user.idDepartment,
      idModule: user.idModule,
      authToken: user.authToken,
      idRole: user.id_role,
    }),
  };

  const GetUsers = async () => {
    fetch(config.apiUrl + "obiettivi/GetValutationsUsers.php", options)
      .then((response) => response.json())
      .then((data) => setRows(data));
  };

  useEffect(() => {
    if (
      parseInt(user.id_role) === roles.hr ||
      parseInt(user.id_role) === roles.admin
    )
      GetUsers();
    /* GetRowsUtente();
    GetColumnSupervisor(); */
  }, [selectedUser]);

  const RowDetail = ({ row }) => {
    return (
      <div className="row">
        <div className="col-12">
          <Typography className="col-12 mt-3 text-center">
            Aggiungi valutazione o visualizza il resoconto di{" "}
            {row.nomeDipendente}. La valutazione ha cadenza mensile, nel mese in
            cui è stata effettuata puoi solo modificarla
          </Typography>
        </div>
        <div className="col-6">
          <div className="col-12 d-flex justify-content-center">
            <Button
              onClick={() => handleOpenRiepilogo(row.id, row.nomeDipendente)}
              className="text-center mt-5 mb-1"
              size="sm"
              variant="primary"
            >
              <i className="fas fa-chart-line pr-4"></i>Riepilogo
            </Button>
          </div>
          <p className="text-muted text-center col-12">
            Tabella e grafico delle valutazioni del dipendente
          </p>
        </div>
        <div className="col-6">
          <div className="col-12 d-flex justify-content-center">
            <Button
              onClick={() => handleOpenValut(row.id, row.nomeDipendente)}
              className="text-center mt-5 mb-1"
              size="sm"
              variant="primary"
            >
              <i className="far fa-edit pr-4"></i>Nuovo
            </Button>
          </div>
          <p className="text-muted text-center col-12">
            Aggiorna o aggiungi nuova valutazione
          </p>
        </div>
      </div>
    );
  };

  if (
    parseInt(user.id_role) === roles.hr ||
    parseInt(user.id_role) === roles.admin
  ) {
    return (
      <>
        <TabellaValutazione
          id={selectedUser.index}
          nomeDipendente={selectedUser.nome}
          show={showRiepilogo}
          handleClose={handleCloseRiepilogo}
          handleOpen={handleOpenRiepilogo}
          user={user}
        />
        <NuovaValutazione
          id={selectedUser.index}
          nomeDipendente={selectedUser.nome}
          user={user}
          show={showValut}
          handleClose={handleCloseValut}
          handleOpen={handleOpenValut}
          count={count}
          setCount={setCount}
          handleOpenRiepilogo={handleOpenRiepilogo}
        />
        <div className="col-12">
          <Card className="col-12 p-4">
            <Grid rows={rows} columns={columns}>
              <RowDetailState defaultExpandedRowIds={[]} />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
              />
              <IntegratedPaging />
              <SearchState value={searchValue} onValueChange={setSearchState} />
              <IntegratedFiltering />
              <SortingState sorting={sorting} onSortingChange={setSorting} />
              <IntegratedSorting />
              <Table tableComponent={TableComponent} />
              <TableHeaderRow />
              <Toolbar />
              <SearchPanel />
              <PagingPanel pageSizes={pageSizes} />
              <TableRowDetail contentComponent={RowDetail} />
            </Grid>
          </Card>
        </div>
      </>
    );
  } else {
    return (
      <>
        <PaginaDipendenti />
      </>
    );
  }
};
