/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect, useState } from "react";
import objectPath from "object-path";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { CurrentDate } from "./components/CurrentDate";
import Snackbar from "@material-ui/core/Snackbar";
import config from "../../../../config/config";
import { DropdownCheckIn } from "./components/DropdownCheckIn";
import { isMobile } from "react-device-detect";

export function SubHeader() {
  const { user } = useSelector((state) => state.auth);
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();

  function Checkin(options) {
    fetch(config.apiUrl + "calendar/Checkin.php", options)
      .then((response) => response.json())
      .then((data) => {
        if (data.risultato) {
          setToastIn(true);
        } else {
          setToastFalse(true);
        }
      });
  }

  function Checkout(options) {
    fetch(config.apiUrl + "calendar/Checkout.php", options)
      .then((response) => response.json())
      .then(() => setToastOut(true));
  }

  const requestOptions = {
    headers: {
      Authorization:
        "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
    },
    method: "POST",
    body: JSON.stringify({
      idUser: user.id,
    }),
  };

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);

  const [toastIn, setToastIn] = useState(false);
  const handleInClose = () => setToastIn(false);
  const [toastFalse, setToastFalse] = useState(false);
  const handleFalseClose = () => setToastFalse(false);
  const [toastOut, setToastOut] = useState(false);
  const handleOutClose = () => setToastOut(false);
  const [check, setCheck] = useState("");
  const [color, setColor] = useState("");
  const [show, setShow] = useState(false);

  fetch(config.apiUrl + "calendar/LastCheck.php", requestOptions).then(
    (response) =>
      response.json().then((result) => {
        setCheck(result.setCheck);
        setColor(result.setColor);
      })
  );

  function handleClick() {
    if (check === "Check-In") {
      Checkin(requestOptions);
      setCheck("Check-Out");
      setColor("danger");
    } else {
      Checkout(requestOptions);
      setCheck("Check-In");
      setColor("success");
      setToastOut(true);
    }
  }
  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title
    );
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);

  return (
    <div
      id="kt_subheader"
      className={`subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}
        <div className="d-flex align-items-center flex-wrap mr-1">
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}

          {!isMobile ? (
            <div className="d-flex align-items-baseline mr-5">
              <h5 className="text-dark font-weight-bold my-2 mr-5">
                <>{subheader.title}</>
                {/*<small></small>*/}
              </h5>
            </div>
          ) : (
            <div className="d-flex align-items-baseline">
              <span className="text-dark text-small font-weight-bold my-2 mr-5">
                <>{subheader.title}</>
                {/*<small></small>*/}
              </span>
            </div>
          )}
        </div>

        {/* Toolbar */}
       
        <div className="btn-group">
          <DropdownCheckIn
            show={show}
            setShow={setShow}
            color={color}
            check={check}
            handleClick={handleClick}
          />
          <CurrentDate />
        </div>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={toastIn}
        onClose={handleInClose}
        message="Checkin effettuato, buon lavoro!"
      />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={toastFalse}
        onClose={handleFalseClose}
        message="Per oggi già è stato effettuato il checkin, torna domani!"
      />
      <Snackbar
        id="check-out-snack"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={toastOut}
        onClose={handleOutClose}
        message="Check out effettuato, a domani!"
      />
    </div>
  );
}
