import React from "react";
// import Progress from '../widgets/mixed/Progress';
import {
  MixedWidget1,
  MixedWidget14,
  ListsWidget9,
  /* AdvanceTablesWidget2, */
  AdvanceTablesWidget4,
  StatsWidget11,
  StatsWidget12,
  ListsWidget1,
  AdvanceTablesWidget2,
  TaskObiettivi,
} from "../widgets";
import { StatusBarObiettivi } from "../widgets/mixed/StatusBarObiettivi";

export function Demo1Dashboard() {
  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-sm-12">
          <MixedWidget1 className="card-stretch gutter-b" />
        </div>

        {/*           <div className="col-sm"> */}
        {/*  <StatusBarObiettivi className="card-stretch gutter-b" /> */}

        {/* avvisi */}
        {<div className="col-12">
          <ListsWidget9 className="card-stretch gutter-b" />
        </div>}
        {/* <div className="col-lg-6 col-xxl-4">
          <StatsWidget11
            className="card-stretch card-stretch-half gutter-b"
            symbolShape="circle"
            baseColor="success"
          />
          <StatsWidget12 className="card-stretch card-stretch-half gutter-b" />
        </div> */}
       {/*  <div className="col-lg-4 col-xxl-4 col-sm-12 order-xxl-1">
          <ListsWidget1 className="card-stretch gutter-b" />
        </div>

        <div className="col-lg-8 col-xxl-8 col-sm-12 order-1 order-xxl-1">
          <AdvanceTablesWidget2 className="card-stretch gutter-b" />
        </div> */}

        {/* <div className="col-12">
          <TaskObiettivi className="card-stretch gutter-b" />
        </div> */}

        {/*       </div> */}
        {/* prossimi eventi */}
        {/*  <div className="container">
        <div className="row">
          <div className="col-sm">
            <AdvanceTablesWidget2 className="card-stretch gutter-b" />
          </div>
        </div>
      </div> */}

        {/* widget con informazioni dipendenti -> stipendi ecc... */}
        {/* <AdvanceTablesWidget4 className="card-stretch gutter-b" /> */}

        {/* <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
          <ListsWidget3 className="card-stretch gutter-b" />
        </div> */}
      </div>
    </>
  );
}
