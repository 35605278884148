import React, { useEffect, useState } from "react";
import config, { roles } from "../../../../../config/config";
import { Button } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import {
  Grid,
  Table,
  PagingPanel,
  TableHeaderRow,
  Toolbar,
  ColumnChooser,
  TableColumnVisibility,
} from "@devexpress/dx-react-grid-bootstrap4";
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import { ModalImportCsv } from "../../mixed/ModalImportCsv";

export function TabellaCustomerCare(props) {
  const { user } = useSelector((state) => state.auth);

  const [rows, setRows] = useState([]);

  const GetCalls = () => {
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idUser: props.idUser,
        authToken: props.authToken,
      }),
    };
    fetch(config.apiUrl + "obiettivi/GetAllTicketEmployee.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setRows(data));
  };

  const [importModal, setImportModal] = useState(false);

  const handleOpenImport = () => setImportModal(true);
  const handleCloseImport = () => setImportModal(false);

  const [tableColumnExtensions] = useState([
    { columnName: "id", align: "left", width: "auto" },
    { columnName: "nomeOperatore", align: "center", width: "auto" },
    { columnName: "tipoChiamata", align: "center", width: "auto" },
    { columnName: "dataRicevuta", align: "center", width: "auto" },
    { columnName: "oraRicevuta", align: "center", width: "auto" },
    { columnName: "durata", align: "center", width: "auto" },
    { columnName: "nrDialed", align: "center", width: "auto" },
    { columnName: "ringTime", align: "center", width: "auto" },
    { columnName: "numeroChiamante", align: "center", width: "auto" },
    { columnName: "nomeSoggetto", align: "center", width: "auto" },
  ]);
  const [pageSizes] = useState([10, 25, 50, 100, 0]);
  const [defaultHiddenColumnNames] = useState(["id"]);
  const [sorting, setSorting] = useState([
    { columnName: "id", direction: "desc" },
  ]);

  const columns = [
    { name: "id", title: "ID" },
    { name: "nomeOperatore", title: "Nome operatore" },
    { name: "tipoChiamata", title: "Tipo Chiamata" },
    { name: "dataRicevuta", title: "Data ricevuta" },
    { name: "oraRicevuta", title: "Ora ricevuta" },
    { name: "durata", title: "Durata" },
    { name: "nrDialed", title: "Numero dialed" },
    { name: "ringTime", title: "Ring time" },
    { name: "numeroChiamante", title: "Numero chiamante" },
    { name: "nomeSoggetto", title: "Nome soggetto" },
  ];

  const TableComponent = ({ ...restProps }) => (
    <Table.Table {...restProps} className="table-striped" />
  );

  useEffect(() => {
    if (props.idUser) {
      GetCalls();
    }
    /*eslint-disable-next-line*/
  }, [props.idUser]);

  return (
    <>
      <ModalImportCsv
        handleCloseImport={handleCloseImport}
        importModal={importModal}
      />

      <Grid rows={rows} columns={columns}>
        <SortingState sorting={sorting} onSortingChange={setSorting} />
        <IntegratedSorting />
        <PagingState defaultCurrentPage={0} defaultPageSize={10} />
        <IntegratedPaging />
        <FilteringState defaultFilters={[]} />
        <IntegratedFiltering />
        <Table
          tableComponent={TableComponent}
          columnExtensions={tableColumnExtensions}
        />

        <TableHeaderRow showSortingControls />

        <PagingPanel pageSizes={pageSizes} />
        <TableColumnVisibility
          defaultHiddenColumnNames={defaultHiddenColumnNames}
        />
        <Toolbar />
        <ColumnChooser />
      </Grid>

      <div className="row">
        {user.importCsv === true &&
        (parseInt(user.id_role) === roles.hr ||
          parseInt(user.id_role) === roles.admin) ? (
          <div className="col-6 float-left">
            <Button
              onClick={() => handleOpenImport()}
              variant="primary"
              className="mt-4 mb-4 ml-4"
              size="sm"
              style={{ float: "left", marginBottom: "2%" }}
            >
              Import CSV
            </Button>
          </div>
        ) : null}
        {user.exportCsv === true &&
        (parseInt(user.id_role) === roles.hr ||
          parseInt(user.id_role) === roles.admin) ? (
          <div className="col-6 float-right">
            <CSVLink
              //data={props.tickets}
              data={[]}
              filename={"chiamate.csv"}
              className="btn btn-primary btn-sm font-weight-bold mt-4 mb-4 mr-4"
              style={{ marginBottom: "1%", marginRight: "2%", float: "right" }}
              size={"small"}
              separator={";"}
            >
              Esporta CSV{" "}
            </CSVLink>
          </div>
        ) : null}
      </div>
    </>
  );
}
