/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import SVG from "react-inlinesvg";
import { useSelector } from "react-redux";
import { useHtmlClassService } from "../../../layout";
import { toAbsoluteUrl } from "../../../_helpers";
import config, { roles } from "../../../../config/config";

export function StatusBarObiettivi({ className }) {
  /*eslint-disable */
  const { user } = useSelector((state) => state.auth);
  const [departments, setDepartments] = useState([]);
  const [riepilogo, setRiepilogo] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [loading, setLoading] = useState(false);
  const [idDepartment, setIdDepartment] = useState(0);

  const requestOptions = {
    headers: {
      Authorization:
        "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
    },
    method: "POST",
    body: JSON.stringify({
      idDepartment:
        parseInt(user.id_role) === roles.admin ||
        parseInt(user.id_role) === roles.hr
          ? idDepartment
          : user.idDepartment,
      idOrganization: user.id_organization,
      idUtente: user.id,
      idRole: user.id_role,
    }),
  };

  const GetDepartments = () => {
    fetch(config.apiUrl + "users/GetDepartment.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setDepartments(data));
  };

  const GetValutazioneDipartimento = () => {
    setLoading(true);
    fetch(config.apiUrl + "obiettivi/GetRiepilogoDashboard.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setRiepilogo(data))
      .then(() => setLoading(false));
  };

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBasePrimary: objectPath.get(
        uiService.config,
        "js.colors.theme.base.primary"
      ),
      colorsThemeLightPrimary: objectPath.get(
        uiService.config,
        "js.colors.theme.light.primary"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService, loading]);

  useEffect(() => {
    if (
      parseInt(user.id_role) === roles.admin ||
      parseInt(user.id_role) === roles.hr
    ) {
      GetDepartments();
    }
    const element = document.getElementById("kt_stats_widget_12_chart");

    if (!element) {
      return;
    }

    const options = getChartOption(layoutProps, riepilogo);
    const chartnewUsers = new ApexCharts(element, options);
    chartnewUsers.render();
    return function cleanUp() {
      chartnewUsers.destroy();
    };
  }, [layoutProps]);

  useEffect(() => {
    GetValutazioneDipartimento();
  }, [idDepartment]);

  return (
    <div className={`card card-custom  ${className}`} d>
      <div className="card-dashboard border-0 pt-5">
        <h3
          style={{ color: "black !important" }}
          className="card-title font-weight-bolder"
        >
          RIEPILOGO
        </h3>
      </div>
      <div className="card-body d-flex flex-column p-0">
        <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
          <span className="symbol symbol-circle symbol-50 symbol-light-primary mr-2">
            <span className="symbol-label">
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Shopping/Chart-line1.svg"
                  )}
                ></SVG>
              </span>
            </span>
          </span>
          <div className="d-flex flex-column text-right">
            {parseInt(user.id_role) === roles.hr ||
            parseInt(user.id_role) === roles.admin ? (
              <span className="text-dark-75 font-weight-bolder font-size-h3 mb-4">
                Riferimento
              </span>
            ) : null}
            {parseInt(user.id_role) === roles.hr ? (
              <span className="text-muted font-weight-bold mt-2">
                {user.nameDepartment}
              </span>
            ) : null}
            {parseInt(user.id_role) === roles.admin ? (
              <select
                onChange={(e) => setIdDepartment(e.target.value)}
                className="form-control mb-10"
              >
                <option>Seleziona dipartimento</option>
                {departments.map((e, key) => (
                  <option key={key} value={e.id} name={e.slug}>
                    {e.name}
                  </option>
                ))}
              </select>
            ) : null}
            {parseInt(user.id_role) === roles.dipendente ? (
              <span className="text-muted font-weight-bold mt-2">
                Personale
              </span>
            ) : null}
          </div>
        </div>
        {!loading ? (
          <div
            id="kt_stats_widget_12_chart"
            className="card-rounded-bottom"
            style={{ height: "150px" }}
          ></div>
        ) : null}
      </div>
    </div>
  );
}

function getChartOption(layoutProps, riepilogo) {
  var options = {
    series: [
      {
        name: "Risultati",
        data: riepilogo,
      },
    ],
    chart: {
      type: "area",
      height: 200,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "solid",
      opacity: 1,
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [layoutProps.colorsThemeBasePrimary],
    },
    xaxis: {
      categories: ["Lun", "Mar", "Mer", "Gio", "Ven", "Sab", "Dom"],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 55,
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily,
      },
      y: {
        formatter: function (val) {
          return val + "%";
        },
      },
    },
    colors: [layoutProps.colorsThemeLightPrimary],
    markers: {
      colors: [layoutProps.colorsThemeLightPrimary],
      strokeColor: [layoutProps.colorsThemeBasePrimary],
      strokeWidth: 3,
    },
  };
  return options;
}
