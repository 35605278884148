import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { DateRangePicker } from "react-date-range";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import it from "date-fns/locale/it";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  LineSeries,
  Title,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";
import { scaleBand } from "@devexpress/dx-chart-core";
import { ArgumentScale } from "@devexpress/dx-react-chart";
import { line, curveStep } from "d3-shape";
import { ValueScale, Stack, Animation } from "@devexpress/dx-react-chart";
import config from "../../../../config/config";
import {
  defaultStaticRanges,
  defaultInputRanges,
} from "react-date-range/dist/defaultRanges";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Skeleton } from "@material-ui/lab";

export const ChartRiepilogo = (props) => {
  /*eslint-disable */
  const [startDate, setStartDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 2))
  );
  const [endDate, setEndDate] = useState(new Date());
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSelect = (ranges) => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  };

  const Root = (props) => (
    <Legend.Root {...props} className="m-auto flex-row" />
  );

  const format = () => (tick) => tick;

  const Marker = (props) => {
    const { className, color } = props;
    return (
      <svg className={className} fill={color} width="10" height="10">
        <rect x={0} y={0} width={100} height={100} />
      </svg>
    );
  };

  const Line = (props) => (
    <LineSeries.Path
      {...props}
      path={line()
        .x(({ arg }) => arg)
        .y(({ val }) => val)
        .curve(curveStep)}
    />
  );

  const GetRiepilogoValutazioni = () => {
    setLoading(true);
    const adminOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        start: startDate,
        end: endDate,
        idDipendente: props.id,
        idRole: props.idRole,
      }),
    };
    fetch(config.apiUrl + "obiettivi/GetRiepilogoValutazioni.php", adminOptions)
      .then((response) => response.json())
      .then((data) => setData(data))
      .then(() => setLoading(false));
  };

  useEffect(() => {
    GetRiepilogoValutazioni();
  }, [startDate, endDate, props.tab]);

  const staticRangesLabels = {
    Today: "Oggi",
    Yesterday: "Ieri",
    "This Week": "Questa settimana",
    "Last Week": "Settimana scorsa",
    "This Month": "Questo mese",
    "Last Month": "Mese scorso",
  };

  const inputRangesLabels = {
    "days up to today": "giorni da oggi",
    "days starting today": "giorni a partire da oggi",
  };

  function translateRange(dictionary) {
    return (item) =>
      dictionary[item.label]
        ? { ...item, label: dictionary[item.label] }
        : item;
  }

  const itStaticRanges = defaultStaticRanges.map(
    translateRange(staticRangesLabels)
  );
  const itInputRanges = defaultInputRanges.map(
    translateRange(inputRangesLabels)
  );

  const Label = (symbol) => (props) => {
    const { text } = props;
    return <ValueAxis.Label {...props} text={text + symbol} />;
  };

  const modifyValueDomain = (domain) => [domain[0], 100];
  const labelPercentual = Label(" %");

  return (
    <>
      <Row>
        <div className="col-12 my-2 mx-33">
          <Accordion className="col-9" style={{ backgroundColor: "#fbfbfd" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Seleziona intervallo</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="row">
                <div className="d-flex justify-content-center">
                  <DateRangePicker
                    staticRanges={itStaticRanges}
                    inputRanges={itInputRanges}
                    className="col-12 ml-20 mr-auto"
                    locale={it}
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="col-12">
          {!loading ? (
            <Chart data={data}>
              <ValueScale name="value" modifyDomain={modifyValueDomain} />
              <ArgumentScale factory={scaleBand} />
              <ArgumentAxis tickFormat={format} />
              <ValueAxis scaleName="value" labelComponent={labelPercentual} />
              <BarSeries
                scaleName="value"
                name="Valutazione"
                valueField="valutation"
                argumentField="field"
                color="#0f4c5c"
              />
              <BarSeries
                scaleName="value"
                name="Autovalutazione"
                valueField="autovalutation"
                argumentField="field"
                color="#ff9f1c"
              />
              <LineSeries
                scaleName="value"
                name="Media valutazione/autovalutazione"
                valueField="mediaValutazione"
                argumentField="field"
                color="#e71d36"
                seriesComponent={Line}
              />
              <Animation />
              <Legend
                position="right"
                rootComponent={Root}
                markerComponent={Marker}
              />
              <Title text="Riepilogo valutazioni" />
              <Stack />
            </Chart>
          ) : (
            <div className="col-12">
              <Skeleton animation="wave" variant="text" />
              <Skeleton
                animation="wave"
                variant="circle"
                width={40}
                height={40}
              />
              <Skeleton
                animation="wave"
                variant="rect"
                width={210}
                height={118}
              />
            </div>
          )}
        </div>
      </Row>
    </>
  );
};
