/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";

export default function StickyToolbar(props) {
  return (
    <>
      <ul className="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
      <OverlayTrigger
          placement="left"
          target="hover"
          overlay={
            <Tooltip id="documentations-tooltip">
              Presenze
            </Tooltip>
          }
        >
          <li className="nav-item mb-2" data-placement="left">
            <Button
              onClick={() => props.handleOpenPresenzeShow()}
              className="btn btn-sm btn-icon btn-bg-secondary btn-text-success btn-light-success"
              target="_blank"
              rel="noopener noreferrer"
            >
            <FontAwesomeIcon icon={faAddressCard}/>
            </Button>
          </li>
        </OverlayTrigger>
        
        <OverlayTrigger
          placement="left"
          target="hover"
          overlay={
            <Tooltip id="documentations-tooltip">
              Richiedi straordinario
            </Tooltip>
          }
        >
          <li className="nav-item mb-2" data-placement="left">
            <Button
              onClick={() => props.handleOpenStraordinarioShow()}
              className="btn btn-sm btn-icon btn-bg-secondary btn-text-warning btn-light-warning"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fas fa-business-time"></i>
            </Button>
          </li>
        </OverlayTrigger>

        <OverlayTrigger
          placement="left"
          target="hover"
          overlay={
            <Tooltip id="documentations-tooltip">Richiedi Permesso</Tooltip>
          }
        >
          <li className="nav-item mb-2" data-placement="left">
            <Button
              onClick={() => props.handleRichiestaOpen()}
              className="btn btn-sm btn-icon btn-bg-secondary btn-text-warning btn-light-danger"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="flaticon2-time"></i>
            </Button>
          </li>
        </OverlayTrigger>

        <OverlayTrigger
          placement="left"
          target="hover"
          overlay={
            <Tooltip id="documentations-tooltip">Richiedi Ferie</Tooltip>
          }
        >
          <li className="nav-item mb-2" data-placement="left">
            <Button
              onClick={() => props.handleOpenFerieShow()}
              className="btn btn-sm btn-icon btn-bg-secondary btn-text-info btn-light-info"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="flaticon2-world"></i>
            </Button>
          </li>
        </OverlayTrigger>

        <OverlayTrigger
          placement="left"
          target={["hover", "focus"]}
          overlay={
            <Tooltip id="documentations-tooltip">
              Aggiungi evento/avviso
            </Tooltip>
          }
        >
          <li className="nav-item mb-2" data-placement="left">
            <Button
              onClick={() => props.handleOpen()}
              className="btn btn-sm btn-icon btn-text-primary btn-light-primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="flaticon2-calendar"></i>
            </Button>
          </li>
        </OverlayTrigger>
      </ul>
    </>
  );
}
