import React from "react";
import { Modal, Button } from "react-bootstrap";
import config from "../../../../config/config";

export const DeleteField = (props) => {
  const DeleteField = (index) => {
    const options = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idField: index,
      }),
    };
    fetch(config.apiUrl + "obiettivi/DeleteField.php", options).then(() => {
      props.setCount(props.count + 1);
      props.handleClose();
    });
  };

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header>
        <Modal.Title>Elimina campo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12">
          <p>
            Sei sicuro di voler <strong>eliminare definitivamente</strong>{" "}
            {props.field.name}?
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <p className="text-muted">La scelta sarà definitiva</p>
        <Button
          variant="danger"
          className="px-3"
          size="sm"
          onClick={() => DeleteField(props.field.id)}
        >
          SI
        </Button>
        <Button
          variant="secondary"
          size="sm"
          className="px-2"
          onClick={() => props.handleClose()}
        >
          NO
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
