/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import config from "../../../../config/config";
import { ModalRequest } from "../mixed/ModalRequest";
import { Permessi } from "./richieste/Permessi";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Ferie } from "./richieste/Ferie";
import { Straordinari } from "./richieste/Straordinari";
import {Presenze} from "./richieste/Presenze";

export const ListsRichieste = ({ className }) => {
  const { user } = useSelector((state) => state.auth);
  const [permessi, setPermessi] = useState([]);
  const [straordinari, setStraordinari] = useState([]);
  const [presenze, setPresenze] = useState([]);
  const [ferie, setFerie] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedNotification, setSelect] = useState({ type: "" });
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `nav-tab-${index}`,
      "aria-controls": `nav-tabpanel-${index}`,
    };
  }

  function LinkTab(props) {
    return (
      <Tab
        component="a"
        onClick={(event) => {
          event.preventDefault();
        }}
        {...props}
      />
    );
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
  }));

  const handleClose = () => setShow(false);
  function handleShow(notifiche) {
    setSelect(notifiche);
    setShow(true);
  }

  const requestOptions = {
    headers: {
      Authorization:
        "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
    },
    method: "POST",
    body: JSON.stringify({
      idUser: user.id,
      accessToken: user.authToken,
      idOrganization: user.id_organization,
      idRole: user.id_role,
      idDepartment: user.idDepartment,
    }),
  };

  const GetRequests = () => {
    setLoading(true);
    fetch(config.apiUrl + "calendar/GetRequests.php", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setPermessi(data.result["permessi"]);
        setStraordinari(data.result["straordinari"]);
        setPresenze(data.result["presenze"]);
        setFerie(data.result["ferie"]);
      })
      .then(() => setLoading(false));
  };

  const classes = useStyles();

console.log(presenze)

  useEffect(() => {
    GetRequests();
  }, [count]);

  var status;
  function handleStatus(notifica) {
    if (parseInt(notifica.status) === 0) {
      status = "Attesa";
      return "label label-lg label-light-warning label-inline font-weight-bold py-4";
    } else if (parseInt(notifica.status) === 1) {
      status = "Approvata";
      return "label label-lg label-light-success label-inline font-weight-bold py-4";
    } else if (parseInt(notifica.status) === -1) {
      status = "Rifiutata";
      return "label label-lg label-light-danger label-inline font-weight-bold py-4";
    }
  }

  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-dashboard border-0">
          <h3 className="card-title font-weight-bolder text-dark">Richieste</h3>
        </div>
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="nav tabs example"
            >
              <LinkTab label="Presenze" href="/presenze" {...a11yProps(0)} />
              <LinkTab label="Permessi" href="/permessi" {...a11yProps(1)} />
              <LinkTab
                label="Straordinari"
                href="/straordinari"
                {...a11yProps(2)}
              />
              <LinkTab label="Ferie" href="/ferie" {...a11yProps(3)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            {!loading ? (
              <Presenze
                data={presenze}
                user={user}
                loading={loading}
                count={count}
                setCount={setCount}
              />
            ) : null}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {!loading ? (
              <Permessi
                data={permessi}
                user={user}
                loading={loading}
                count={count}
                setCount={setCount}
              />
            ) : null}
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Straordinari
              data={straordinari}
              user={user}
              loading={loading}
              count={count}
              setCount={setCount}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Ferie
              data={ferie}
              user={user}
              loading={loading}
              count={count}
              setCount={setCount}
            />
          </TabPanel>
        </div>

        {/* Body */}
      </div>
      <ModalRequest
        notifica={selectedNotification}
        handleShow={handleShow}
        handleClose={handleClose}
        count={count}
        setCount={setCount}
        show={show}
      />
    </>
  );
};
