import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import config, { modules } from "../../../../config/config";
import { CSVReader } from "react-papaparse";

export const ModalImportVenditori = (props) => {
  const { user } = useSelector((state) => state.auth);

  const buttonRef = React.createRef();

  
  const [dati, setDati] = useState([]);
  const [colonne, setColonne] = useState([]);
  const [load, setLoading] = useState(false);

  const [lastSettings, setLastSettings] = useState({
    exId: "",
    idTrattativa: "",
    idOfferta: "",
    venditore: "",
    stato: "",
  });

  useEffect(() => {
    GetLastSettings();
    /*eslint-disable-next-line*/
  }, []);

  const handleOnFileLoad = (data) => {
    setColonne(data[0].data);
    setDati(data.slice(1).map((e) => e.data));
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log("---------------------------");
    console.log(err);
    console.log("---------------------------");
  };

  const handleOnRemoveFile = (data) => {
    console.log("---------------------------");
    console.log(data);
    console.log("---------------------------");
  };

  const handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const schema = Yup.object().shape({
    exId: Yup.number().required("Selezionare il campo corrispondente"),
    idTrattativa: Yup.number().required("Selezionare il campo corrispondente"),
    idOfferta: Yup.number().required("Selezionare il campo corrispondente"),
    venditore: Yup.number().required("Selezionare il campo corrispondente"),
    stato: Yup.number().required("Selezionare il campo corrispondente"),
  });

  const GetLastSettings = () => {
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idUser: user.id,
        idOrganization:
          user.id_organization === 0 ? user.id : user.id_organization,
        idModule: modules.venditori,
      }),
    };
    fetch(config.apiUrl + "obiettivi/GetLastSettings.php", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.lenght > 0) {
          setLastSettings(data);
        }
      });
  };

  const formik = useFormik({
    initialValues: lastSettings,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      const requestOptions = {
        headers: {
          Authorization:
            "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
        },
        method: "POST",
        body: JSON.stringify({
          colonne: values,
          dati: dati,
          useCustomField: true,
          idOrganization: user.id_organization,
          idUser: user.id,
        }),
      };

      fetch(config.apiUrl + "obiettivi/ImportCsvVenditori.php", requestOptions)
        .then((response) => response.json())
        .then((result) => setLoading(result))
        .then(()=>props.handleCloseImport());
    },
  });

  console.log(formik.values);

  return (
    <Modal
      backdrop="static"
      show={props.importModal}
      onHide={props.handleCloseImport}
    >
      <Modal.Header style={{ backgroundColor: "#caf0f8" }} closeButton>
        <Modal.Title>Importa file CSV</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CSVReader
          ref={buttonRef}
          onFileLoad={handleOnFileLoad}
          onError={handleOnError}
          noClick
          noDrag
          onRemoveFile={handleOnRemoveFile}
        >
          {({ file }) => (
            <aside
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
              }}
            >
              <Button
                style={{
                  float: "left",
                }}
                size="sm"
                variant="success"
                onClick={handleOpenDialog}
              >
                Seleziona file
              </Button>
              <div
                style={{
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: "#ccc",
                  height: 35,
                  lineHeight: 2.5,
                  paddingLeft: 13,
                  paddingTop: 3,
                  width: "60%",
                }}
              >
                {file && file.name}
              </div>
              <Button
                size="sm"
                style={{
                  float: "right",
                }}
                variant="danger"
                onClick={handleRemoveFile}
              >
                Remove
              </Button>
            </aside>
          )}
        </CSVReader>
        <Form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-6">
              <label className="col-xl-12 col-lg-12 col-form-label">
                ID Venditore
              </label>
              <div className="col-lg-12 col-xl-12">
                <div className="input-group input-group-lg input-group-solid">
                  <select
                    onChange={formik.handleChange}
                    value={formik.values.exId}
                    className="form-control form-control-lg form-control-solid"
                    name="exId"
                  >
                    <option>Seleziona colonna corrispondente</option>
                    {colonne.map((e, key) => {
                      return (
                        <option key={key} value={key}>
                          {e}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {formik.touched.exId && formik.errors.exId ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.exId}</div>
                  </div>
                ) : null}
              </div>

              <label className="col-xl-12 col-lg-12 col-form-label">
                ID trattativa
              </label>
              <div className="col-lg-12 col-xl-12">
                <div className="input-group input-group-lg input-group-solid">
                  <select
                    onChange={formik.handleChange}
                    value={formik.values.idTrattativa}
                    className="form-control form-control-lg form-control-solid"
                    name="idTrattativa"
                  >
                    <option>Seleziona colonna corrispondente</option>
                    {colonne.map((e, key) => {
                      return (
                        <option key={key} value={key}>
                          {e}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {formik.touched.idTrattativa && formik.errors.idTrattativa ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.idTrattativa}
                    </div>
                  </div>
                ) : null}
              </div>
              <label className="col-xl-12 col-lg-12 col-form-label">
                Codice offerta
              </label>
              <div className="col-lg-12 col-xl-12">
                <div className="input-group input-group-lg input-group-solid">
                  <select
                    onChange={formik.handleChange}
                    value={formik.values.idOfferta}
                    className="form-control form-control-lg form-control-solid"
                    name="idOfferta"
                  >
                    <option>Seleziona colonna corrispondente</option>
                    {colonne.map((e, key) => {
                      return (
                        <option key={key} value={key}>
                          {e}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {formik.touched.idOfferta && formik.errors.idOfferta ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.idOfferta}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-6">
              <label className="col-xl-12 col-lg-12 col-form-label">
                Nome venditore
              </label>
              <div className="col-lg-12 col-xl-12">
                <div className="input-group input-group-lg input-group-solid">
                  <select
                    onChange={formik.handleChange}
                    value={formik.values.venditore}
                    className="form-control form-control-lg form-control-solid"
                    name="venditore"
                  >
                    <option>Seleziona colonna corrispondente</option>
                    {colonne.map((e, key) => {
                      return (
                        <option key={key} value={key}>
                          {e}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {formik.touched.venditore && formik.errors.venditore ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.venditore}
                    </div>
                  </div>
                ) : null}
              </div>
              <label className="col-xl-12 col-lg-12 col-form-label">
                Stato offerta
              </label>
              <div className="col-lg-12 col-xl-12">
                <div className="input-group input-group-lg input-group-solid">
                  <select
                    onChange={formik.handleChange}
                    value={formik.values.stato}
                    className="form-control form-control-lg form-control-solid"
                    name="stato"
                  >
                    <option>Seleziona colonna corrispondente</option>
                    {colonne.map((e, key) => {
                      return (
                        <option key={key} value={key}>
                          {e}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {formik.touched.stato && formik.errors.stato ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.stato}</div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleCloseImport}>
          Annulla
        </Button>
        <Button onClick={formik.handleSubmit} variant="primary">
          Conferma
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
